@import '../components/base';

.Template__TeamScansCustomersWithProduct {
  .player {
    --distance: 26px;
    &.team1 {
      left: 237px;
      top: 274px;
    }
    &.team2 {
      left: 237px;
      top: 443px;
    }
    &.path1 {
      left: 342px;
      top: 345px;
    }
    &.path2 {
      left: 342px;
      top: 436px;
    }
    &.right {
      left: 599px;
      top: 289px;
      --distance: 26px;
      .Player__Path {
        position: absolute;
        &:nth-child(1) {
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          left: calc(var(--distance) * 0 + 140px);
          top: 0px;
        }
        &:nth-child(3) {
          left: calc(var(--distance) * 1 + 140px);
          top: 0px;
        }
        &:nth-child(4) {
          left: calc(var(--distance) * 2 + 140px);
          top: 0px;
        }
        &:nth-child(5) {
          left: calc(var(--distance) * 3 + 140px);
          top: 0px;
        }
        &:nth-child(6) {
          left: calc(var(--distance) * 4 + 140px);
          top: 0px;
        }
        // Middle section
        &:nth-child(7) {
          left: 15px;
          top: 136px;
        }
        // Middle section 1
        &:nth-child(8) {
          left: 145px;
          top: 131px;
        }
        &:nth-child(9) {
          left: 145px;
          top: 143px;
        }
        // Middle section 2
        &:nth-child(10) {
          left: 121px;
          top: 104px;
        }
        &:nth-child(11) {
          left: 121px;
          top: 145px;
        }
        // Middle section 3
        &:nth-child(12) {
          left: 96px;
          top: 78px;
        }
        &:nth-child(13) {
          left: 96px;
          top: 145px;
        }
        // Bottom section
        &:nth-child(14) {
          left: 0px;
          top: 188px;
        }
        &:nth-child(15) {
          left: calc(var(--distance) * 0 + 140px);
          top: 244px;
        }
        &:nth-child(16) {
          left: calc(var(--distance) * 1 + 140px);
          top: 244px;
        }
        &:nth-child(17) {
          left: calc(var(--distance) * 2 + 140px);
          top: 244px;
        }
        &:nth-child(18) {
          left: calc(var(--distance) * 3 + 140px);
          top: 244px;
        }
        &:nth-child(19) {
          left: calc(var(--distance) * 4 + 140px);
          top: 244px;
        }
        &:nth-child(20) {
          left: calc(var(--distance) * 5 + 140px);
          top: 244px;
        }
      }
    }
    &.product {
      left: 496px;
      top: 373px;
      label {
        display: block;
        width: 100%;
        text-align: center;
        position: absolute;
        top: -30px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 14px;
        color: rgba($white, 0.5);
      }
    }
    &.network {
      left: 758px;
      top: 334px;
    }
  }
}
