@import '../base';

.slide-pcap {
  .data-left {
    position: absolute;
    width: calc(50% - 150px);
    top: 50%;
    left: 40px;
    height: 12px;
  }
  .data-right {
    position: absolute;
    width: calc(50% - 150px);
    top: 50%;
    right: 40px;
    height: 12px;
    @include transition('left 1s ease, right 1s ease');
  }
}
