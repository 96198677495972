/*

RANSOMWARE

*/
@import "../base";

//
// Players that often have the same starting position
//

$dots: 6;
$duration: 3s;
$size: 6px;
$delay: $duration / $dots;

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin DonutCenter {
  top: 25%;
  left: 50%;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

.ransomware {

// Slide 0
$leftOffset: -25px;
.slide-01 {
  .player {
    &.card1 {
      top: 240px;
      left: 120px + $leftOffset;
    }
    &.card2 {
      top: 240px;
      left: 360px + $leftOffset;
    }
    &.card3 {
      top: 240px;
      left: 600px + $leftOffset;
    }
    &.card4 {
      top: 240px;
      left: 840px + $leftOffset;
    }
  }
}

@include keyframes(dotsLeftToRight) {
  0% {
    left: 0%;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

//
// Slide 02 - Beaconing Detected
//
$leftOffset: -40px;
$topOffset: 0;
$trackWidth: 330px;
.slide-02 {
  .player {
    &.computer {
      left: 860px + $leftOffset;
      top: 320px + $topOffset;
    }
    &.computerGroup {
      left: 160px + $leftOffset;
      top: 300px + $topOffset;
    }
    &.alert {
      @include AlertCenter();
    }
    &.dots-left {
      left: centerLeft(200px) - 120px;
      top: centerTop(4px);
      .player--dots-track {
        width: 160px;
      }
    }
    &.dots-right {
      left: centerLeft(200px) + 160px;
      top: centerTop(4px);
      .player--dots-track {
        width: 160px;
      }
    }
    &.shield {
      left: 550px + $leftOffset;
      top: 330px + $topOffset;
    }
  }
}

//
// Slide 03 - Inspect Suspect IP Address
//
$leftOffset: -40px;
$topOffset: 0px;
.slide-03 {
  .player {
    &.terminal {
      left: 860px + $leftOffset;
      top: 320px + $topOffset;
    }
    &.request {
      left: 800px;
      top: 240px;
    }
  }
}

//
// Slide 04 - Inspect Suspect IP Address
//
.slide-04 {
  .player {
    &.terminal {
      @include CardCenter();
    }
    &.donut {
      @include DonutCenter();
    }
    &.cymon {
      left: 60px;
      top: 200px;
    }
    &.notations {
      left: 60px;
      top: 400px;
    }
    &.activity {
      left: 820px;
      top: 200px;
    }
  }
}

//
// Slide 05 - Inspect Suspect IP Address
//
.slide-05 {
  .player {
    &.terminal {
      left: 496px;
      top: 320px;
    }
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.client {
      left: 400px;
      top: 240px;
    }
    &.tcp {
      left: 400px;
      top: 340px;
    }
    &.event {
      top: 240px;
      left: 670px;
    }
  }
}

//
// Slide 06 - Assess Client IP Activity
//
.slide-06 {
  .player {
    &.terminal {
      left: 100px;
      top: 320px;
    }
    &.client {
      left: 400px;
      top: 240px;
    }
    &.policies {
      top: 240px;
      left: 670px;
    }
    &.filters {
      top: 400px;
      left: 670px;
    }
  }
}

//
// Slide 07 - Inspect PCAP
//
.slide-07 {
  .player {
    &.tcp {
      left: 460px;
      top: 360px;
    }
    &.file {
      left: 460px;
      top: 460px;
    }
    &.seq {
      left: 460px;
      top: 460px;
    }
    &.data-left {
      position: absolute;
      width: calc(50% - 150px);
      top: 50%;
      left: 40px;
      height: 12px;
    }
  }
}

//
// Slide 08 - Inspect HTTP Activity
//
.slide-08 {
  .player {
    &.alert {
      @include AlertCenter();
    }
    &.tcp {
      left: 460px;
      top: 270px;
    }
    &.file {
      left: 460px;
      top: 350px;
    }
    &.seq {
      left: 460px;
      top: 450px;
    }
    &.http {
      left: 460px;
      top: 500px;
    }
    &.data-left {
      position: absolute;
      width: calc(50% - 150px);
      top: 50%;
      left: 40px;
      height: 12px;
    }
  }
}

//
// Slide 09 - Inspect HTTP Activity
//
.slide-09 {
  .player {
    &.terminal {
      left: 100px;
      top: 320px;
    }
    &.host {
      left: 400px;
      top: 240px;
    }
    &.stream {
      top: 240px;
      left: 670px;
    }
    &.http {
      top: 400px;
      left: 670px;
    }
  }
}

//
// Slide 10 - Inspect HTTP Activity
//
.slide-10 {
  .player {
    &.code {
      left: 540px;
      top: 390px;
    }
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.analyzing {
      left: 640px;
      top: 360px;
    }
  }
}

//
// Slide 11 - Inspect Activity in Analyzer
//
.slide-11 {
  .player {
    &.code {
      left: 540px;
      top: 370px;
      transform: scale(3);
    }
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.analyzing {
      left: 640px;
      top: 360px;
    }
    &.bro {
      left: 60px;
      top: 200px;
    }
    &.suricata {
      left: 60px;
      top: 400px;
    }
    &.geo {
      left: 800px;
      top: 200px;
    }
    &.usa {
      left: 810px;
      top: 340px;
    }
  }
}

//
// Slide 12 - Inspect Payload in Suricata
//
.slide-12 {
  .player {
    &.folder {
      left: 180px;
      top: 360px;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.payload {
      top: 290px;
      left: 450px;
    }
    &.results {
      top: 290px;
      left: 680px;
    }
  }
}

//
// Slide 13 - Inspect Payload in Bro
//
.slide-13 {
  .player {
    &.folder {
      left: 180px;
      top: 360px;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.payload {
      top: 290px;
      left: 450px;
    }
    &.results {
      top: 290px;
      left: 680px;
    }
  }
}

//
// Slide 14 - Confirm Malicious Payload
//
.slide-14 {
  .player {
    &.code {
      left: 540px;
      top: 390px;
    }
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.analyzing {
      left: 640px;
      top: 360px;
    }
  }
}

//
// Slide 15 - Review Drop File Analysis
//
$leftOffset: 100px;
$topOffset: -50px;
.slide-15 {
  .player {
    .player--textBlock-section-title span {
      min-width: 140px;
    }
    &.folder {
      left: 180px + $leftOffset;
      top: 360px + $topOffset;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.filename {
      top: 290px + $topOffset;
      left: 450px + $leftOffset;
    }
    &.host {
      top: 400px + $topOffset;
      left: 450px + $leftOffset;
    }
    &.file {
      top: 510px + $topOffset;
      left: 450px + $leftOffset;
    }
  }
}

//
// Slide 16 - Isolate Host
//
$dots: 8;
.slide-16 {
  .center-player {
    display: grid;
    grid-template-areas: "main";
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .player {
    &.computer {
      top: 378px;
      left: 525px;
    }
    &.dots {
      position: static !important;
      grid-area: main;
      .mask {
        position: relative;
        transform-origin: 50% 50%;
        border-radius: 50%;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        overflow: hidden;
        width: 600px;
        height: 600px;
        background: rgba($white, 0);
        border: 4px solid rgba($white, 0);
        @include transition('background 0.5s ease, border 0.5s ease');
        .dotContainer {
          position: absolute;
          width: 400px;
          height: 400px;
          top: 50%;
          left: 50%;
          transform-origin: 50% 50%;
          .player--dots {
            position: absolute;
          }
        }
        &.highlighted {
          background: rgba($white, 0.05);
          border: 4px solid rgba($white, 0.15);
        }
      }
      .player--dots {
        .player--dots-track {
          transform-origin: 0 0;
          left: 50px;
        }
        @for $i from 1 through $dots {
          &:nth-child(#{$i}) {
            $deg: #{$i * (360 / $dots)}deg;
            transform: rotate($deg);
          }
        }
      }
    }
  }
}

//
// Slide 17 - Create blocking rule
//
$leftOffset: 100px;
$topOffset: -50px;
.slide-17 {
  .player {
    .player--textBlock-section-title span {
      min-width: 140px;
    }
    &.stack {
      left: 180px + $leftOffset;
      top: 360px + $topOffset;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.host {
      top: 290px + $topOffset;
      left: 450px + $leftOffset;
    }
    &.rule {
      top: 400px + $topOffset;
      left: 450px + $leftOffset;
    }
  }
}

//
// Slide 18 - Alert Client
//
.slide-18 {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

//
// Slide 19 - Alert Escalation
//
$topOffset: -40px;
.slide-19 {
  .player--textBlock {
    .player--textBlock-dots {
      .player--textBlock-dots {
        width: 60px;
      }
    }
  }
  .player {
    &.warning {
      left:  150px;
      top: 360px + $topOffset;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.threat {
      left: 400px;
      top: 270px + $topOffset;
    }
    &.info {
      left: 400px;
      top: 390px + $topOffset;
    }
    &.client {
      left: 700px;
      top: 270px + $topOffset;
    }
  }
}

//
// Slide 20 - Alert Escalation
//
.slide-20 {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

//
// Slide 21 - Alert Escalation
//
$leftOffset: 150px;
$topOffset: 0;
$dots: 6;
$distance: 110px;
$duration: 3s;
$size: 6px;
$delay: $duration / $dots;
.slide-21 {
  .player {
    &.analyst {
      left: (496px + -$leftOffset);
      top: 320px + $topOffset;
    }
    &.client {
      left: (496px + $leftOffset);
      top: 320px + $topOffset;
    }
    &.phone {
      left: 535px;
      top: 375px + $topOffset;
      z-index: 2;
    }
    &.dots {
      @include DotsCenter();
      z-index: 1;
      width: $distance;
      height: $size;
      &[data-active="1"] {
        span {
          opacity: 1;
          @for $i from 1 through $dots {
            &:nth-child(#{$i}) {
              @include animation(moveDots $duration linear  (($i - 1) * $delay * -1) infinite);
            }
          }
        }
      }
      span {
        position: absolute;
        display: block;
        height: $size;
        width: $size;
        background: rgba($white, 0.5);
        border-radius: 50%;
        opacity: 0;
        animation: none;
      }
    }
    &.check {
      left: 685px;
      top: 255px + $topOffset;
    }
  }
}

//
// Slide 22 - Send Attack Details
//
$topOffset: -20px;
.slide-22 {
  .player {
    &.title {
      width: 100%;
      top: 160px;
      text-align: center;
      h1 {
        font-size: 48px;
        font-family: Blender Thin;
      }
    }
    &.alert {
      left: 384px;
      top: 320px + $topOffset;
    }
    &.timeline {
      top: 320px + $topOffset;
      left: 240px;
    }
    &.domains {
      top: 320px + $topOffset;
      left: 640px;
    }
    &.hashes {
      top: 480px + $topOffset;
      left: 640px;
    }
    &.bad {
      top: 460px;
      left: 270px;
      .player--circleIcon-circle {
        width: 40px;
        height: 40px;
        border-width: 2px;
      }
    }
    &.chain {
      .chain--lines {
        span {
          position: absolute;
          display: block;
          background: $blue;
          &:nth-child(1) {
            width: 20px;
            height: 2px;
            top: 480px;
            left: 240px;
          }
          &:nth-child(2) {
            width: 30px;
            height: 2px;
            top: 480px;
            left: 360px;
          }
          &:nth-child(3) {
            width: 2px;
            height: 102px;
            top: 380px;
            left: 390px;
          }
          &:nth-child(4) {
            width: 30px;
            height: 2px;
            top: 380px;
            left: 390px;
          }
          &:nth-child(5) {
            width: 30px;
            height: 2px;
            top: 430px;
            left: 390px;
          }
          &:nth-child(6) {
            width: 30px;
            height: 2px;
            top: 480px;
            left: 390px;
          }
          &:nth-child(7) {
            width: 2px;
            height: 102px;
            top: 480px;
            left: 390px;
          }
          &:nth-child(8) {
            width: 30px;
            height: 2px;
            top: 530px;
            left: 390px;
          }
          &:nth-child(9) {
            width: 30px;
            height: 2px;
            top: 580px;
            left: 390px;
          }
        }
      }
      .player--circleIcon {
        position: absolute;
        .player--circleIcon-circle {
          width: 40px;
          height: 40px;
          border-width: 2px;
        }
        &:nth-child(1) {
          left: 270px;
          top: 460px;
        }
        &:nth-child(2) {
          left: 440px;
          top: 360px;
        }
        &:nth-child(3) {
          left: 440px;
          top: 410px;
        }
        &:nth-child(4) {
          left: 440px;
          top: 460px;
        }
        &:nth-child(5) {
          left: 440px;
          top: 510px;
        }
        &:nth-child(6) {
          left: 440px;
          top: 560px;
        }
      }
    }
  }
}


}
