@import '../base';

$donutSize: 400px;

.donut {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 1px;
  height: 400px;
  margin-top: -20px;

  &.red {
    .donut--ticks {
      .donut--tick {
        background-color: $red;
      }
    }
    .donut--circle {
      box-shadow: 0 0 40px rgba($red, 0.5);
    }
  }

  &.green {
    .donut--ticks {
      .donut--tick {
        background-color: $green;
      }
    }
    .donut--circle {
      box-shadow: 0 0 40px rgba($green, 0.5);
    }
  }

  .donut--ticks {
    .donut--tick {
      position: absolute;
      opacity: 1;
      width: 2px;
      height: 25px;
      transform-origin: 50% 100%;
      background-color: $blue;
      @include transition(background-color 0.5s ease);
    }
  }

  $circleSize: 300px;

  .donut--circle {
    display: block;
    position: absolute;
    width: $circleSize;
    height: $circleSize;
    left: -($circleSize / 2);
    top: ($donutSize - $circleSize) / 4 * 3;
    border-radius: 50%;
    box-shadow: 0px 0px 40px rgba($blue, 0.5);
    @include transition(all 0.5s ease);
  }
}
