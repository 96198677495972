@import '../components/base';

.Template__Webinar {
  .player {
    &.world {
      left: 120px;
      top: 180px;
      z-index: 1;
    }
    $leftOffset: 225px;
    $topOffset: 0px;
    &.paths {
      z-index: 2;
      .Player__Path {
        position: absolute;
        &:nth-child(1) {
          left: 23px + $leftOffset;
          top: 310px + $topOffset;
        }
        &:nth-child(2) {
          left: 18px + $leftOffset;
          top: 333px + $topOffset;
        }
        &:nth-child(3) {
          left: 58px + $leftOffset;
          top: 343px + $topOffset;
        }
        &:nth-child(4) {
          left: 118px + $leftOffset;
          top: 359px + $topOffset;
        }
        &:nth-child(5) {
          left: 128px + $leftOffset;
          top: 383px + $topOffset;
        }
        &:nth-child(6) {
          left: 137px + $leftOffset;
          top: 390px + $topOffset;
        }
        &:nth-child(7) {
          left: 145px + $leftOffset;
          top: 398px + $topOffset;
        }
        &:nth-child(8) {
          left: 178px + $leftOffset;
          top: 404px + $topOffset;
        }
        &:nth-child(9) {
          left: 273px + $leftOffset;
          top: 413px + $topOffset;
        }
        &:nth-child(10) {
          left: 289px + $leftOffset;
          top: 431px + $topOffset;
        }
        &:nth-child(11) {
          left: 98px + $leftOffset;
          top: 371px + $topOffset;
        }
        // Right side
        &:nth-child(12) {
          left: 324px + $leftOffset;
          top: 430px + $topOffset;
        }
        &:nth-child(13) {
          left: 331px + $leftOffset;
          top: 430px + $topOffset;
        }
        &:nth-child(14) {
          left: 358px + $leftOffset;
          top: 410px + $topOffset;
        }
        &:nth-child(15) {
          left: 374px + $leftOffset;
          top: 408px + $topOffset;
        }
        &:nth-child(16) {
          left: 374px + $leftOffset;
          top: 400px + $topOffset;
        }
        &:nth-child(17) {
          left: 374px + $leftOffset;
          top: 394px + $topOffset;
        }
        &:nth-child(18) {
          left: 374px + $leftOffset;
          top: 387px + $topOffset;
        }
        &:nth-child(19) {
          left: 374px + $leftOffset;
          top: 369px + $topOffset;
        }
        &:nth-child(20) {
          left: 374px + $leftOffset;
          top: 350px + $topOffset;
        }
        &:nth-child(21) {
          left: 374px + $leftOffset;
          top: 312px + $topOffset;
        }
        &:nth-child(22) {
          left: 374px + $leftOffset;
          top: 304px + $topOffset;
        }
        // Top
        &:nth-child(23) {
          left: -40px + $leftOffset;
          top: 254px + $topOffset;
        }
        &:nth-child(24) {
          left: 320px + $leftOffset;
          top: 300px + $topOffset;
        }
      }
    }
    &.webinar {
      left: 480px;
      top: 320px;
      z-index: 3;
    }
  }
}
