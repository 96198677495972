@import '../components/base';

.Template__CrossTeamCommunication {
  .player {
    &.team1 {
      left: 446px;
      top: 187px;
    }
    &.team2 {
      left: 292px;
      top: 437px;
    }
    &.team3 {
      left: 600px;
      top: 437px;
    }
    &.circle {
      left: 517px;
      top: 430px;
    }
    &.path1 {
      top: 404px;
      left: 557px;
    }
    &.path2 {
      top: 482px;
      left: 500px;
    }
    &.path3 {
      top: 482px;
      left: 595px;
    }
  }
}
