@import '../base';

$movement: 1%;

@include keyframes(jiggleLeft) {
  0% {
    transform: translateX(-$movement);
    border-width: 0 0 0 6px;
    border-color: $red;
  }
  100% {
    transform: translateX($movement);
    border-width: 0 0 0 3px;
    border-color: $blue;
  }
}

@include keyframes(jiggleRight) {
  0% {
    transform: translateX($movement);
    border-width: 0 0 0 6px;
    border-color: $red;
  }
  100% {
    transform: translateX(-$movement);
    border-width: 0 0 0 3px;
    border-color: $blue;
  }
}

@include keyframes(particle) {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 100%;
  }
  100% {
    left: 0%;
    opacity: 0%;
    transform: scale(0.5);
  }
}

.Player__Barrier {
  position: relative;
  &__Shield {
    position: absolute;
    top: 0px;
    height: 300px;
    width: 160px;
    border-radius: 50% 0 0 50%;
    border-width: 0 0 0 3px;
    border-color: $blue;
    border-style: solid;
    &.shield1 {
      left: 100%;
      animation: jiggleLeft linear 200ms infinite;
    }
    &.shield2 {
      left: 105%;
      animation: jiggleRight linear 200ms infinite;
    }
  }
  .player--dots {
    top: 50%;
  }
  &__Particles {
    position: absolute;
    top: calc(50% - 40px);
    right: 0px;
    width: 60px;
    height: 80px;
    div {
      position: absolute;
      width: 100%;
      height: 8px;
      transform-origin: right center;
      top: calc(50% - 2px);
      right: 0;
      &:nth-child(1) {
        transform: rotate(50deg);
        span {
          animation: particle linear 700ms infinite;
          animation-delay: 100ms;
        }
      }
      &:nth-child(2) {
        transform: rotate(40deg);
        span {
          animation: particle linear 600ms infinite;
          animation-delay: 50ms;
        }
      }
      &:nth-child(3) {
        transform: rotate(20deg);
        span {
          animation: particle linear 800ms infinite;
          animation-delay: 80ms;
        }
      }
      &:nth-child(4) {
        transform: rotate(0deg);
        span {
          animation: particle linear 620ms infinite;
          animation-delay: 30ms;
        }
      }
      &:nth-child(5) {
        transform: rotate(-20deg);
        span {
          animation: particle linear 800ms infinite;
          animation-delay: 0ms;
        }
      }
      &:nth-child(5) {
        transform: rotate(-30deg);
        span {
          animation: particle linear 740ms infinite;
          animation-delay: 90ms;
        }
      }
      &:nth-child(6) {
        transform: rotate(-50deg);
      }
      span {
        background: $blue;
        display: block;
        position: absolute;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        left: 100%;
        animation: particle linear 500ms infinite;
      }
    }
  }
}
