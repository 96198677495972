@import '_base';

.dimmed {
  .Badge {
    background-color: $grey-dark !important;
  }
}

.Badge {
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  padding: 0 15px;
  background-color: #3a3a3a;
  border-radius: 40px;
  transition: all 0.5s ease;

  &.disabled {
    background-color: rgba($white, 0.5) !important;
  }

  &--primary,
  &.blue {
    background-color: $blue;
  }

  &--danger,
  &.red {
    background-color: $red;
  }
}
