@import '../components/base';

.Template__TeamScansThreats {
  .player {
    &.team {
      left: 209px;
      top: 353px;
    }
    &.product {
      left: 808px;
      top: 353px;
    }
    &.scanner {
      z-index: 99;
    }
    .threat {
      background: $red;
      height: 30px;
      line-height: 30px;
      padding: 0 10px 0 30px;
      box-sizing: border-box;
      width: 140px;
      position: relative;
      transition: all 0.2s ease;
      font-size: 11px;
      text-transform: uppercase;
      white-space: nowrap;
      svg {
        position: absolute;
        left: 8px;
        top: 7px;
      }
      &.blue {
        background: $blue;
      }
    }
    &.file1,
    &.scanner1 {
      left: 491px;
      top: 338px;
    }
    &.file2,
    &.scanner2 {
      left: 491px;
      top: 402px;
    }
    &.file3,
    &.scanner3 {
      left: 491px;
      top: 466px;
    }
    &.linesRight {
      left: 644px;
      top: 354px;
      .Player__Path {
        position: absolute;
        &:nth-child(1) {
          left: 0px;
          top: 0px;
        }
        &:nth-child(2) {
          left: 0px;
          top: 53px;
        }
        &:nth-child(3) {
          left: 0px;
          top: 73px;
        }
      }
    }
    &.linesLeft {
      left: 314px;
      top: 354px;
      .Player__Path {
        position: absolute;
        &:nth-child(1) {
          left: 0px;
          top: 0px;
        }
        &:nth-child(2) {
          left: 0px;
          top: 53px;
        }
        &:nth-child(3) {
          left: 0px;
          top: 73px;
        }
      }
    }
  }
}
