@import '../components/base';

.Template__ScanBenignOrMalicious {
  .NumberBox {
    padding: 10px 10px 10px 25px;
    display: grid;
    gap: 10;
    grid-template-columns: 1fr 1fr;
    position: relative;
    width: 130px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: calc(50% - 3px);
      left: 10px;
    }
    label {
      color: rgba($white, 0.5);
      letter-spacing: 0;
      font-family: $blenderBold;
      font-size: 14px;
    }
    .Player__Counter {
    }
    &.red {
      border: 4px solid $red;
      &:before {
        background: $red;
      }
    }
    &.blue {
      border: 4px solid $blue;
      &:before {
        background: $blue;
      }
    }
    &.big {
      background: $red;
      display: block;
      padding: 20px;
      text-align: center;
      label {
        display: block;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        width: 100%;
      }
      .Player__Counter {
        text-align: center;
      }
    }
  }
  .player {
    &.shield {
      left: 150px;
      top: 370px;
    }
    &.scanner {
      left: 356px;
      top: 279px;
      width: 400px;
      border: 4px solid $blue;
      height: 300px;
      padding: 16px;
      box-sizing: border-box;
    }
    &.path1 {
      left: 255px;
      top: 430px;
    }
    &.malicious {
      left: 848px;
      top: 307px;
    }
    &.benign {
      left: 848px;
      top: 513px;
    }
    &.log {
      left: 848px;
      top: 381px;
    }
    &.path2 {
      left: 756px;
      top: 280px;
    }
    &.path3 {
      left: 932px;
      top: 352px;
    }
    &.path4 {
      left: 756px;
      top: 447px;
    }
  }
}
