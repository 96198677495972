@import "base";

@include keyframes(blink) {
    0% {
        filter: brightness(1);
    }
    50% {
        filter: brightness(2);
    }
    100% {
        filter: brightness(1);
    }
}

@keyframes blinkSpace {
  0%		{ opacity: .25; }
	10% 	{ opacity: 1; }
	20% 	{ opacity: .3; }
	30% 	{ opacity: .6; }
	40% 	{ opacity: 1; }
	100% 	{ opacity: 1; }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@include keyframes(flickerOn) {
    0%		{ opacity: .25; }
	10% 	{ opacity: .5; }
	20% 	{ opacity: .3; }
	30% 	{ opacity: .3; }
	40% 	{ opacity: .9; }
	50% 	{ opacity: .5; }
	60% 	{ opacity: .3; }
	70% 	{ opacity: .4; }
	80% 	{ opacity: .8; }
	90% 	{ opacity: .6; }
	100% 	{ opacity: 1; }
}

@include keyframes(flickerOff) {
    0% 	    { opacity: 1; }
	10% 	{ opacity: .6; }
	20% 	{ opacity: .8; }
	30% 	{ opacity: .4; }
	40% 	{ opacity: .3; }
	50% 	{ opacity: .5; }
	60% 	{ opacity: .9; }
	70% 	{ opacity: .3; }
	80% 	{ opacity: .3; }
	90% 	{ opacity: .5; }
	100%	{ opacity: 0; }
}

@include keyframes(shimmerIn) {
    0%		{ opacity: .25; -webkit-filter: brightness(1); }
    10% 	{ opacity: .5;  -webkit-filter: brightness(3); }
    20% 	{ opacity: .3;  -webkit-filter: brightness(1); }
    30% 	{ opacity: .3;  -webkit-filter: brightness(3); }
    40% 	{ opacity: .9;  -webkit-filter: brightness(1); }
    50% 	{ opacity: .5;  -webkit-filter: brightness(2); }
    60% 	{ opacity: .3;  -webkit-filter: brightness(1); }
    70% 	{ opacity: .4;  -webkit-filter: brightness(2); }
    80% 	{ opacity: .8;  -webkit-filter: brightness(3); }
    90% 	{ opacity: .6;  -webkit-filter: brightness(2); }
    100% 	{ opacity: 1;   -webkit-filter: brightness(1); }
}

@include keyframes(heightFill) {
  100% {height: 100%;}
}

@include keyframes(shimmer) {
  0%   { filter: brightness(1); }
	10%  { filter: brightness(3); }
	20%  { filter: brightness(1); }
	30%  { filter: brightness(3); }
	40%  { filter: brightness(1); }
	50%  { filter: brightness(2); }
	60%  { filter: brightness(1); }
	70%  { filter: brightness(2); }
	80%  { filter: brightness(3); }
	90%  { filter: brightness(2); }
	100% { filter: brightness(1); }
}

@include keyframes(dottedLines) {
    0% {background-position: 0px 0px;}
    100% {background-position: -100px 0px;}
}

@include keyframes(dottedLinesReverse) {
    0% {background-position: 0px 0px;}
    100% {background-position: 100px 0px;}
}

@include keyframes(dotsGrow) {
    0% {width: 0px;}
    100% {width: 100px;}
}

.blink {
    @include animation(blink 0.1s linear);
}

@include keyframes(arrowRightPulse) {
  0%    {right: 0;    opacity: 0.5;}
  50%   {right: 10px; opacity: 0.1;}
  100%  {right: 0;    opacity: 0.5;}
}

@include keyframes(arrowLeftPulse) {
  0% {left: 0;}
  50% {left: 20px;}
}

@include keyframes(moveDown) {
  0% {transform: translateY(-30px);}
  100% {transform: translateY(0);}
}


//
// Dotted line animation
//
.animated-dotted-line {
  content: '';
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba($white, 0.5) 50%,
    transparent 50%
  );
  background-repeat: repeat-x;
  background-size: 10px 4px;
  background-position: 0 0;
  transition: opacity 0.5s ease;
  @include animation('dottedLines 5s infinite linear');
  &.reverse {
    @include animation('dottedLinesReverse 5s infinite linear');
  }
  &.blue {
    background: linear-gradient(
      90deg,
      rgba($blue, 1) 50%,
      transparent 50%
    );
    background-repeat: repeat-x;
    background-size: 10px 4px;
    background-position: 0 0;
  }
  &.red {
    background: linear-gradient(
      90deg,
      rgba($red, 1) 50%,
      transparent 50%
    );
    background-repeat: repeat-x;
    background-size: 10px 4px;
    background-position: 0 0;
  }
}

// Blue parent class
.blue {
  .animated-dotted-line {
    background: linear-gradient(
      90deg,
      rgba($blue, 1) 50%,
      transparent 50%
    );
    background-repeat: repeat-x;
    background-size: 10px 4px;
    background-position: 0 0;
  }
}

// Blue parent class
.red {
  .animated-dotted-line {
    background: linear-gradient(
      90deg,
      rgba($red, 1) 50%,
      transparent 50%
    );
    background-repeat: repeat-x;
    background-size: 10px 4px;
    background-position: 0 0;
  }
}
