@import '../../base';

.SOCCalculatorLeftNav {
  border-right: 5px solid $black;
  overflow-y: auto;
  width: 175px;

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto 1fr 1fr 1fr 1fr 1fr 1fr auto 1fr 1fr 1fr 1fr 1fr 1fr;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__Header {
    background-color: $blue;
    height: 25px;
    color: black;
    font-family: $blenderBold;
    font-size: 14px;
    display: grid;
    align-items: center;
    justify-items: center;
    text-transform: uppercase;
  }

  &__Item {
    background-color: rgba(80, 194, 227, 0.1);
    border-bottom: 2px solid $black;
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    justify-items: center;
    text-transform: uppercase;
    color: $blue;
    cursor: pointer;

    &:hover {
      background-color: rgba(80, 194, 227, 0.2);
    }
  }
}

.SOCCalculatorLeftNavItem {
  input {
    display: block;
    font-size: 20px;
    background: rgba(0, 0, 0, 0);
    border: none;
    height: 20px;
    color: $white;
    text-align: center;
    font-family: $blenderBold;
    padding: 0;
    margin: 10px 20px 0 20px;
    width: 100%;
    outline: 0;

    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  &__Label {
    margin: 0 0 10px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}

@media (max-width: 1000px) {
  .SOCCalculatorLeftNav {
    width: 140px;

    &__Header {
      font-size: 12px;
    }
  }

  .SOCCalculatorLeftNavItem {
    input {
      font-size: 18px;
      height: 18px;
    }

    &__Label {
      font-size: 11px;
    }
  }
}

@media (max-height: 900px) {
  .SOCCalculatorLeftNav {
    &__Header {
      font-size: 12px;
      height: 20px;
    }
  }

  .SOCCalculatorLeftNavItem {
    input {
      margin-top: 5px;
      font-size: 18px;
      height: 18px;
    }

    &__Label {
      margin-bottom: 5px;
      font-size: 11px;
    }
  }
}

@media (max-height: 750px) {
  .SOCCalculatorLeftNavItem {
    input {
      font-size: 14px;
      height: 14px;
    }

    &__Label {
      font-size: 10px;
    }
  }
}
