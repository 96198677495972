@import '../base';

.player--textLines {
  display: inline-block;
  z-index: 9;
  display: grid;
  width: 120px;
  grid-template-columns: repeat(8, 1fr);
  &.group-2 {
    .player--textLines-group:nth-child(2n + 2) {
      padding-right: 7px;
    }
  }
  &.group-4 {
    .player--textLines-group:nth-child(4n + 4) {
      padding-right: 7px;
    }
  }
  .player--textLines-group {
    flex: 1;
    width: 10px;
    height: 22px;
    text-align: center;
    justify-self: center;
    color: $white;
    font-size: 18px;
    font-family: 'Blender Thin';
  }
}
