.MitreMappingChart {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  &__Chart {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    margin: 0px 20px 0px 30px;
    padding: 0px 10px 0px 0px;
  }

  &__Scroll {
    display: flex;
  }

  &__ChartHeader {
    flex: 0 0 48px;
    display: flex;
    flex-direction: row;
    margin: 0px 30px;
  }

  &__Column {
    flex: 1 1 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    margin: 0px 2px 0px 0px;

    &:last-child {
      margin: 0px;
    }
  }

  &__Header {
    box-sizing: border-box;
    flex: 0 1 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 48px;
    min-height: 48px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 100%;
    background: #1f222f;
    margin: 0px 0px 2px 0px;
    padding: 8px;
    text-align: center;
  }

  &__Count {
    flex: 0 1 14px;
    display: flex;
    flex-direction: row;
    height: 14px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 6px;
    line-height: 100%;
    padding: 4px 6px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    box-sizing: border-box;
    white-space: pre;
    transition: all 0.2s ease;
  }

  &__Cell {
    flex: 0 0 58px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    margin: 0px 0px 2px 0px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 120%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  &__Info {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    transition: opacity 0.2s ease;
  }

  &__Title {
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__Filters {
    position: absolute;
    bottom: 4px;
    width: 100%;
  }

  &__FilterBars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__FilterBar {
    background: rgba(31, 34, 47, 0.3);
    border-radius: 4px;
    width: 8px;
    height: 4px;
    margin: 0px 2px 0px 0px;

    &:last-child {
      margin: 0px;
    }
  }

  &__Footer {
    flex: 0 1 60px;
    display: flex;
    flex-direction: column;
    height: 60px;
    min-height: 60px;
    width: 100%;
    background: #1f222f;
  }

  &__Controls {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 30px;
    box-sizing: border-box;
  }

  &__ControlLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    margin: 0px 24px 0px 0px;
  }

  &__FilterButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    border-radius: 32px;
    padding: 0px 16px;
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    background: rgba(255, 255, 255, 0.1);
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    font-family: 'IBM Plex Sans';
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  &__Dot {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin: 0px 10px 0px 0px;
    background: white;
    transition: all 0.2s ease;
  }

  &__Bar {
    width: 2px;
    height: 32px;
    margin: 0px 24px 0px 16px;
    background: rgba(255, 255, 255, 0.1);
  }

  &__Cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__Tooltip {
    position: absolute;
    width: 272px;
    background: #1f222f;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;

    & > h1 {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 150%;
      margin: 0px 0px 10px 0px;
    }

    & > p {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 150%;
      margin: 0px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__ResetButton {
    cursor: pointer;
    width: 86px;
    height: 32px;
    background: #f36c21;
    border-radius: 4px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      background: lighten(#f36c21, 10%);
    }
  }

  &__Select {
    cursor: pointer;
  }

  &__Container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 32px;
    overflow: hidden;
    position: relative;
  }

  &__LeftContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }

  &__RightContainer {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }

  &__CenterContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    min-height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  &--blue {
    background: #50c2e3;

    &:hover {
      background: lighten(#50c2e3, 10%);
    }
  }

  &--dark-blue {
    background: #0085c9;

    &:hover {
      background: lighten(#0085c9, 10%);
    }
  }

  &--red {
    background: #f36c21;

    &:hover {
      background: lighten(#f36c21, 10%);
    }
  }

  &--warn {
    background: #c53d43;
  }

  &--green {
    background: #36a170;

    &:hover {
      background: lighten(#36a170, 10%);
    }
  }

  &--yellow {
    background: #ffbd00;

    &:hover {
      background: lighten(#ffbd00, 10%);
    }
  }

  &--hide {
    opacity: 0;
  }

  &--fade {
    opacity: 0.2;
  }

  &--unfade {
    background: rgba(255, 255, 255, 0.3);
  }

  &--shrink {
    flex: 0 0 0px;
    height: 0px;
    max-height: 0px;
    padding: 0;
    transform: scaleY(0);
    margin: 0;
  }
}
