@import '../base';

$series: 5;

.Player__BoxScanner {
  position: relative;
  .shimmer {
    @include animation(shimmer 0.2s ease);
  }
  svg {
    position: absolute;
    transform-origin: center center;
    overflow: visible;
    opacity: 0;
  }
  circle {
    transform-origin: center center;
  }
  &__Box {
    transform-origin: center center;
  }
  &__Offset {
    position: absolute;
  }
}
