@import '../_base.scss';

.MitreSelect {
  outline: none;

  display: grid;
  width: auto;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  align-items: center;
  width: 178px;
  justify-items: left;

  &__Caption {
    position: relative;
    font-family: 'Blender Book';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
  }

  &__Label {
    position: relative;
    cursor: pointer;
    width: 100%;
    outline: none;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: 'Blender Book';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    align-items: center;
    justify-items: left;
    background: rgba(255, 255, 255, 0.1);

    select {
      cursor: pointer;
      width: 100%;
      text-transform: capitalize;
      background: transparent;
      color: white;
      border: none;
      font-size: 14px;
      z-index: 2;
      font-family: 'Blender Book';
      height: 35px;
      line-height: 35px;
      padding: 0 25px 0 10px;
      outline: none;
      box-sizing: border-box;

      // Remove the arrows
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    &__Arrow {
      position: absolute;
      right: 7px;
      top: 11px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      z-index: 1;
    }
  }
}
