@import '../../base';

.SOCCalculatorDetail {
  &__Section {
    grid-auto-columns: 1fr 160px 160px 160px 160px;
  }

  &__SectionColumn {
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      background-color: $black;
    }

    &--white,
    &--white:last-child,
    &--blue,
    &--blue:last-child {
      padding-bottom: 10px;
    }
  }

  &__SectionCell {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  &__Savings {
    position: absolute;
    bottom: 0;
    right: 40px;
    height: 30px;
    line-height: 30px;
    text-transform: uppercase;
  }
}

@media (max-height: 900px) {
  .SOCCalculatorDetail {
    &__SectionColumn {
      &--white,
      &--white:last-child,
      &--blue,
      &--blue:last-child {
        padding-bottom: 5px;
      }
    }

    &__Savings {
      right: 35px;
    }
  }
}

@media (max-width: 1100px) {
  .SOCCalculatorDetail {
    &__Section {
      grid-auto-columns: 1fr 135px 135px 135px 135px;
    }
  }
}

@media (max-width: 1000px) {
  .SOCCalculatorDetail {
    &__Section {
      grid-auto-columns: 1fr 120px 120px 120px 120px;
    }
  }
}

@media (max-width: 900px) {
  .SOCCalculatorDetail {
    &__Section {
      grid-auto-columns: 1fr 110px 110px 110px 110px;
    }
  }
}
