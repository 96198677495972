@import '../../base';

.SOCCalculatorGraph {
  margin-top: 60px;

  &__Chart {
    margin-bottom: 30px;
  }

  &__Legend {
    display: flex;
    flex-direction: row;
    height: 115px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -30px;
    padding-left: 30px;
    background-color: $black;
  }

  &__LegendItem {
    display: flex;
    flex-direction: row;
    height: 115px;
    align-items: center;
    justify-content: center;

    &--black {
      background-color: #000000;
      padding: 0 50px;
    }
  }

  &__LegendColor {
    height: 10px;
    width: 10px;
    background-color: $white;
    margin-right: 10px;

    &--red {
      background-color: $red;
    }

    &--yellow {
      background-color: $yellow;
    }

    &--green {
      background-color: $green;
    }

    &--blue {
      background-color: $blue;
    }

    &--orange {
      background-color: $orange;
    }
  }

  &__LegendLabel {
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &--red {
      color: $red;
    }

    &--yellow {
      color: $yellow;
    }

    &--green {
      color: $green;
    }

    &--blue {
      color: $blue;
    }

    &--orange {
      color: $orange;
    }
  }
}
