/*

Progress pie

*/
svg.progress-pie {
  circle {
    transition: stroke-dashoffset 1s linear;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
}
