/*

RANSOMWARE

*/
@import "../base";

//
// Players that often have the same starting position
//

$dots: 6;
$duration: 3s;
$size: 6px;
$delay: $duration / $dots;

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin DonutCenter {
  top: 25%;
  left: 50%;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

// Event Slides
.SlideList.kaseya {
  .SlideList__Column {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      flex: 2;
      .SlideList__Slides {
        display: block;
        column-count: 2;
      }
    }
  }
}

$leftOffset: 40px;
$topOffset: 140px;
$rightStart: 430px;
$width: 500px;
$antsStart: -25px;
.kaseya {
  // Slide 0 - Narrative
  .slide-01 {
    // Information
    .player {
      &.block1 {
        width: $width - 120px;
        left: 0px + $leftOffset;
        top: 140px;
      }
      &.block2 {
        width: $width - 120px;
        left: 0px + $leftOffset;
        top: 313px;
      }
      &.block3 {
        width: $width - 120px;
        left: 0px + $leftOffset;
        top: 526px;
      }
      &.block4 {
        width: $width + 100px;
        left: $rightStart + $leftOffset;
        top: 0px + $topOffset;
      }
      &.block5 {
        width: $width + 100px;
        left: $rightStart + $leftOffset;
        top: 167px + $topOffset;
      }
      &.block6 {
        width: $width + 100px;
        left: $rightStart + $leftOffset;
        top: 334px + $topOffset;
      }
      .animated-dotted-line {
        &.ants-1 {
          position: absolute;
          width: 20px;
          left: $antsStart;
          top: 40px;
          transform: rotate(-180deg);
        }
        &.ants-2 {
          position: absolute;
          width: 400px;
          left: $antsStart;
          top: 40px;
          transform: rotate(90deg);
          transform-origin: bottom left;
        }
        &.ants-3 {
          position: absolute;
          width: 20px;
          left: $antsStart - 20px;
          top: 440px;
          transform: rotate(-180deg);
        }
      }
    }
  }

  // Slide 2 - Cards
  $leftOffset: -25px;
  $topOffset: 100px;
  .slide-02 {
    .player {
      &.card1 {
        top: 0px + $topOffset;
        left: 120px + $leftOffset;
      }
      &.card2 {
        top: 0px + $topOffset;
        left: 360px + $leftOffset;
      }
      &.card3 {
        top: 0px + $topOffset;
        left: 600px + $leftOffset;
      }
      &.card4 {
        top: 0px + $topOffset;
        left: 840px + $leftOffset;
      }
      &.card5 {
        top: 330px + $topOffset;
        left: 120px + $leftOffset;
      }
      &.card6 {
        top: 330px + $topOffset;
        left: 360px + $leftOffset;
      }
      &.card7 {
        top: 330px + $topOffset;
        left: 600px + $leftOffset;
      }
    }
  }

  $leftOffset: 30px;
  .slide-03 {
    .player {
      &.team {
        top: 320px;
        left: 30px + $leftOffset;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left: 130px;
            top: -10px;
            transform: rotate(-45deg);
          }
          &.ants-2 {
            left: 200px;
            top: -80px;
          }
        }
      }
      &.info1 {
        top: 220px;
        left: 360px + $leftOffset;
      }
      &.info2 {
        top: 220px;
        left: 710px + $leftOffset;
      }
    }
  }

  .slide-04 {
    .player {
      &.attacker {
        left: 220px;
        top: 330px;
      }
      &.vsa {
        left: 510px;
        top: 350px;
      }
      &.team {
        left: 790px;
        top: 330px;
      }
      &.dots1 {
        left: 340px;
        top: 410px;
      }
      &.dots2 {
        left: 620px;
        top: 400px;
      }
      &.dots3 {
        left: 620px;
        top: 420px;
      }
      &.message {
        width: 720px;
        left: 200px;
        top: 190px;
      }
    }
  }

  .slide-05 {
    .player {
      &.world {
        left:  120px;
        top: 180px;
        &.off {
          .USAScanner {
            display: none !important;
          }
        }
        .USAScanner {
          position: absolute;
          .dot {
            display: none;
          }
        }
      }
      &.blip {
        top: 360px;
        left: 540px;
      }
      &.crowdstrike {
        left: 510px;
        top: 350px;
      }
      &.circle {
        left: 425px;
        top: 275px;
      }
      &.terminal {
        position: absolute;
        width: 70px;
        height: 70px;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .background {
            stroke: $blue;
            opacity: 1;
          }
        }
        svg.computer {
          transition: opacity 200ms ease;
        }
        &.show-computer {
          .animated-dotted-line {
            background: linear-gradient(
              90deg,
              rgba($blue, 1) 50%,
              transparent 50%
            );
            background-repeat: repeat-x;
            background-size: 10px 4px;
            background-position: 0 0;
          }
        }
        &.hide-line {
          .animated-dotted-line {
            opacity: 0;
          }
          svg {
            .background {
              stroke: $white;
              opacity: 0.2;
            }
          }
        }
      }
      &.terminal1 {
        left: 345px;
        top: 220px;
        .animated-dotted-line {
          transform: rotate(45deg);
          left: 50px;
          top: 80px;
        }
      }
      &.terminal2 {
        left: 285px;
        top: 380px;
        .animated-dotted-line {
          left: 70px;
          top: 35px;
        }
      }
      &.terminal3 {
        left: 345px;
        top: 540px;
        .animated-dotted-line {
          transform: rotate(-45deg);
          left: 50px;
          top: -10px;
        }
      }
      &.terminal4 {
        left: 710px;
        top: 220px;
        .animated-dotted-line {
          transform: rotate(-45deg);
          left: -50px;
          top: 80px;
        }
      }
      &.terminal5 {
        left: 770px;
        top: 380px;
        .animated-dotted-line {
          left: -70px;
          top: 35px;
        }
      }
      &.terminal6 {
        left: 710px;
        top: 540px;
        .animated-dotted-line {
          transform: rotate(45deg);
          left: -50px;
          top: -10px;
        }
      }
    }
  }

  $topOffset: -30px;
  $leftOffset: 220px;
  .slide-06 {
    .player {
      &.team {
        top: 320px + $topOffset;
        left: 30px + $leftOffset;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left: 130px;
            top: -10px;
            transform: rotate(-45deg);
          }
          &.ants-2 {
            left: 200px;
            top: -80px;
          }
        }
      }
      &.info1 {
        top: 220px + $topOffset;
        left: 360px + $leftOffset;
      }
      &.info2 {
        top: 430px + $topOffset;
        left: 360px + $leftOffset;
      }
      &.info3 {
        top: 600px + $topOffset;
        left: 360px + $leftOffset;
      }
      &.alert {
        @include AlertCenter();
      }
    }
  }

  $dotLength: 30px;
  .slide-07 {
    .player {
      &.team1 {
        left: 446px;
        top: 187px;
      }
      &.team2 {
        left: 292px;
        top: 437px;
      }
      &.team3 {
        left: 600px;
        top: 437px;
      }
      &.circle {
        left: 517px;
        top: 430px;
        .animated-dotted-line {
          width: $dotLength;
          opacity: 0;
          transform-origin: bottom left;
          &.show {
            opacity: 1;
          }
          &.ants-1 {
            left: 50%;
            top: $dotLength * -1;
            transform: rotate(90deg);
          }
          &.ants-2 {
            left: 75px;
            top: 50px;
            transform: rotate(35deg);
          }
          &.ants-3 {
            left: -20px;
            top: 70px;
            transform: rotate(-35deg);
          }
        }
      }
    }
  }

  $topOffset: -30px;
  $leftOffset: 80px;
  .slide-08 {
    .player {
      &.team {
        top: 320px + $topOffset;
        left: 30px + $leftOffset;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left: 130px;
            top: -10px;
            transform: rotate(-45deg);
          }
          &.ants-2 {
            left: 200px;
            top: -80px;
          }
        }
      }
      &.info1 {
        top: 220px + $topOffset;
        left: 360px + $leftOffset;
      }
      &.info2 {
        top: 480px + $topOffset;
        left: 360px + $leftOffset;
      }
      &.data {
        top: 410px + $topOffset;
        left: 660px + $leftOffset;
        .update {
          display: block;
          position: absolute;
          opacity: 0;
          top: -2px;
          left: 0px;
          background: $blue;
          width: 12px;
          height: 12px;
          &.show {
            opacity: 1;
          }
        }
      }
      &.intel {
        top: 340px + $topOffset;
        left: 820px + $leftOffset;
      }
    }
  }

  //
  // Slide 9
  //
  $leftOffset: -40px;
  $topOffset: 0;
  $trackWidth: 330px;
  .slide-09 {
    .player {
      &.network {
        left: 160px + $leftOffset;
        top: 300px + $topOffset;
      }
      &.dots {
        left: 370px + $leftOffset;
        top: 395px + $topOffset;
        .player--dots-track {
          width: 150px !important;
        }
      }
      &.shield {
        left: 540px + $leftOffset;
        top: 330px + $topOffset;
      }
      &.badge {
        .player--dots {
          position: absolute;
          right: 100%;
          top: 50%;
          width: $trackWidth;
          transform-origin: 100% 0;
          transition: opacity 0.5s ease;
          .player--dots-track {
            width: $trackWidth;
          }
        }
        &.dimmed {
          .player--dots {
            opacity: 0;
          }
        }
      }
      &.badge1 {
        left: 890px;
        top: 180px;
      }
      &.badge2 {
        left: 890px;
        top: 280px;
      }
      &.badge3 {
        left: 890px;
        top: 380px;
      }
      &.badge4 {
        left: 890px;
        top: 480px;
      }
      &.badge5 {
        left: 890px;
        top: 580px;
      }
    }
  }

  $leftOffset: -10px;
  .slide-10 {
    .player {
      &.team {
        left: 80px + $leftOffset;
        top: 340px;
        .animated-dotted-line {
          left: 130px;
          top: 50%;
          width: 100px;
        }
      }
      &.message {
        left: 319px + $leftOffset;
        top: 250px;
        width: 520px;
      }
      &.data {
        left: 840px + $leftOffset;
        top: 420px;
        .update {
          display: block;
          position: absolute;
          opacity: 0;
          top: -2px;
          left: 0px;
          background: $blue;
          width: 12px;
          height: 12px;
          &.show {
            opacity: 1;
          }
        }
      }
      &.waves {
        left: 940px + $leftOffset;
        top: 270px;
      }
    }
  }

  .slide-11 {
    .player {
      &.shield {
        left: 130px;
        top: 350px;
        .animated-dotted-line {
          left: 110px;
          top: 50%;
          width: 200px;
        }
      }
      &.scanner {
        left: 446px;
        top: 300px;
        width: 220px;
        height: 220px;
        border: 4px solid $blue;
        .Player__DataScan  {
          top: 20px;
          left: 20px;
          width: 180px;
          height: 180px;
          overflow: hidden;
        }
      }
      &.queries {
        left: 446px;
        top: 530px;
        width: 220px;
        h3 {
          font-size: 14px;
          text-transform: uppercase;
          color: $blue;
          position: relative;
          padding: 7px 0 0 20px;
          margin: 0px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            background: $blue;
            height: 10px;
            width: 10px;
            left: 0px;
            top: 10px;
          }
        }
        ul {
          padding: 0px;
        }
        li {
          opacity: 0;
          display: flex;
          grid-gap: 4px;
          margin-bottom: 4px;
          span {
            background: rgba($white, 0.5);
            height: 7px;
            display: block;
            flex: 1;
          }
        }
        &.one {
          ul li:nth-child(1) {
            opacity: 1;
          }
        }
        &.two {
          ul li:nth-child(2) {
            opacity: 1;
          }
        }
        &.three {
          ul li:nth-child(3) {
            opacity: 1;
          }
        }
      }
      &.data {
        left: 680px;
        top: 260px;
        width: 180px;
        height: 310px;
        .query {
          position: absolute;
          width: 12px;
          height: 12px;
          background: $blue;
          opacity: 0;
          transition: opacity 0.2s ease;
          &.show {
            opacity: 1;
          }
          &.query-1 {
            top: 96px;
          }
          &.query-2 {
            top: 152px;
          }
          &.query-3 {
            top: 206px;
          }
        }
        .animated-dotted-line {
          transform-origin: bottom left;
        }
        > .animated-dotted-line {
          left: 0px;
          top: 80px;
          width: 100px;
          opacity: 0;
          &.show {
            opacity: 1;
          }
          > .animated-dotted-line {
            top: 0px;
            left: 100%;
            width: 65px;
            transform: rotate(-90deg);
            > .animated-dotted-line {
              top: 0px;
              left: 100%;
              width: 80px;
              transform: rotate(90deg);
            }
          }
          &.ants-1 {
            top: 100px;
          }
          &.ants-2 {
            top: 155px;
            width: 180px;
          }
          &.ants-3 {
            top: 210px;
            > .animated-dotted-line {
              transform: rotate(90deg);
              width: 60px;
              > .animated-dotted-line {
                transform: rotate(-90deg);
                transform-origin: top left;
              }
            }
          }
        }
      }
      &.terminal {
        position: absolute;
        width: 70px;
        height: 70px;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .background {
            stroke: $blue;
            opacity: 1;
          }
        }
      }
      &.terminal1 {
        left: 870px;
        top: 260px;
      }
      &.terminal2 {
        left: 870px;
        top: 380px;
      }
      &.terminal3 {
        left: 870px;
        top: 500px;
      }
    }
  }

  /*

  SLIDE 12

  */
  .slide-12 {
    .player {
      &.analyst {
        left: 130px;
        top: 340px;
        .animated-dotted-line {
          left: 130px;
          width: 100px;
          top: 50%;
          transform-origin: bottom left;
        }
      }
      &.info {
        left: 370px;
        top: 200px;
      }
      &.shield {
        left: 890px;
        top: 350px;
        .animated-dotted-line {
          left: -110px;
          width: 100px;
          top: 50%;
          transform-origin: bottom left;
        }
      }
      &.data {
        z-index: 99;
        width: 12px;
        height: 12px;
        background: $blue;
        left: 780px;
        top: 412px;
      }
    }
  }

  .slide-13 {
    .player {
      &.shield {
        left: 280px;
        top: 354px;
      }
      &.dots {
        left: 400px;
        top: 420px;
      }
      &.page {
        left: 380px;
        top: 380px;
      }
      &.admin {
        left: 720px;
        top: 340px;
      }
    }
  }

  .slide-14 {
    .player {
      &.donut {
        @include DonutCenter();
      }
      &.info1 {
        left: 60px;
        top: 357px;
      }
      &.file {
        left: 490px;
        top: 320px;
      }
      &.info2 {
        left: 800px;
        top: 230px;
      }
      &.info3 {
        left: 800px;
        top: 508px;
      }
    }
  }

  .slide-15 {
    .player {
      .update {
        background: $blue;
        position: absolute;
        height: 12px;
        width: 12px;
        left: -6px;
        top: -4px;
        z-index: 99;
      }
      .update[data-active="0"] {
        opacity: 0;
      }
      .update[data-active="1"] {
        opacity: 1;
      }
      &.shield {
        left: 340px;
        top: 350px;
      }
      &.customer {
        position: absolute;
        width: 70px;
        height: 70px;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &.customer1 {
        left: 687px;
        top: 242px;
      }
      &.customer2 {
        left: 814px;
        top: 302px;
      }
      &.customer3 {
        left: 750px;
        top: 385px;
      }
      &.customer4 {
        left: 814px;
        top: 462px;
      }
      &.customer5 {
        left: 687px;
        top: 522px;
      }
      &.path1 {
        left: 460px;
        top: 380px;
        .animated-dotted-line {
          &.ants-1 {
            width: 80px;
          }
          &.ants-2 {
            transform-origin: bottom left;
            top: -100px;
            left: 80px;
            width: 100px;
            transform: rotate(90deg);
          }
          &.ants-3 {
            left: 80px;
            top: -100px;
            width: 140px;
          }
        }
      }
      &.path2 {
        left: 460px;
        top: 400px;
        .animated-dotted-line {
          &.ants-1 {
            width: 160px;
          }
          &.ants-2 {
            transform-origin: bottom left;
            top: -60px;
            left: 160px;
            width: 60px;
            transform: rotate(90deg);
          }
          &.ants-3 {
            left: 160px;
            top: -60px;
            width: 190px;
          }
        }
      }
      &.path3 {
        left: 460px;
        top: 420px;
        .animated-dotted-line {
          width: 280px;
        }
      }
      &.path4 {
        left: 460px;
        top: 440px;
        .animated-dotted-line {
          &.ants-1 {
            width: 160px;
          }
          &.ants-2 {
            transform-origin: bottom left;
            top: 0px;
            left: 160px;
            width: 60px;
            transform: rotate(90deg);
          }
          &.ants-3 {
            left: 160px;
            top: 60px;
            width: 190px;
          }
        }
      }
      &.path5 {
        left: 460px;
        top: 460px;
        .animated-dotted-line {
          &.ants-1 {
            width: 80px;
          }
          &.ants-2 {
            transform-origin: bottom left;
            top: 0px;
            left: 80px;
            width: 100px;
            transform: rotate(90deg);
          }
          &.ants-3 {
            left: 80px;
            top: 100px;
            width: 140px;
          }
        }
      }
    }
  }

  $topOffset: -40px;
  .slide-16 {
    .player {
      &.kaseya {
        left: 490px;
        top: 320px + $topOffset;
      }
      &.title {
        width: 712px; // 1112 total width
        left: 200px;
        top: 410px + $topOffset;
        text-align: center;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        color: $white;
        transition: all 0.5s ease;
        &.restored {
          color: $blue;
        }
      }
      &.progress {
        left: 340px;
        top: 450px + $topOffset;
      }
      &.checkmark {
        left: 540px;
        top: 520px + $topOffset;
      }
    }
  }

  $topOffset: -40px;
  .slide-17 {
    .player {
      &.message {
        @include AlertCenter();
      }
      &.title {
        width: 100%;
        top: 160px;
        text-align: center;
        h1 {
          font-size: 48px;
          font-family: Blender Thin;
        }
      }
      &.alert {
        left: 384px;
        top: 320px + $topOffset;
      }
      &.info1 {
        left: 240px;
        top: 320px + $topOffset;
      }
      &.info2 {
        left: 640px;
        top: 320px + $topOffset;
      }
      &.info3 {
        left: 640px;
        top: 540px + $topOffset;
      }
    }
  }

}