/*

RANSOMWARE

*/
@import "../base";
@import "../animations";

//
// Players that often have the same starting position
//

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin DonutCenter {
  top: 25%;
  left: 50%;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

// Use case wrapper
.cloud {

// Slide 1 - Overview
$leftOffset: 100px;
.slide-01 {
  .player {
    &.card1 {
      top: 240px;
      left: 120px + $leftOffset;
    }
    &.card2 {
      top: 240px;
      left: 360px + $leftOffset;
    }
    &.card3 {
      top: 240px;
      left: 600px + $leftOffset;
    }
  }
}

.slide-02 {
  [data-active='1'] {
    &.player.shield div {
      animation: blinkSpace 0.5s linear 0s infinite;
    }
  }
  .player {
    &.attacker {
      @include CardCenter();
    }
    &.dots {
      top: 400px;
      left: 320px;
    }
    &.sparks {
      top: 405px;
      left: 465px;
    }
    &.cloud {
      left: 500px;
      top: 364px;
    }
    &.aws {
      left: 547px;
      top: 397px;
    }
    &.skull {
      left: 545px;
      top: 390px;
    }
    &.shield {
      top: 400px;
      left: 560px;
      div {
        position: absolute;
        left: -100px;
        top: -100px;
        box-sizing: border-box;
        border: 20px solid rgba($white, 0.2);
        width: 200px;
        height: 200px;
        border-radius: 50%;
      }
    }
  }
}

.slide-03 {
  @include keyframes(typed) {
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  $dots: 10;
  $space: 400 / $dots;
  $padding: 25px;
  $duration: 1s;
  $delay: $duration / $dots;
  [data-active='1'] {
    &.player.password.error {
      animation-name: shake;
      animation-delay: 0;
      animation-duration: 500ms;
      animation-iteration-count: 1;
      border-color: $red;
      h3 {
        visibility: visible;
        opacity: 1;
      }
      span {
        background: $red;
      }
    }
    &.player.password.type {
      span {
        @for $i from 1 through $dots {
          $wait: $i * $delay;
          @if ($i > 1) {
            $wait: $wait + (1 / random(100));
          }
          &:nth-of-type(#{$i}) {
            left: #{($space * ($i - 1)) + $padding};
            animation-delay: $wait;
            animation-name: typed;
            animation-duration: 0ms;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
          }
        }
      }
    }
  }
  .player {
    &.password {
      width: 420px;
      height: 70px;
      top: centerTop(70);
      left: centerLeft(420);
      border: 2px solid $white;
      transition: all 0.2s ease;

      h3 {
        display: block;
        position: absolute;
        top: -40px;
        font-size: 14px;
        color: $red;
        opacity: 0;
        visibility: hidden;
      }

      span {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: $padding;
        border-radius: 50%;
        background: $white;
        display: block;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease;
        @for $i from 1 through $dots {
          &:nth-of-type(#{$i}) {
            left: #{($space * ($i - 1)) + $padding};
          }
        }
      }
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

.slide-04 {
  .player {
    &.incident {
      left: 440px;
      top: 270px;
    }
  }
}

.slide-05 {
  .player {
    &.client {
      left:  270px;
      top: 340px;
    }
    &.login {
      left: 580px;
      top: 260px;
    }
  }
}

.slide-06 {
  .player {
    &[data-active='1'] {
      &.dots span {
        visibility: visible !important;
        opacity: 1 !important;
        transform: scale(1) !important;
      }
    }
    &.world {
      left:  120px;
      top: 180px;
      &.off {
        .USAScanner {
          display: none !important;
        }
      }
      .USAScanner {
        position: absolute;
        .dot {
          display: none;
        }
      }
    }
    &.dots {
      left: 0;
      top: 0;
      span {
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-family: $blenderBold;
        font-size: 18px;
        text-align: center;
        line-height: 40px;
        z-index: 99;
        background: $blue;
        visibility: hidden;
        opacity: 0;
        transform: scale(0.01);
        transition: all 1s ease;
        &:nth-child(1) {
          left: 320px;
          top: 360px;
        }
        &:nth-child(2) {
          left: 560px;
          top: 300px;
        }
      }
    }
  }
}

.slide-07 {
  .player {
    &.chart {
      left: 280px;
      top: 220px;
    }
  }
}

//
// Slide 08 - Client Alert
//
.slide-08 {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

.slide-09 {
  .player {
    &.client {
      left:  270px;
      top: 340px;
    }
    &.request {
      left: 580px;
      top: 260px;
    }
  }
}

$leftOffset: 150px;
$topOffset: 0;
.slide-10 {
  .player {
    &.analyst {
      left: (496px + -$leftOffset);
      top: 320px + $topOffset;
    }
    &.client {
      left: (496px + $leftOffset);
      top: 320px + $topOffset;
    }
    &.phone {
      left: 535px;
      top: 375px + $topOffset;
      z-index: 2;
    }
    &.dots {
      left: 480px;
      top: 400px;
      opacity: 0.4 !important;
    }
    &.check {
      left: 685px;
      top: 255px + $topOffset;
    }
  }
}

.slide-11 {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

.slide-12 {
  .player {
    &.events1 {
      left: 300px;
      top: 350px;
    }
    &.events2 {
      left: 600px;
      top: 350px;
    }
  }
}

.slide-13 {
  .player {
    &.events {
      .player--textBlock-section-title.multiple div {
        margin-top: 0 !important;
      }
      left: 360px;
      top: 320px;
    }
  }
}

.slide-14 {
  .player {
    &.events {
      left: 440px;
      top: 300px;
    }
  }
}

.slide-15 {
  .player {
    &.user {
      left:  270px;
      top: 340px;
    }
    &.aws {
      left: 580px;
      top: 260px;
    }
    &.detection {
      left: 580px;
      top: 380px;
    }
  }
}

.slide-16 {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

}
