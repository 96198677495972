@import '../components/base';

.Template__TeamIsolatesHost {
  .connections {
    &-ring {
      position: absolute;
      width: 420px;
      height: 420px;
      transform-origin: center center;
      border: 5px solid rgba($white, 0);
      border-radius: 50%;
      transition: all 1s ease;
      box-sizing: border-box;
      box-shadow: inset 0px 0px 0px rgba($blue, 0);
      &.smaller {
        transform: scale(0.7);
        box-shadow: inset 0px 0px 100px rgba($blue, 0.5);
        border: 5px solid rgba($blue, 0.5);
      }
    }
    &-wrapper {
      position: absolute;
      width: 420px;
      height: 420px;
      .Player__Path {
        position: absolute;
        &:nth-child(1) {
          left: 0px;
          top: 0px;
        }
        &:nth-child(2) {
          left: 210px;
          top: 0px;
        }
        &:nth-child(3) {
          left: 270px;
          top: 0px;
        }
        &:nth-child(4) {
          left: 270px;
          top: 210px;
        }
        &:nth-child(5) {
          left: 270px;
          top: 260px;
        }
        &:nth-child(6) {
          left: 210px;
          top: 270px;
        }
        &:nth-child(7) {
          left: 0px;
          top: 260px;
        }
      }
    }
  }
  .player {
    &.team {
      left: 191px;
      top: 355px;
    }
    &.path {
      left: 296px;
      top: 414px;
    }
    &.connections {
      left: 350px;
      top: 207px;
      transform-origin: center center;
      border-radius: 50%;
    }
    &.icon {
      left: 512px;
      top: 367px;
    }
  }
}
