@import '_base';
@import 'animations';

.BottomNav {
  display: flex;
  flex-direction: row;

  &__ListButton {
    padding: 4px;
    cursor: pointer;
    margin-left: 11px;

    // Center the bad boy
    display: grid;
    align-items: center;
    justify-items: center;

    &:hover {
      svg {
        rect {
          fill-opacity: 1;
        }
      }
    }
  }

  &__Spacer {
    flex: 1;
    display: grid;
    height: 45px;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      left: 0;
      top: 50%;
      content: '';
      display: inline-block;
      height: 2px;
      margin: auto 0px;
    }

    // The line
    &:before {
      width: 100%;
      background: rgba($white, 0.2);
      z-index: 1;
    }

    &:after {
      width: 0%;
      background: $blue;
      transition: all 1s ease;
      z-index: 2;
    }

    &--active:after {
      width: 100%;
    }
  }

  &__Slide {
    padding: 10px 3px;
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;

    &__ClientCommunications {
      position: absolute;
      top: -5px;
      left: 50%;
    }

    &--active {
      animation: shimmer 0.2s linear;
    }
  }
}
