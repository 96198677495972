@import '../base';

.DemoGoals {
  padding: 0 0 0 40px;
  position: relative;
  &__Title {
    font-size: 24px;
  }
  &__Line {
    height: 4px;
    background: rgba($white, 0.2);
    margin: 20px 0;
  }
  &__Goal {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    position: relative;
    margin: 0 0 20px 0;
    svg {
      position: absolute;
      left: -40px;
    }
  }
}
