@import '../base';

@include keyframes(sparksMove) {
  0% {
    left: 0%;
    opacity: 1;
  }
  50% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

[data-active='0'] {
  .player--sparks .spark-track span {
    animation: none !important;
  }
}

.player--sparks {
  .spark-track {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    span {
      display: block;
      position: absolute;
      background-color: $white;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
    }
  }
}
