@import 'base';

.Kaseya__Message {
  border: 10px solid $red;
  padding: 30px;
  font-size: 20px;
  line-height: 24px;
  background: rgba($white, 0.1);
  h2 {
    margin: 10px 0 0 0;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: $blenderBold;
  }
  h3 {
    margin: 0 0 10px 0;
    font-size: 20px;
    font-weight: 400;
  }
  div {
    font-weight: 100;
  }
  b {
    text-decoration: underline;
  }
  //
  // Wordless mode
  //
  &.wordless {
    padding: 20px;
    border-color: rgba($white, 0.5);
    h2 {
      font-size: 60px;
      line-height: 40px;
      letter-spacing: -4px;
      text-transform: none;
    }
    .bricks {
      span {
        display: block;
        height: 20px;
        background: rgba($white, 0.4);
      }
      .subtitle {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 100px 50px 50px;
        grid-gap: 4px;
      }
      .title {
        margin-top: 30px;
        display: grid;
        span {
          width: 130px;
          height: 30px;
        }
      }
      .rows {
        span {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }
}
