@import '../base';

@include keyframes(dottedLines) {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@include keyframes(dottedLinesReverse) {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@include keyframes(dotsGrow) {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

// Dot animation growing when activated
.player[data-active='1'] {
  .player--circleIcon {
    .player--circleIcon-circle {
      .player--circleIcon-dots {
        &:after {
          @include animation('dottedLines 5s infinite linear');
        }
      }
    }
    &.right {
      .player--circleIcon-circle {
        .player--circleIcon-dots {
          &:after {
            @include animation('dottedLines 5s infinite linear');
          }
        }
      }
    }
  }
}

.hide-title {
  .player--circleIcon-title {
    opacity: 0 !important;
  }
}

.player--circleIcon {
  position: relative;
  box-sizing: border-box;
  .player--circleIcon-circle {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    .player--circleIcon-dots {
      position: absolute;
      box-sizing: border-box;
      transform-origin: 100% 0%;
      right: calc(100% + 10px);
      transform: rotate(25deg);
      display: block;
      height: 4px;
      width: 100px;
      &:after {
        display: block;
        position: absolute;
        box-sizing: border-box;
        content: '';
        top: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba($white, 0.5) 50%,
          transparent 50%
        );
        background-repeat: repeat-x;
        background-size: 10px 4px;
        background-position: 0 0;
      }
    }
  }
  .player--circleIcon-title {
    font-family: 'Blender Bold';
    font-size: 14px;
    color: $white;
    position: absolute;
    text-transform: uppercase;
    height: 20px;
    top: 50%;
    left: 100%;
    margin: -10px 0 0 10px;
    white-space: nowrap;
    box-sizing: border-box;
    @include transition(all 0.2s ease);
  }
  &.right {
    .player--circleIcon-circle {
      .player--circleIcon-dots {
        left: calc(100% + 10px);
        right: auto;
        transform-origin: 0% 100%;
      }
    }
    .player--circleIcon-title {
      right: 100%;
      left: auto;
      margin: -10px 10px 0 0;
    }
  }
  &.bottom,
  &.center {
    .player--circleIcon-title {
      width: 200px;
      top: 80px;
      left: calc(50% - 100px);
      text-align: center;
      margin: 0;
    }
  }
}
