@import '../base';

@keyframes ringAnimateGrow {
  0% {
    transform: scale(0);
    opacity: 0.3;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

.Player__MapBlip {
  position: absolute;
  border-radius: 50%;
  &__Ring {
    left: -2px; // Compensate for border width
    top: -2px; // Compensate for border width
    position: absolute;
    transform-origin: center center;
    border: 2px solid $blue;
    border-radius: 50%;
    transform: scale(0);
    animation: ringAnimateGrow 2s linear infinite;
    &:nth-child(1) {
      animation-delay: 0ms;
    }
    &:nth-child(2) {
      animation-delay: 500ms;
    }
    &:nth-child(3) {
      animation-delay: 1000ms;
    }
    &:nth-child(4) {
      animation-delay: 1500ms;
    }
  }
}
