@import 'base';

@keyframes modal-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Modal {
  z-index: $z-modal;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  animation: modal-fade-in 0.25s;

  display: grid;
  grid-template:
    'top' 1fr
    'body' auto
    'btm' 1fr /
    1fr;

  &__Body {
    border-top: 4px solid rgba(white, 0.1);
    border-bottom: 4px solid rgba(white, 0.1);
    background-color: $black;
    padding: 100px;
    position: relative;
    grid-area: body;

    &--full-page {
      overflow: hidden;
      padding: 0;
    }

    &__Close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;

      &:hover {
        svg {
          path {
            fill-opacity: 1;
          }
        }
      }
    }

    &__Content {
      width: 100%;

      h3 {
        font-family: $blenderThin;
        line-height: 34px;
        font-size: 34px;
        margin: 0;
        color: $blue;
        letter-spacing: -1px;
      }

      p {
        font-family: $blenderThin;
        margin: 20px 0;
        line-height: 22px;
        font-size: 15px;
      }

      &--full-page {
        height: 100%;
        overflow: auto;
      }
    }
  }

  &--full-page {
    grid-template:
      'body' 1fr /
      1fr;
  }
}
