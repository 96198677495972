.ScrollView {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &__Bar {
    transition: opacity 0.2s ease;
    position: absolute;
    right: 0px;
    border-radius: 2px;
    width: 4px;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    height: 0;
  }

  &__Content {
    position: relative;
  }
}
