@import './base';

.DotIndicator {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-gap: 10px;

  &__HalfDot {
    background-color: #fac903;
    opacity: 0;
    height: 10px;
    width: 5px;

    transition: opacity 0.25s;
  }

  &__Dot {
    background-color: rgba(0, 0, 0, 0.2);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    overflow: hidden;

    transition: background-color 0.25s;

    &--half {
      .DotIndicator__HalfDot {
        opacity: 1;
      }
    }

    &--whole {
      background-color: #fac903;
    }
  }
}
