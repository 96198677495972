@import '../base';
@import '../animations';

.Player__ListComplete {
  &__Title {
    color: $blue;
    font-size: 14px;
    padding: 10px 10px 10px 20px;
    margin: 0px;
    border-top: 4px solid $blue;
    h3 {
      margin: 0px;
      height: 20px;
      line-height: 20px;
    }
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: $blue;
      position: absolute;
      top: 19px;
      left: 0px;
    }
  }
  &__Items {
    &__Item {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 30px 1fr;
      margin-bottom: 10px;
      &.shimmer {
        animation: shimmer 0.2s ease;
      }
      span {
        display: block;
        background: rgba($white, 0.2);
        line-height: 14px;
        padding: 7px 7px 3px 7px;
        text-transform: uppercase;
        box-sizing: border-box;
        border: 2px solid transparent;
        transition: all 0.2s ease;
        &:nth-child(1) {
          background: transparent;
          border: 2px solid rgba($white, 0.2);
          svg {
            opacity: 0;
          }
        }
      }
      &.processing {
        span {
          border-color: $blue;
          &:nth-child(2) {
            background: transparent;
          }
        }
      }
      &.complete {
        span {
          &:nth-child(1) {
            background: $blue;
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
