@import '../../base';

.SOCCalculatorSummary {
  &__SectionColumn {
    &:not(:first-child) {
      .SOCCalculatorSummary__SectionCell {
        &:last-child,
        &:not(:first-child) {
          align-items: end;
          padding-bottom: 10px;
        }
      }
    }
  }

  &__SectionCell {
    &--editable {
      background-color: rgba(80, 194, 227, 0.1);
      border-left: 3px solid $grey-xdark;
      width: calc(100% - 13px);
      padding: 10px 10px 10px 0;

      .KeyValueSet,
      .KeyValueSet__KeyValue,
      .KeyValueSet__Key,
      .KeyValueSet__Value {
        width: 100%;
        text-align: right;
      }
    }
  }

  &__EsentireCost {
    grid-auto-columns: 1fr 1fr 1fr 1fr 256px;
  }

  &__Delta {
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
    background-color: $black;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
  }
}

@media (max-height: 900px) {
  .SOCCalculatorSummary {
    &__SectionColumn {
      &:not(:first-child) {
        .SOCCalculatorSummary__SectionCell {
          &:last-child,
          &:not(:first-child) {
            padding-bottom: 5px;
          }
        }
      }
    }

    &__SectionCell {
      &--editable {
        padding: 5px 5px 5px 0;
        width: calc(100% - 8px);
      }
    }
  }
}
