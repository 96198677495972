@import 'base';

.Login {
  display: grid;
  grid-template-areas:
    'top'
    'main'
    'bottom';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-items: center;
  align-content: center;
  background-color: $grey-xdark;

  &__Header {
    grid-area: top;

    padding: 30px;
  }

  &__Main {
    grid-area: main;

    display: flex;
    flex-direction: column;
    position: relative;
  }
}
