@import '../base';

.GraphicsTab {
  &__BackLink {
    float: right;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 30px;
  }

  h2 {
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: 3px solid $blue;
  }

  h3 {
    margin: 0 0 20px 0;
    color: $grey;
  }

  &__Misc,
  &__Cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;

    & > div {
      margin-left: 30px;
    }
  }

  &__Icons {
    display: grid;
    grid-template-columns: repeat(auto-fill, 40px);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding-bottom: 20px;

    > div {
      display: grid;
      grid-template:
        'title' auto
        'icon' 1fr /
        1fr;
      grid-row-gap: 10px;
      align-items: center;
      justify-items: center;

      > h3 {
        margin: 0;
        display: flex;
        justify-content: center;
        min-width: 0;
        justify-self: stretch;

        > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__Alerts {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(349px, 1fr));
    margin-bottom: 30px;
  }

  &__Players {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 30px;

    & > div {
      position: relative;
    }
  }

  &__Colors {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, auto));
    grid-gap: 10px;
  }

  &__Swatch {
    display: grid;
    grid-template:
      'NW  N    NE  ' auto
      'W   C    E   ' auto
      'SW  S    SE  ' auto /
      auto auto auto;
    min-width: 90px;
    min-height: 90px;

    dt,
    dd {
      margin: 0;
      display: grid;
      align-items: center;
      justify-items: center;
    }

    dt {
      grid-area: NW/NW / NE/NE;
    }
    dd {
      grid-area: SW/SW / SE/SE;
    }
  }
}
