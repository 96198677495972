@import '../base';

.KitchenSink {
  display: grid;
  grid-template:
    'navigation' auto
    'body      ' 1fr /
    1fr;

  &__Navigation {
    grid-area: navigation;
    padding: 10px 10px 0px 10px;
    display: grid;
    grid-template:
      'left right' auto /
      auto 1fr;
    border-bottom: 2px solid $blue;

    .Button {
      border-bottom: 0;
    }

    h2 {
      margin: 0;
      white-space: nowrap;
      align-self: center;
      justify-self: flex-end;
    }
  }

  &__Tabs {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-column-gap: 10px;
  }

  & &__BackLink {
    align-self: flex-end;
    padding: 5px;
    line-height: unset;
    height: auto;
  }

  &__Body {
    display: grid;
    grid-template: 'body' 1fr / 1fr;
    grid-area: body;
    padding: 30px;
  }
}
