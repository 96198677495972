.ChartShell {
  display: grid;
  grid-template:
    '.           top          .          ' min-content
    'left        main         right      ' min-content
    '.           bottom       .          ' min-content /
    min-content min-content min-content;
  align-items: center;
  justify-items: center;

  &__LeftSection {
    grid-area: left;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    color: rgba(255, 255, 255, 0.6);
    text-align: center;

    @media (max-width: 1111px) {
      font-size: 12px;
    }

    &:empty {
      display: none;
    }
  }

  &__TopSection {
    grid-area: top;
    font-size: 19px;
    padding: 20px 0;

    @media (max-width: 1111px) {
      font-size: 16px;
    }

    &:empty {
      display: none;
    }
  }

  &__BottomSection {
    grid-area: bottom;

    &:empty {
      display: none;
    }
  }

  &__MainSection {
    grid-area: main;

    &:empty {
      display: none;
    }
    .yAxis text {
      font-size: 10px;
      fill: #f5f5f5;
    }

    .xAxis text {
      font-size: 10px;
      text-transform: uppercase;
      fill: #f5f5f5;
    }

    .recharts-legend-item {
      &:not(:first-child) {
        margin-left: 20px;
      }

      text-transform: uppercase;
    }

    .yAxis text {
      font-size: 10px;
      fill: #f5f5f5;
    }

    .xAxis text {
      font-size: 10px;
      text-transform: uppercase;
      fill: #f5f5f5;
    }

    .recharts-legend-item {
      &:not(:first-child) {
        margin-left: 20px;
      }

      text-transform: uppercase;
    }
  }
}
