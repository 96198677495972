@import '../base';

.player--dataMoving {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 12px;
  &.blue {
    .player--dataMoving-square,
    .player--dataMoving-dot:after {
      background: $blue;
    }
  }
  &.red {
    .player--dataMoving-square,
    .player--dataMoving-dot:after {
      background: $red;
    }
  }
  &.green {
    .player--dataMoving-square,
    .player--dataMoving-dot:after {
      background: $green;
    }
  }
  .player--dataMoving-dots {
    position: absolute;
    width: 100%;
    height: 100%;
    right: calc(100% + 12px);
    .player--dataMoving-square {
      position: absolute;
      z-index: 3;
      width: 12px;
      height: 12px;
      transform-origin: 50% 50%;
      left: 100%;
    }
    .player--dataMoving-dot {
      position: absolute;
      z-index: 2;
      width: 12px;
      height: 12px;
      opacity: 0;
      &:after {
        position: absolute;
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        top: 3px;
        left: 3px;
      }
    }
  }
}
