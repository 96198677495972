/*

RANSOMWARE

*/
@import "../base";

.skull-boxes {
  padding: 20px;
  display: grid;
  gap: 14px;
  border: 4px solid $red;
  box-sizing: border-box;
  .skull-box {
    position: relative;
    background: $red;
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 5px 5px 25px;
    svg {
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }
}

.proxyshell {
  
  // Slide 1
  $topOffset: -20px;
  .slide-01 {
    .player {
      &.title1 {
        left: 86px;
        top: 142px + $topOffset;
        width: 440px;
      }
      &.title2 {
        left: 86px;
        top: 498px + $topOffset;
        width: 440px;
      }
      &.title3 {
        left: 586px;
        top: 142px + $topOffset;
        width: 440px;
      }
      &.title4 {
        left: 586px;
        top: 356px + $topOffset;
        width: 440px;
      }
      &.title5 {
        left: 586px;
        top: 571px + $topOffset;
        width: 440px;
      }
      &.path1 {
        left: 306px;
        top: 478px + $topOffset;
      }
      &.path2 {
        left: 306px;
        top: 178px + $topOffset;
      }
      &.path3 {
        left: 806px;
        top: 336px + $topOffset;
      }
      &.path4 {
        left: 806px;
        top: 551px + $topOffset;
      }
    }
  }

  // Slide 1 - Cards
  $leftOffset: -25px;
  .slide-02 {
    .player {
      .Stage {
        height: 350px;
      }
      &.card1 {
        top: 240px;
        left: 120px + $leftOffset;
      }
      &.card2 {
        top: 240px;
        left: 360px + $leftOffset;
      }
      &.card3 {
        top: 240px;
        left: 600px + $leftOffset;
      }
      &.card4 {
        top: 240px;
        left: 840px + $leftOffset;
      }
    }
  }

  .slide-03 {
    .player {
      &.card {
        left: 155px;
        top: 328px;
      }
      &.info {
        left: 375px;
        top: 320px;
      }
      &.reveal {
        left: 785px;
        top: 358px;
      }
      &.path1 {
        left: 275px;
        top: 350px;
      }
      &.path2 {
        left: 684px;
        top: 375px;
      }
      &.path3 {
        left: 594px;
        top: 413px;
      }
      &.path4 {
        left: 671px;
        top: 451px;
      }
      &.path5 {
        left: 471px;
        top: 486px;
      }
    }
  }

  .slide-10 {
    .player {
      position: absolute;
      &.team1 {
        left: 106px;
        top: 333px;
        .Player__Path {
          position: absolute;
          left: 52px;
          top: -98px;
        }
      }
      &.team2 {
        left: 901px;
        top: 333px;
      }
      &.info1 {
        left: 271px;
        top: 212px;
      }
      &.info2 {
        left: 561px;
        top: 212px;
        .paths {
          position: absolute;
          top: 0;
          left: 0;
          .Player__Path {
            position: absolute;
            &:nth-child(1) {
              left: 296px;
              top: 58px;
            }
            &:nth-child(2) {
              left: 185px;
              top: 134px;
            }
            &:nth-child(3) {
              left: 114px;
              top: 162px;
            }
            &:nth-child(4) {
              left: 128px;
              top: 180px;
            }
            &:nth-child(5) {
              left: 268px;
              top: 187px;
            }
            &:nth-child(6) {
              left: 116px;
              top: 210px;
            }
            &:nth-child(7) {
              left: 295px;
              top: 244px;
            }
          }
        }
      }
    }
  }

}