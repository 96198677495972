@import '../base';

.Player__InfoBlock {
  box-sizing: border-box;
  > div {
    flex: 0 1 auto;
  }
  &__Icon {
    display: grid;
    width: 30px;
    height: 30px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  &__Sections {
    color: $white;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    // padding: 4px;
    display: grid;
    .Sections__Section {
      opacity: 0;
      &[data-active='1'] {
        opacity: 1;
      }
    }
    .Type {
      &__Alert {
        background: $red;
        border-color: $red;
        display: grid;
        grid-template-columns: 14px 1fr;
        gap: 10px;
        padding: 10px;
        font-size: 14px;
        font-family: $blenderBold;
        text-transform: uppercase;
      }
      &__Title {
        font-family: $blenderBold;
        text-transform: uppercase;
        margin: 1px 0px 4px 0px;
        padding: 4px;
        &:only-child {
          margin: 0px;
        }
      }
      &__Header {
        position: relative;
        text-transform: uppercase;
        font-family: $blenderBold;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 0px 0px 20px;
        border-top-width: 3px;
        border-top-style: solid;
        &__Block {
          position: absolute;
          width: 10px;
          height: 10px;
          top: 15px;
          left: 0px;
        }
        &.has-icon {
          display: flex;
          align-items: center;
          padding-left: 0px;
          > svg {
            margin-right: 10px;
          }
        }
      }
      &__Spacer {
        height: 10px;
      }
      &__List {
        &__Item {
          position: relative;
          margin: 0px 0px 6px 0px;
          padding: 0px 0px 0px 12px;
          &:last-child {
            margin: 0px;
          }
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: rgba($white, 0.5);
            left: -2px;
            top: 5px;
          }
        }
      }
      &__Cells {
        &__Cell {
          display: inline-block;
          padding: 5px 8px;
          line-height: 18px;
          margin-right: 10px;
          font-size: 14px;
          letter-spacing: -0.02em;
          font-family: $blenderBold;
        }
      }
      &__Text {
      }
      &__Links {
      }
      &__Grid {
        display: grid;
        grid-auto-columns: min-content;
        column-gap: 10px;
        row-gap: 4px;
        &__Item {
          padding: 5px 8px;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.02em;
          &.small {
            font-size: 90%;
          }
          &:nth-child(odd) {
            font-family: $blenderBold;
          }
        }
      }
    }
  }
}
