@import './base';

.StoryBlock {
  display: block;
  padding: 20px;
  position: relative;
  border: 4px solid $red;
  --margin: 0 0 10px 0;
  --titleOpacity: 1;
  transition: all 0.2s ease;

  &.no-description {
    --margin: 0;
  }
  &.inactive {
    --titleOpacity: 0.5;
  }
  &.active {
    --titleOpacity: 1;
  }

  &__Title {
    font-size: 15px;
    opacity: var(--titleOpacity);
    margin: var(--margin);
    line-height: 18px;
    padding-right: 40px;
    letter-spacing: -1px;
    font-family: $blenderBold;
    vertical-align: middle;
    transition: all 0.2s ease;
    svg g {
      transition: fill 0.2s ease;
    }
  }
  &__Description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  &__Icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
