@import './base';
@import './animations';
@import './malicious/starting-positions';
@import './ransomware/starting-positions';
@import './powershell/starting-positions';
@import './esinsider/starting-positions';
@import './sunwalker/starting-positions';
@import './kaseya/starting-positions';
@import './waffle/starting-positions';
@import './log/starting-positions';
@import './proxyshell/starting-positions';
@import './svg/shield';
@import './cloud/styles';

.Slide {
  display: grid;

  height: 814px;
  width: 1112px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  animation: transform 0.5s;
  z-index: 1;

  &--disable-animations * {
    animation: none !important;
  }

  .background {
    text-align: center;
    padding: 30px;
    grid-area: 1/1 / 2/2;
  }

  .drawing {
    grid-area: 1/1 / 2/2;
  }

  //
  // Content
  //
  .content {
    height: 100%;
    display: grid;
    position: relative;
    // grid-template-columns: 1;
    // grid-template-rows: 1;
    // grid-template-areas: 'main';

    .player {
      z-index: 2;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      // transition: all .2s ease;
      display: inline-block;
      // ~ LPK
      // This doesn't seem like something I would add, but it's breaking a bunch of slides
      // grid-area: main;

      &.toppest {
        z-index: 99;
      }

      &-title {
        position: absolute;
        bottom: calc(100% + 10px);
        width: 100%;
        left: 0;
        text-align: center;
        color: rgba($white, 0.5);
        font-family: 'Blender Thin';
      }

      &[data-active='1'] {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

// Shimmer animation
.player.shimmer,
.shimmer {
  @include animation(shimmer 0.2s ease);
}

// USA map
.player.usa {
  position: relative;
  .USA {
    position: relative;
  }
  .USAScanner {
    position: absolute;
    top: 0;
    left: 0;
  }
}
