@import 'base';
@import 'animations';

.SlideList {
  display: grid;
  grid-template-areas:
    'header timeline close'
    'main main main';
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto 1fr;
  padding: 40px 60px;

  &__Header {
    grid-area: header;

    margin: 0;
    line-height: 40px;
    font-size: 55px;
    text-transform: uppercase;
  }

  &__ViewTimeline {
    grid-area: timeline;
  }

  &__Close {
    grid-area: close;

    cursor: pointer;
    margin-left: 30px;

    &:hover {
      svg {
        path {
          fill-opacity: 1;
        }
      }
    }
  }

  &__Main {
    grid-area: main;

    display: flex;
    flex-direction: row;
    margin-top: 60px;
  }

  &__Column {
    flex: 1;
    margin-right: 60px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    svg {
      position: absolute;
      top: 8px;
      left: -30px;
    }

    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: $blue;
      text-transform: uppercase;
      margin: 0 0 20px 0;
      padding: 0 0 20px 0;
      border-bottom: 2px solid $grey;
    }

    h3 {
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 14%;
      font-family: $futuraBold;
      color: white;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }

    h4 {
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      color: $blue;
      margin: 0 0 5px 0;
      padding: 0;
      text-transform: uppercase;
    }

    p {
      font-size: 16px;
      line-height: 19px;
      color: white;
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
      font-family: Blender Thin;
    }

    .SlideList__Slide {
      cursor: pointer;
      position: relative;
      padding: 10px;
      font-family: $futuraBold;
      font-weight: bold;

      > * {
        margin: 0px;
      }

      $delay: 50ms;
      opacity: 0;
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          animation: shimmerIn 0.2s ease, moveDown 0.2s ease;
          animation-delay: $i * $delay;
          animation-fill-mode: forwards;
        }
      }

      &:hover {
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }
  &__Row {
    position: relative;
    margin-bottom: 20px;
  }
}
