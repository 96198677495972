@import './base';

$size: 40px;
$shift: 10px;
$name: BackgroundGrid;

.BackgroundGrid {
  pointer-events: none;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;

  &--hidden {
    opacity: 0;
  }

  &__Gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba($grey-xdark, 0) 0%,
      rgba($grey-xdark, 1) 100%
    );
    z-index: 3;
  }

  .cell {
    z-index: $z-background-grid-cell;
    background: #1f2224;
    border: solid 1px rgba($white, 0.05);
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .over-cell {
    z-index: $z-background-grid-over-cell;
    background: #000;
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
  }

  @keyframes #{$name}-intro {
    0% {
      background: #000;
      border-color: #000;
    }
    50% {
      background: #444;
    }
    100% {
      background: #222;
      border-color: #444;
    }
  }

  @keyframes #{$name}-uncover {
    0% {
      background: #000;
      opacity: 1;
    }
    50% {
      background: #333;
      opacity: 1;
    }
    100% {
      background: #000;
      opacity: 0;
    }
  }

  .over-cell.intro {
    background: #000;
    animation: #{$name}-uncover ease-in 0.3s forwards;
  }

  @keyframes #{$name}-over {
    0% {
      background: #222;
    }
    100% {
      background: #444;
    }
  }

  .over {
    animation: #{$name}-over ease-in 0.3s forwards;
  }

  @keyframes #{$name}-out {
    100% {
      background: #222;
    }
    0% {
      background: #444;
    }
  }

  .out {
    animation: #{$name}-out ease-in 0.3s forwards;
  }

  @for $i from 0 to 1000 {
    .intro.r-#{$i} {
      animation-delay: #{($i * 2) + 100}ms;
    }

    // Diagonal
    // .intro.d-#{$i} {
    //   animation-delay: #{($i * 2) + 500}ms;
    // }

    // Columnar
    // .intro.x-#{$i} {
    //   animation-delay: #{($i * 35) + 500}ms;
    // }

    // Row
    // .intro.y-#{$i} {
    //   animation-delay: #{($i * 35) + 500}ms;
    // }
  }
}
