@import '../base';

.Player__ProgressBar {
  border: 1px solid rgba($white, 0.5);
  position: absolute;
  box-sizing: border-box;
  width: 440px;
  height: 50px;
  &__Percent {
    position: absolute;
    width: 100px;
    height: 40px;
    left: calc(50% - 50px);
    top: 5px;
    z-index: 3;
    font-size: 30px;
    line-height: 40px;
    font-family: $blenderBold;
    text-align: center;
    vertical-align: middle;
  }
  &__Background {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 430px;
    height: 40px;
    z-index: 1;
    background: linear-gradient(90deg, rgba($white, 0.2) 50%, transparent 50%);
    background-repeat: repeat-x;
    background-size: 5px 40px;
    background-position: 0 0;
    transition: opacity 0.5s ease;
  }
  &__Complete {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 430px;
    height: 40px;
    z-index: 2;
    background: linear-gradient(90deg, rgba($blue, 1) 50%, transparent 50%);
    background-repeat: repeat-x;
    background-size: 5px 40px;
    background-position: 0 0;
    transition: opacity 0.5s ease;
  }
}
