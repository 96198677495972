.RiskAnalysisImpact {
  svg:hover {
    cursor: pointer;

    g {
      opacity: 1;
    }
  }

  &__Row {
    margin-bottom: 30px;

    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    align-items: center;
    justify-items: left;
  }
}
