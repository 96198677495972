/*

MALICIOUS

*/
@import "../base";

//
// Players that often have the same starting position
//

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin DonutCenter {
  top: 25%;
  left: 50%;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

// Slide 0
$leftOffset: -25px;
.Overview {
  .player {
    &.card1 {
      top: 240px;
      left: 120px + $leftOffset;
    }
    &.card2 {
      top: 240px;
      left: 360px + $leftOffset;
    }
    &.card3 {
      top: 240px;
      left: 600px + $leftOffset;
    }
    &.card4 {
      top: 240px;
      left: 840px + $leftOffset;
    }
  }
}

// Slide 1
.Home {
  .player {
    &.terminal {
      @include CardCenter();
    }
    &.dots {
      @include DotsCenter();
    }
    &.amp {
      left: 504px;
      top: 336px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

// Slide 2
.Inspect {
  .player {
    &.donut {
      @include DonutCenter();
    }
    &.terminal {
      left: 496px;
      top: 320px;
    }
    &.amp {
      left: 80px;
      top: 200px;
    }
    &.whois {
      left: 80px;
      top: 400px;
    }
    &.geolocation {
      left: 800px;
      top: 200px;
    }
    &.usa {
      left: 820px;
      top: 320px;
    }
    &.alert {
      @include AlertCenter();
    }
    &.icon-skull {
      left: 250px;
      top: 290px;
    }
    &.icon-email {
      left: 250px;
      top: 450px;
    }
    &.icon-bug {
      left: 800px;
      top: 380px;
    }
  }
}

// Slide 3
.InspectAnalysis {
  .player {
    &.terminal {
      left: 496px;
      top: 320px;
    }
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.client {
      left: 400px;
      top: 240px;
    }
    &.tcp {
      left: 400px;
      top: 340px;
    }
    &.event {
      top: 240px;
      left: 670px;
    }
  }
}

// Slide 4
.InspectAnalysis2 {
  .player {
    &.terminal {
      left: 100px;
      top: 320px;
    }
    &.client {
      left: 400px;
      top: 240px;
    }
    &.policies {
      top: 240px;
      left: 670px;
    }
    &.filters {
      top: 400px;
      left: 670px;
    }
  }
}

// Slide 5
.slide-pcap {
  .player {
    &.cloudshark {
      left: 440px;
      top: 307px;
    }
    &.textLines {
      left: 492px;
      top: 400px;
    }
    &.data-left {
      position: absolute;
      width: calc(50% - 150px);
      top: 50%;
      left: 40px;
      height: 12px;
    }
    &.data-right {
      position: absolute;
      width: calc(50% - 150px);
      top: 50%;
      right: 40px;
      height: 12px;
      @include transition('left 1s ease, right 1s ease');
    }
  }
}

// Slide 6
.slide-pcap2 {
  .player {
    &.tcp {
      left: 460px;
      top: 300px;
    }
    &.file {
      left: 460px;
      top: 460px;
    }
    &.data-left {
      position: absolute;
      width: calc(50% - 150px);
      top: 50%;
      left: 40px;
      height: 12px;
    }
  }
}

// Slide 7
.slide-file-extraction {
  .player {
    &.page {
      left: 468px;
      top: 398px;
    }
    &.computer {
      left: 490px;
      top: 365px;
    }
  }
}

// Slide 8
.AnalyzeActivity {
  .player {
    &.page {
      left: 540px;
      top: 390px;
    }
    &.computer {
      left: 490px;
      top: 365px;
    }
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.analyzing {
      left: 640px;
      top: 360px;
    }
  }
}

// Slide 9
$col1: 140px;
$col2: 450px;
$col3: 760px;
$topOffset: 16px;
.ProcessLocation {
  .player {
    &.folder {
      left: $col1;
      top: 360px + $topOffset;
    }
    &.process1 {
      left: $col2;
      top: 180px + $topOffset;
    }
    &.process2 {
      left: $col2;
      top: 270px + $topOffset;
    }
    &.process3 {
      left: $col2;
      top: 360px + $topOffset;
    }
    &.process4 {
      left: $col2;
      top: 450px + $topOffset;
    }
    &.process5 {
      left: $col2;
      top: 540px + $topOffset;
    }
    &.subProcess3 {
      left: $col3;
      top: 180px + $topOffset;
    }
    &.subProcess4 {
      left: $col3;
      top: 270px + $topOffset;
    }
    &.subProcess5 {
      left: $col3;
      top: 360px + $topOffset;
    }
    &.process {
      left: 390px;
      top: 290px;
    }
    &.network {
      top: 290px;
      left: 680px;
    }
    &.info {
      top: 490px;
      left: 680px;
    }
  }
}

// Slide 10
.ProcessLocation2 {
  .player {
    &.explorer {
      left: 261px;
      top: 370px;
    }
    &.word {
      left: 391px;
      top: 370px;
    }
    &.cmd {
      left: 521px;
      top: 370px;
    }
    &.powershell {
      left: 651px;
      top: 370px;
    }
    &.exe {
      left: 781px;
      top: 370px;
    }
    &.process {
      top: 280px;
      left:  450px;
    }
    &.network {
      top: 280px;
      left: 680px;
    }
    &.activity {
      left: 680px;
      top: 480px;
    }
    &.alert {
      left: 384px;
      top: 260px;
    }
    &.hide-title {
      &:after {
        display: none !important;
      }
    }
    &.explorer, &.word, &.cmd, &.powershell {
      &:after {
        position: absolute;
        display: block;
        content: '';
        background: $blue;
        width: 40px;
        height: 2px;
        top: calc(50% - 1px);
        left: calc(100% + 10px);
      }
    }
  }
}

// Slide 11
.PayloadAnalysis {
  .player {
    &.donut {
      left: 300px;
      top: 230px;
    }
    &.page {
      left: 254px;
      top: 364px;
    }
    &.binary {
      left: 570px;
      top: 300px;
    }
    &.paths {
      left: 570px;
      top: 500px;
    }
    &.hosts {
      left: 840px;
      top: 300px;
    }
  }
}

// Slide 12
.ProcessLocation3 {
  .player {
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.binary {
      left:  500px;
      top: 360px;
    }
    &.alert {
      left: 384px;
      top: 260px;
    }
  }
}

// Slide 13
.PayloadActivity {
  .player {
    &.file {
      left: 600px;
      top: 340px;
    }
  }
}

// Slide 14
.SandboxFindings {
  .player {
    &.donut {
      @include DonutCenter();
    }
    &.page {
      left: 505px;
      top: 340px;
    }
    &.alert {
      @include AlertCenter();
    }
    &.status {
      top: 340px;
      left: 20px;
    }
    &.file {
      top: 340px;
      left: 820px;
    }
  }
}

// Slide 15
// Easy way to adjust slide's position
$topOffset: 40px;
.SandboxFindings2 {
  .player {
    &.page {
      left: 280px;
      top: 340px + $topOffset;
      transform: scale(1.5);
      .player--circleIcon-circle {
        border-width: 2px;
      }
    }
    &.file {
      top: 220px + $topOffset;
      left: 530px;
      .player--textBlock-dots {
        .player--textBlock-dots {
          width: 90px;
        }
      }
    }
    &.eye {
      top: 340px + $topOffset;
      left: 530px;
      .player--circleIcon-dots {
        width: 150px;
      }
    }
    &.document {
      top: 460px + $topOffset;
      left: 530px;
      .player--circleIcon-dots {
        width: 180px;
      }
    }
  }
}

.SandboxFindings3 {
  .player {
    &.terminal1 {
      @include CardCenter();
    }
    &.terminal2 {
      @include CardCenter();
    }
    &.dots {
      left: 500px;
      top: 395px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

// Slide 16
// Easy way to adjust slide's position
$topOffset: 40px;
$leftOffset: -60px;
.SandboxFindings4 {
  .player {
    &.page {
      left: 280px + $leftOffset;
      top: 340px + $topOffset;
      transform: scale(1.5);
      .player--circleIcon-circle {
        border-width: 2px;
      }
    }
    &.payload {
      top: 220px + $topOffset;
      left: 530px + $leftOffset;
      .player--textBlock-dots {
        .player--textBlock-dots {
          width: 90px;
        }
      }
    }
    &.filepath {
      top: 220px + $topOffset;
      left: 760px + $leftOffset;
    }
    &.hash {
      top: 320px + $topOffset;
      left: 760px + $leftOffset;
    }
    &.results {
      top: 480px + $topOffset;
      left: 760px + $leftOffset;
    }
  }
}

// Slide 17
$dots: 8;
.HostIsolation {
  .center-player {
    display: grid;
    grid-template-areas: "main";
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .player {
    &.computer {
      top: 378px;
      left: 525px;
    }
    &.dots {
      position: static !important;
      grid-area: main;
      .mask {
        position: relative;
        transform-origin: 50% 50%;
        border-radius: 50%;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        overflow: hidden;
        width: 600px;
        height: 600px;
        background: rgba($white, 0);
        border: 4px solid rgba($white, 0);
        @include transition('background 0.5s ease, border 0.5s ease');
        .dotContainer {
          position: absolute;
          width: 400px;
          height: 400px;
          top: 50%;
          left: 50%;
          transform-origin: 50% 50%;
          .player--dots {
            position: absolute;
          }
        }
        &.highlighted {
          background: rgba($white, 0.05);
          border: 4px solid rgba($white, 0.15);
        }
      }
      .player--dots {
        .player--dots-track {
          transform-origin: 0 0;
          left: 50px;
        }
        @for $i from 1 through $dots {
          &:nth-child(#{$i}) {
            $deg: #{$i * (360 / $dots)}deg;
            transform: rotate($deg);
          }
        }
      }
    }
  }
}

// Slide 18
.MaliciousIP {
  .center-player {
    display: grid;
    grid-template-areas: "main";
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .player {
    &.computer {
      top: 378px;
      left: 525px;
    }
    &.dots {
      position: static !important;
      grid-area: main;

      .circle {
        position: relative;
        transform-origin: 50% 50%;
        border-radius: 50%;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        overflow: hidden;
        width: 10px;
        height: 10px;
        background: rgba($white, 0.2);
        border: 4px solid rgba($white, 0.5);
        @include transition('background 0.5s ease, border 0.5s ease');
        &.highlighted {
          background: rgba($white, 0.05);
          border: 4px solid rgba($white, 0.15);
        }
      }

      .dotContainer {
        position: absolute;
        width: 400px;
        height: 400px;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        .player--dots {
          position: absolute;
        }
      }

      .player--dots {
        .player--dots-track {
          transform-origin: 0 0;
          left: 50px;
        }
        @for $i from 1 through $dots {
          &:nth-child(#{$i}) {
            $deg: #{$i * (360 / $dots)}deg;
            transform: rotate($deg);
          }
        }
      }
    }
  }
}

$leftOffset: -40px;
$topOffset: 0;
$trackWidth: 330px;
.MaliciousIP2 {
  .player {
    &.computer {
      left: 160px + $leftOffset;
      top: 300px + $topOffset;
    }
    &.dots {
      left: 370px + $leftOffset;
      top: 395px + $topOffset;
    }
    &.shield {
      left: 500px + $leftOffset;
      top: 330px + $topOffset;
    }
    &.badge {
      position: relative;
      .player--dots {
        position: absolute;
        right: 100%;
        top: 50%;
        width: $trackWidth;
        transform-origin: 100% 0;
        transition: opacity 0.5s ease;
        .player--dots-track {
          width: $trackWidth;
        }
      }
      &.dimmed {
        .player--dots {
          opacity: 0;
        }
      }
    }
    &.badge1 {
      left: 890px;
      top: 180px;
    }
    &.badge2 {
      left: 890px;
      top: 280px;
    }
    &.badge3 {
      left: 890px;
      top: 380px;
    }
    &.badge4 {
      left: 890px;
      top: 480px;
    }
    &.badge5 {
      left: 890px;
      top: 580px;
    }
  }
}

.ClientAlert, .DeepDive {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

$topOffset: -40px;
.EscalatingProcess {
  .player--textBlock {
    .player--textBlock-dots {
      .player--textBlock-dots {
        width: 60px;
      }
    }
  }
  .player {
    &.warning {
      left:  150px;
      top: 360px + $topOffset;
      transform: scale(1.5);
    }
    &.threat {
      left: 400px;
      top: 270px + $topOffset;
    }
    &.info {
      left: 400px;
      top: 390px + $topOffset;
    }
    &.client {
      left: 700px;
      top: 270px + $topOffset;
    }
  }
}

$leftOffset: 150px;
$topOffset: 0;
.EscalationFollowup {
  .player {
    &.analyst {
      left: (496px + -$leftOffset);
      top: 320px + $topOffset;
    }
    &.client {
      left: (496px + $leftOffset);
      top: 320px + $topOffset;
    }
    &.phone {
      left: 535px;
      top: 375px + $topOffset;
      z-index: 2;
    }
    &.dots {
      @include DotsCenter();
      z-index: 1;
    }
    &.check {
      left: 685px;
      top: 255px + $topOffset;
    }
  }
}

$topOffset: -20px;
.DeepDive2 {
  .player {
    &.title {
      width: 100%;
      top: 160px;
      text-align: center;
      h1 {
        font-size: 48px;
        font-family: Blender Thin;
      }
    }
    &.alert {
      left: 384px;
      top: 320px + $topOffset;
    }
    &.timeline {
      top: 320px + $topOffset;
      left: 240px;
    }
    &.domains {
      top: 320px + $topOffset;
      left: 640px;
    }
    &.hashes {
      top: 520px + $topOffset;
      left: 640px;
    }
  }
}

