@import '../../base';

.RiskAnalysisRates {
  display: grid;

  grid-template-areas: 'left right';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 30px;

  &.Content {
    padding: 20px;
  }

  &__Left {
    grid-area: left;

    h1 {
      color: $blue;
      font-family: $blenderThin;
      font-size: 34px;
      margin: 0;
      letter-spacing: -1px;

      @media (max-width: 1111px) {
        font-size: 28px;
      }
    }

    h2 {
      color: $blue;
      font-family: $futuraBold;
      font-size: 12px;
      text-transform: uppercase;
      margin: 0;

      @media (max-width: 1111px) {
        font-size: 10px;
      }
    }

    p {
      line-height: 20px;
      font-size: 15px;
      font-family: $blenderThin;
      margin: 20px 0;

      @media (max-width: 1111px) {
        line-height: 16px;
        font-size: 14px;
      }
    }

    table {
      margin: 10px 0 20px 0;
      padding: 0;
      width: 100%;

      tr {
        td,
        th {
          border-bottom: 1px solid rgba($white, 0.1);
          height: 40px;
          line-height: 40px;
          text-align: center;

          @media (max-width: 1111px) {
            height: 35px;
            line-height: 35px;
          }

          &:first-child {
            text-align: left;
          }
        }

        th {
          font-family: $futuraBold;
          font-size: 10px;
          text-transform: uppercase;
        }

        td {
          font-family: $blenderThin;
          font-size: 15px;
          color: rgba($white, 0.6);

          &:first-child {
            color: $white;
          }
        }
      }
    }
  }

  &__Right {
    grid-area: right;
  }

  &__Chart {
    margin-top: 60px;
  }
}
