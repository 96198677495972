.ProbabilityInstantChart {
  &__Tooltip {
    display: grid;
    background: #282d30;
    padding: 20px;
    grid-template-columns: min-content auto 1fr 1fr;
    grid-auto-flow: row;
    column-gap: 10px;
    align-items: center;
    // Set the top to rows to be small, and the rest to be 30px
    grid-template-rows: min-content min-content;
    grid-auto-rows: 30px;
    // ---

    .Tooltip__Header {
      color: white;
      font-size: 19px;
      grid-area: 1/1 / 1/5;
      padding-bottom: 10px;
    }

    .Tooltip__TH {
      font-size: 10px;
      text-transform: uppercase;
      color: #f5f5f5;
      padding-bottom: 5px;
      justify-self: right;

      &:first-of-type {
        grid-area: 2/1 / 2/3;
        justify-self: left;
      }
    }

    .Tooltip__Line {
      grid-column: 1 / 5;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .Tooltip__Dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    .Tooltip__NameCell {
      font-size: 15px;
      color: #ffffff;
    }

    .Tooltip__ValueCell {
      justify-self: right;
      color: rgba(255, 255, 255, 0.6);
    }

    .Tooltip__ConfidenceCell {
      justify-self: right;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
