@import '../../base';

.ComplianceAlignmentDetail {
  grid-area: main;

  margin-top: 50px;
  font-size: 14px;
  line-height: 14px;
  overflow: hidden;
  overflow-y: scroll;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  display: grid;
  grid-template:
    'header'
    'content';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;

  &__Header {
    grid-area: header;

    margin: 10px 60px 40px 60px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
  }

  &__Back {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba($white, 0.5);
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      color: $white;

      svg g {
        opacity: 1;
      }
    }

    svg {
      margin-right: 15px;
    }
  }

  &__Section {
    margin: 0 60px 0 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 40px;
    margin-bottom: 40px;

    &:last-child {
      border: none;
      padding-bottom: 0;
    }

    p {
      font-size: 14px;
      line-height: 160%;
      color: #9ea1aa;
    }
  }

  &__Functions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 30px;
  }

  &__FunctionItem {
    background: rgba(255, 255, 255, 0.02);
    border: 4px solid rgba(255, 255, 255, 0.1);
    height: 160px;
    display: grid;
    justify-items: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 18px;

    &--disabled {
      opacity: 0.1;

      svg,
      path {
        fill: white;
      }
    }
  }

  &__FunctionItemIcon {
    display: grid;
    grid-template-rows: auto auto;
    justify-items: center;
    align-items: center;
    grid-gap: 30px;
  }

  &__H1 {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--functions {
      color: $orange;
      .ComplianceAlignmentDetail__Count {
        border-color: $orange;
      }
    }

    &--products {
      color: $red;
      .ComplianceAlignmentDetail__Count {
        border-color: $red;
      }
    }

    &--coverages {
      color: $blue;
      .ComplianceAlignmentDetail__Count {
        border-color: $blue;
      }
    }
  }

  &__Count {
    height: 22px;
    min-width: 12px;
    border-radius: 13px;
    border: 2px solid $white;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    margin-left: 10px;
    padding: 0 5px;
  }

  &__H2 {
    font-size: 15px;
    text-transform: uppercase;
    color: #e6e8e6;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--coverages {
      .ComplianceAlignmentDetail__Count {
        color: $blue;
        border-color: $blue;
      }
    }
  }

  &__CoverageItem {
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: flex-start;
    justify-items: left;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .ComplianceAlignmentDetail__H2 {
      margin: 0;
      padding: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}
