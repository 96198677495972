@import '../base';

//
// Timeline
//
.Timeline {
  z-index: 999;
  position: relative;
  .Timeline__Scrolling {
    position: absolute;
    width: 580px;
    height: 100%;
    left: calc(50% - 40px);
    overflow: hidden;
  }
  .player {
    &.timeline {
      position: absolute;
      width: 500px;
      height: 100%;
      left: 40px;
      box-sizing: border-box;
      .timeline-series {
        position: absolute;
        display: grid;
        align-items: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        .timeline-series-bubbles {
          display: grid;
          grid-gap: 10px;
          margin-top: 20px;
          .timeline-series-bubble {
            display: block;
            position: relative;
            height: 20px;
            &:hover,
            &.active {
              a {
                background: $blue;
              }
            }
            a {
              display: block;
              position: absolute;
              height: 100%;
              background: #58595b;
              transition: all 0.2s ease;
              top: 0;
              border-radius: 2px;
              cursor: pointer;
            }
          }
        }
      }
      .timeline-columns {
        height: 100%;
      }
    }
    // Timeline events
    &.events {
      position: absolute;
      width: 400px;
      height: 100%;
      left: 60px;
      box-sizing: border-box;
      z-index: 2;
      // Set to hidden so scrollbar can be hidden
      overflow: hidden;
      h1 {
        position: absolute;
        top: 80px;
        left: 0;
      }
      .Timeline__Events {
        overflow-y: auto;
        // This hides the scrollbar
        right: -20px;
        padding-right: 20px;
        // end
        position: absolute;
        height: 580px;
        width: 100%;
        top: 140px;
        box-sizing: border-box;
        -webkit-mask-image: linear-gradient(
          to top,
          rgba($white, 0) 0%,
          rgba($white, 0) 0%,
          rgba($white, 1) 20%
        );
        &__Scroller {
          padding-bottom: 100px;
        }
        .Event {
          display: grid;
          grid-template-columns: 80px 1fr;
          padding: 10px;
          border-radius: 10px;
          margin-bottom: 10px;
          background: rgba($white, 0);
          box-sizing: border-box;
          opacity: 0.5;
          transition: all 0.2s ease;
          position: relative;
          cursor: pointer;
          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            background: rgba($white, 0.2);
          }
          &:before {
            height: 17px;
            left: 89px;
            top: 0px;
          }
          &:after {
            height: calc(100% - 14px);
            left: 89px;
            top: 24px;
          }
          &:first-child {
            &:before {
              opacity: 0 !important;
            }
          }
          &:last-child {
            &:after {
              opacity: 0 !important;
            }
          }
          &.active {
            background: rgba($white, 0.1);
            opacity: 1 !important;
            .Event__Time {
              color: $blue;
              &:after {
                background: $blue;
              }
            }
            .Event__Description {
            }
            &:before,
            &:after {
              background: rgba($white, 0.1);
            }
          }
          &:hover {
            background: rgba($white, 0.1);
            opacity: 0.5;
            .Event__Time {
              color: $blue;
              &:after {
                background: $blue;
              }
            }
            .Event__Description {
            }
            &:before,
            &:after {
              background: rgba($white, 0.1);
            }
          }
          &__Time {
            color: $white;
            display: block;
            position: relative;
            font-family: $futuraBold;
            text-align: right;
            padding: 0 12px 0 0;
            box-sizing: border-box;
            transition: all 0.2s ease;
            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background: rgba($white, 0.2);
              top: 7px;
              right: -3px;
              transition: all 0.2s ease;
            }
          }
          &__Description {
            color: $white;
            font-size: 19px;
            line-height: 100%;
            display: block;
            text-align: left;
            box-sizing: border-box;
            font-family: $blenderThin;
            padding: 0 0 0 12px;
          }
        }
      }
    }
  }

  .timeline-points {
    z-index: 1;
    position: absolute;
    top: 150px;
    width: 100%;
    height: 540px;

    .timeline-points-point {
      position: absolute;
      top: 0;
      width: 1px;
      background: rgba($white, 0.1);
      height: 100%;
      opacity: 1;
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: -10px;
        left: -1px;
        width: 3px;
        height: 3px;
        background: $white;
      }
      span {
        display: block;
        position: absolute;
        width: 60px;
        height: 30px;
        left: -30px;
        top: -45px;
        font-size: 9px;
        color: $white;
        text-transform: uppercase;
        text-align: center;
        font-family: Blender Thin;
        letter-spacing: 1px;
        b {
          display: block;
          margin-bottom: 3px;
          font-size: 11px;
          letter-spacing: -1px;
        }
      }
    }
  }
}
