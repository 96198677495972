@import '../components/base';

.Template__TeamsScanWorld {
  .player {
    &.team1 {
      left: 149px;
      top: 268px;
    }
    &.team2 {
      left: 149px;
      top: 437px;
    }
    &.product {
      left: 443px;
      top: 357px;
      label {
        display: block;
        width: 100%;
        text-align: center;
        position: absolute;
        top: -30px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 14px;
        color: rgba($white, 0.5);
        white-space: nowrap;
      }
    }
    &.path1 {
      left: 253px;
      top: 327px;
    }
    &.path2 {
      left: 253px;
      top: 422px;
    }
    &.path3 {
      left: 563px;
      top: 405px;
    }
    &.path4 {
      left: 563px;
      top: 415px;
    }
    &.world {
      width: 350px;
      height: 350px;
      left: 614px;
      top: 242px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid rgba($white, 0.2);
      background: radial-gradient(
        60% 70% at 45% 40%,
        rgba($white, 0) 50%,
        rgba($white, 0.05) 100%
      );
      svg {
        position: absolute;
        left: -50px;
        top: 20px;
      }
    }
  }
}
