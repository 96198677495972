@import '../base';

@keyframes pulseBlue {
  0% {
    stroke: rgba($blue, 0.2);
  }
  25% {
    stroke: rgba($blue, 1);
  }
  100% {
    stroke: rgba($blue, 0.2);
  }
}

// Shield
.Icon__Shield {
  .Icon__Shield__Border {
    transition: all 1s ease;
  }
  &.red {
    .Icon__Shield__Border {
      stroke: rgba($red, 1);
      stroke-opacity: 1;
      fill: rgba($red, 0.5);
    }
  }
  &.blue {
    .Icon__Shield__Border {
      stroke: rgba($blue, 1);
      stroke-opacity: 1;
    }
  }
  &.pulse-blue {
    .Icon__Shield__Border {
      stroke-opacity: 1;
      transition: none !important;
      animation: pulseBlue 1s ease infinite;
    }
  }
}

// Blue border
.blue .Icon__Shield .Icon__Shield__Border {
  stroke: rgba($blue, 1);
  stroke-opacity: 1;
}

// Red border
.red .Icon__Shield .Icon__Shield__Border {
  stroke: rgba($red, 1);
  stroke-opacity: 1;
  fill: rgba($red, 0.5);
}