@import 'base';

@font-face {
  font-family: 'Blender Thin';
  src: url(../assets/fonts/blenderpro-thin-webfont.woff2) format('woff2');
}
@font-face {
  font-family: 'Blender Medium';
  src: url(../assets/fonts/blenderpro-medium-webfont.woff2) format('woff2');
}
@font-face {
  font-family: 'Blender Heavy';
  src: url(../assets/fonts/blenderpro-heavy-webfont.woff2) format('woff2');
}
@font-face {
  font-family: 'Blender Bold';
  src: url(../assets/fonts/blenderpro-bold-webfont.woff2) format('woff2');
}
@font-face {
  font-family: 'Blender Book';
  src: url(../assets/fonts/blenderpro-book-webfont.woff2) format('woff2');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  src: url(../assets/fonts/IBMPlexSans-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 200;
  src: url(../assets/fonts/IBMPlexSans-ExtraLight.woff2) format('woff2');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 300;
  src: url(../assets/fonts/IBMPlexSans-Light.woff2) format('woff2');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  src: url(../assets/fonts/IBMPlexSans-Medium.woff2) format('woff2');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  src: url(../assets/fonts/IBMPlexSans-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  src: url(../assets/fonts/IBMPlexSans-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 200;
  src: url(../assets/fonts/IBMPlexSans-Text.woff2) format('woff2');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 100;
  src: url(../assets/fonts/IBMPlexSans-Thin.woff2) format('woff2');
}

html {
  height: 100%;
  @include no-select;
}

html,
body,
#root,
.App {
  background: $grey-xdark;
  color: #fafafa;
  margin: 0px;
  padding: 0px;
  font-family: 'Blender Book';
  font-weight: bold;
  font-size: 14px;

  // Allows for easier modaling
  display: grid;
  grid-template: 'body' 1fr / 1fr;
  grid-area: body;

  position: relative;
}

.App {
  display: grid;
  grid-template:
    'main' 1fr
    'bottom' auto /
    1fr;
  background-color: $grey-xdark;

  .BackgroundGrid {
    grid-area: main / body;
  }

  .TopMenu {
    grid-area: main;
  }
  .BottomNav {
    grid-area: bottom;
  }
  > * {
    grid-area: main;
  }

  .Content {
    z-index: $z-router-content;
  }
}
