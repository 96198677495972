@import '../base';

// Turn debug on to help debug the grid
$debug: false;
$colors: #f0f2, #0f02, #0ff2, #f002, #ff02, #08f2;

@function color($number: 1) {
  @return if(
    $debug,
    nth($colors, ($number % length($colors)) + 1),
    transparent
  );
}

.SOCCalculator {
  display: grid;
  grid-template-areas:
    'MainMenu Title    Logo'
    'MainMenu Subtitle Logo'
    'LeftNav  Middle   Middle';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 35px 35px 1fr;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;

  h2 {
    font-family: $blenderThin;
    line-height: 19px;
    font-size: 20px;
  }

  .capitalize {
    text-transform: capitalize;
  }

  &__MainMenu {
    background: $black;
    width: 150px;
    display: grid;
    align-content: center;
    padding-left: 30px;

    grid-area: MainMenu;
    z-index: $z-chrome-menu-button;

    .HamburgerButton {
      width: 25px;
      height: 25px;
      background: transparent;
      display: block;
      border: 0 none;
      outline: none;
      cursor: pointer;
      padding: 0;

      &:hover {
        svg {
          rect {
            fill-opacity: 1;
          }
        }
      }
    }
  }

  &__Logo {
    background: color(2);

    grid-area: Logo;
    width: 100px;
    display: grid;
    align-content: center;
    padding-right: 30px;
  }

  &__Title {
    background: color(3);
    padding: 20px 20px 0px 20px;

    grid-area: Title;

    font-family: $futuraBold;
    font-weight: 700;
    line-height: 20px;
    font-size: 10px;
    text-align: left;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $blue;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: flex;
  }

  &__Subtitle {
    background: color(4);
    padding: 0 20px 0px 20px;

    grid-area: Subtitle;

    font-family: Blender Book;
    font-weight: 300;
    line-height: 36px;
    font-size: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $white;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: flex;
  }

  &__LeftNav {
    grid-area: LeftNav;
  }

  &__Middle {
    background: color(11);
    grid-area: Middle;
    overflow: hidden;
    display: grid;
    grid-template:
      'tabs'
      'content';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    min-width: 800px;
  }

  &__Tabs {
    grid-area: tabs;
    height: 30px;
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 2px solid $blue;
    margin: 10px 30px 0 20px;
  }

  &__Tab {
    font-family: $blenderBold;
    color: $blue;
    padding: 0 20px;
    font-size: 14px;
    text-transform: uppercase;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba($blue, 0.1);
    }

    &:active,
    &--active {
      background-color: $blue;
      color: $black;

      &:hover {
        background-color: $blue;
      }
    }
  }

  &__Content {
    grid-area: content;
    overflow: hidden;
    display: grid;
    grid-template: 'main' 1fr/1fr;
  }
}

@media (max-width: 1000px) {
  .SOCCalculator {
    &__MainMenu {
      width: 115px;
    }
  }
}

.FadeIn {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.SOCCalculatorSummary,
.SOCCalculatorDetail {
  padding: 0 30px 30px 20px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 29px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  grid-area: main;
  display: grid;
  // prettier-ignore
  grid-template:
    'tools   ' 6fr
    'ops     ' 1fr
    'person  ' 6fr
    'exp     ' 3fr
    'house   ' auto
    'esentire' auto /
     1fr;

  &__Section {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr 1fr 256px;
    border-bottom: 2px solid $blue;

    &:last-child {
      border-bottom: none;
    }
  }

  &__SectionColumn {
    position: relative;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-left: 1px solid rgba(255, 255, 255, 0);

    &:first-child,
    &:nth-child(2) {
      border-left: none;
    }

    &:not(:first-child) {
      .SOCCalculatorSummary__SectionCell,
      .SOCCalculatorDetail__SectionCell {
        justify-items: right;
        text-align: right;
      }
    }

    &:last-child {
      background-color: #000000;
      border-left: 2px solid $blue;
    }

    &--white,
    &--white:last-child {
      background-color: $white;
      border-left: none;
      padding-top: 10px;

      .KeyValueSet__Key,
      .KeyValueSet__Value {
        color: $black;
      }
    }

    &--blue,
    &--blue:last-child {
      background-color: $blue;
      border-left: none;
      color: $black;
      padding-top: 10px;

      .KeyValueSet__Key,
      .KeyValueSet__Value {
        color: $black;
      }
    }
  }

  &__SectionCell {
    display: grid;
    padding: 0 10px 0 0;
    align-items: center;
    justify-items: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0);

    &:last-child {
      border-bottom: none;
    }
  }

  &__SecurityTools {
    grid-area: tools;
  }

  &__SecurityOperations {
    grid-area: ops;
  }

  &__Personnel {
    grid-area: person;
  }

  &__OperationalExpenses {
    grid-area: exp;
  }

  &__InHouseCost {
    grid-area: house;

    border-top: 3px solid $blue;
    border-bottom: 2px solid #000000;
  }

  &__EsentireCost {
    grid-area: esentire;
  }

  &__SectionHeader {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    font-family: $blenderBold;
    color: $blue;

    & > * {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 10px;
      align-items: center;
    }

    .PopupContainer {
      width: 18px;
      height: 18px;
      margin-top: -5px;

      .Popup__QuoteMark,
      .Popup__Middle {
        background-color: #000000;
      }

      .Popup--top-left {
        top: 20px;
      }
    }

    svg {
      cursor: pointer;
      width: 18px;
      height: 18px;

      &:hover {
        g {
          opacity: 1;
        }

        path {
          fill: $white;
        }

        circle {
          stroke: $white;
        }
      }
    }

    p {
      color: $white;
      margin: 15px 0 0 0;
      padding: 0;
    }
  }

  &__TotalText {
    font-size: 24px;
    line-height: 24px;
    color: $blue;
  }

  &__LargeText {
    font-size: 20px;
    font-weight: bold;
    font-family: $blenderBold;
    color: $blue;
  }

  &__WhiteText {
    color: $white;
  }
}

@media (max-height: 900px) {
  .SOCCalculatorSummary,
  .SOCCalculatorDetail {
    font-size: 14px;
    line-height: 22px;

    &__SectionColumn {
      &--white,
      &--white:last-child,
      &--blue,
      &--blue:last-child {
        padding-top: 5px;
      }
    }

    &__SectionCell {
      padding: 0 5px 0 0;
    }

    &__SectionHeader {
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: 14px;

      .PopupContainer,
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

@media (max-height: 750px) {
  .SOCCalculatorSummary,
  .SOCCalculatorDetail {
    font-size: 11px;
    line-height: 18px;

    &__SectionHeader {
      font-size: 11px;

      .PopupContainer,
      svg {
        width: 12px;
        height: 12px;
      }
    }

    &__TotalText {
      font-size: 20x;
      line-height: 20px;
    }

    &__LargeText {
      font-size: 16px;
    }

    .KeyValueSet {
      &__Key {
        font-size: 12px;
        line-height: 12px;
      }

      &__Value {
        font-size: 20px;
        line-height: 20px;
        height: 20px;
      }

      input {
        font-size: 20px;
        line-height: 20px;
        height: 20px;
      }
    }
  }
}
