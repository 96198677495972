@import '../base';

@include keyframes(animatedDotsForwards) {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 360;
  }
}

@include keyframes(animatedDotsBackwards) {
  0% {
    stroke-dashoffset: 360;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.Player__Path {
  svg {
    overflow: visible;
  }
}
