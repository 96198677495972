@import '../base';

@keyframes waveGrow {
  0% {
    transform: scale(0);
    opacity: 0;
    border-color: $blue;
  }
  75% {
    opacity: 0.8;
    border-color: $blue;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}

$size: 300px;
.Player__Waves {
  width: $size / 2;
  height: $size;
  overflow: hidden;
  position: relative;
  span {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform-origin: center center;
    top: calc(50% - 20px);
    left: -20px;
    border: 3px solid $blue;
  }
  &.full-waves {
    width: $size;
    span {
      left: calc(50% - 20px);
    }
  }
}
