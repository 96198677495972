@import '../base';

.Player__LoadingMessage {
  &.first {
    .Player__LoadingMessage__Message {
      &:first-child {
        opacity: 1;
      }
      &:last-child {
        opacity: 0;
      }
    }
  }
  &.second {
    .Player__LoadingMessage__Message {
      &:first-child {
        opacity: 0;
      }
      &:last-child {
        opacity: 1;
      }
    }
  }
  &__Message {
    position: absolute;
    width: 100%;
    transition: all 0.5s ease;
    border: 5px solid transparent;
    background: rgba($white, 0.1);
    box-sizing: border-box;
    padding: 20px 20px 20px 50px;
    line-height: 20px;
    text-transform: uppercase;
    svg {
      position: absolute;
      left: 15px;
      top: 18px;
    }
    &:last-child {
      border: 4px solid $blue;
    }
  }
}
