@import 'base';

.CurrencyCell {
  display: grid;
  grid-template: 'sign value';
  grid-template-columns: 1fr auto;
  width: 100%;

  &__Sign {
    text-align: left;
    padding-left: 15px;
    color: $blue;
  }
}
