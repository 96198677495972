@import "../base";

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin DonutCenter {
  top: 25%;
  left: 50%;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

.Slide.esinsider {
  // Slide 0
  $leftOffset: -25px;

  .slide-01 {
    .player {
      &.card1 {
        top: centerTop(320px);
        left: 120px + $leftOffset;
      }
      &.card2 {
        top: centerTop(320px);
        left: 360px + $leftOffset;
      }
      &.card3 {
        top: centerTop(320px);
        left: 600px + $leftOffset;
      }
      &.card4 {
        top: centerTop(320px);
        left: 840px + $leftOffset;
      }
    }
  }

  .slide-02 {
    .player {
      &.card1 {
        top: centerTop(159px);
        left: calc(50% - 212px);
      }
      &.card2 {
        top: centerTop(159px);
        left: calc(50% - 60px);
      }
      &.card3 {
        top: centerTop(159px);
        left: calc(50% + 92px);
      }
    }
  }

  .slide-03 {
    .player {
      &.computer {
        left: calc(50% - 60px);
        top: 320px;
      }

      &.computerGroup {
        left: calc(50% + 170px);
        top: 300px;
      }

      &.dots-left {
        left: centerLeft(200px) - 40px;
        top: centerTop(4px);

        .player--dots-track {
          width: 44px;

          span {
            width: 2px;
            height: 3px;
            border-radius: 0px;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      &.dots-right {
        left: centerLeft(200px) + 100px;
        top: centerTop(4px);

        .player--dots-track {
          width: 44px;

          span {
            width: 2px;
            height: 3px;
            border-radius: 0px;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      &.recon {
        left: calc(50% - 81px);
        top: 370px;
      }

      &.donut {
        opacity: 0;
        top: 215px;
        left: calc(50% + 270px);
        transform: scale(0.96);
      }
    }
  }

  .slide-04 .content {
    .player {
      &.computer {
        left: calc(50% - 310px);
        top: 320px;
      }

      &.computerGroup {
        left: calc(50% + 170px);
        top: 300px;
      }

      &.dots-left {
        left: centerLeft(200px) - 88px;
        top: centerTop(4px);

        .player--dots-track {
          width: 100px;

          span {
            background: $red;
          }
        }
      }

      &.dots-right-1 {
        left: centerLeft(200px) + 91px;
        top: centerTop(4px) + 3px;
        transform: rotate(-45deg);

        .player--dots-track {
          span {
            background: $red;
          }
        }
      }

      &.dots-right-2 {
        left: centerLeft(200px) + 95px;
        top: centerTop(4px) + 0px;
        transform: rotate(45deg);

        .player--dots-track {
          span {
            background: $red;
          }
        }
      }

      &.recon {
        left: calc(50% - 81px);
        top: 370px;
      }

      &.download {
        left: calc(50% - 81px);
        top: 370px;
      }

      &.db1 {
        top: 320px;
        left: calc(50% + 210px);
        opacity: 0;
      }

      &.db2 {
        top: 320px;
        left: calc(50% + 210px);
        opacity: 0;
      }
    }
  }

  .slide-05 .content {
    .player {
      &.computer {
        left: calc(50% - 310px);
        top: 320px;
      }

      &.laptop {
        opacity: 0;
        left: calc(50% - 310px);
        top: 320px;
      }

      &.repo {
        top: centerTop(159px);
        left: calc(50% + 210px);
      }

      &.dots-left {
        left: centerLeft(200px) - 88px;
        top: centerTop(4px);

        .player--dots-track {
          width: 150px;

          span {
            background: $red;
          }
        }
      }

      &.dots-right {
        left: centerLeft(150px) + 115px;
        top: centerTop(4px) + 3px;

        .player--dots-track {
          width: 150px;

          span {
            background: $red;
          }
        }
      }

      &.download {
        left: centerLeft(70px);
        top: centerTop(70px);
      }
    }
  }

  .slide-06 .content {
    .player {
      &.laptop {
        left: calc(50% - 310px);
        top: 320px;
      }

      &.computer {
        top: centerTop(159px);
        left: calc(50% + 210px);
      }

      &.dots-left {
        left: centerLeft(200px) - 88px;
        top: centerTop(4px);

        .player--dots-track {
          width: 150px;

          span {
            background: $red;
          }
        }
      }

      &.dots-right {
        left: centerLeft(150px) + 115px;
        top: centerTop(4px) + 3px;

        .player--dots-track {
          width: 150px;

          span {
            background: $red;
          }
        }
      }

      &.transfer {
        left: centerLeft(70px);
        top: centerTop(70px);
      }
    }
  }

  .slide-07 .content {
    .player {
      &.computer {
        top: centerTop(159px);
        left: calc(50% + 210px);
      }

      &.dots-left {
        left: centerLeft(200px) - 88px;
        top: centerTop(4px);

        .player--dots-track {
          width: 150px;

          span {
            background: $red;
          }
        }
      }

      &.dots-right-1 {
        left: centerLeft(150px) + 115px;
        top: centerTop(4px) + 3px;

        .player--dots-track {
          width: 110px;

          span {
            background: $red;
          }
        }
      }

      &.dots-right-2 {
        left: centerLeft(150px) + 115px;
        top: centerTop(4px) + 3px;
        transform: rotate(45deg);

        .player--dots-track {
          width: 150px;

          span {
            background: $red;
          }
        }
      }

      &.dots-right-3 {
        left: centerLeft(150px) + 115px;
        top: centerTop(4px) + 3px;
        transform: rotate(-45deg);

        .player--dots-track {
          width: 150px;

          span {
            background: $red;
          }
        }
      }

      &.upload {
        left: centerLeft(70px);
        top: centerTop(70px);
      }

      &.drive {
        left: centerLeft(70px) + 200px;
        top: centerTop(70px);
      }

      &.dropbox {
        left: centerLeft(70px) + 200px;
        top: centerTop(70px) - 110px;
      }

      &.gmail {
        left: centerLeft(70px) + 200px;
        top: centerTop(70px) + 110px;
      }
    }
  }

  .slide-08 .content {
    .player {
      &.page {
        opacity: 0;
        top: centerTop(540px) + 50px;
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.arrow {
        opacity: 0;
        top: centerTop(570px) - 10px;
        left: centerLeft(22px) + 386px;
        transform: rotate(90deg);
      }

      &.label {
        opacity: 0;
        top: centerTop(570px) - 50px;
        left: centerLeft(22px) + 330px;
      }
    }

    .labelbox {
      box-sizing: border-box;
      padding: 10px;
      width: 120px;
      height: 35px;
      background: #f36c21;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 144%;
      text-align: center;
    }
  }

  .slide-09 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-10 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-11 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-12 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-13 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-14 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-15 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-16 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-17 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-18 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-19 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-20 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-21 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-22 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-23 .content {
    .player {
      &.page {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }

      &.page2 {
        opacity: 0;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  //
  // Slide 16 - Isolate Host
  //
  $dots: 8;
  .slide-24 .content {
    .center-player {
      display: grid;
      grid-template-areas: "main";
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .player {
      &.computer {
        top: 378px;
        left: 525px;
      }
      &.dots {
        position: static !important;
        grid-area: main;
        .mask {
          position: relative;
          transform-origin: 50% 50%;
          border-radius: 50%;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          overflow: hidden;
          width: 600px;
          height: 600px;
          background: rgba($white, 0);
          border: 4px solid rgba($white, 0);
          @include transition("background 0.5s ease, border 0.5s ease");
          .dotContainer {
            position: absolute;
            width: 400px;
            height: 400px;
            top: 50%;
            left: 50%;
            transform-origin: 50% 50%;
            .player--dots {
              position: absolute;
            }
          }
          &.highlighted {
            background: rgba($white, 0.05);
            border: 4px solid rgba($white, 0.15);
          }
        }
        .player--dots {
          .player--dots-track {
            transform-origin: 0 0;
            left: 50px;
          }
          @for $i from 1 through $dots {
            &:nth-child(#{$i}) {
              $deg: #{$i * (360 / $dots)}deg;
              transform: rotate($deg);
            }
          }
        }
      }
    }
  }

  .slide-25 .content {
    .player {
      &.client {
        @include CardCenter();
      }
      &.email {
        top: 380px;
        left: 450px;
      }
      &.alert {
        opacity: 1;
        top: centerTop(540px);
        left: centerLeft(984px);
      }
    }
  }

  .slide-26 .content {
    .player {
      &.client {
        @include CardCenter();
      }
      &.alert {
        @include AlertCenter();
      }
    }
  }

  .slide-27 .content {
    .player {
      &.code {
        left: 540px;
        top: 390px;
      }
      &.donut {
        top: 25%;
        left: 50%;
      }
      &.analyzing {
        left: 640px;
        top: 360px;
      }
    }
  }

  .slide-28 .content {
    .player {
      &.alert {
        @include AlertCenter();
      }
    }
  }
}
