@import 'base';

.UseCasePicker {
  display: grid;
  grid-template-areas:
    'logo'
    'use-cases';
  grid-template-rows: 90px 1fr;
  position: relative;

  &__Logo {
    align-self: center;
    justify-self: center;
    padding: 30px;
  }

  &__BackButton {
    position: absolute;
    left: 30px;
    top: 30px;
    height: 40px;
    width: 40px;
    transform: rotate(180deg);
    background: transparent;
    border: 0 none;
    outline: none;
    cursor: pointer;

    &:hover {
      svg {
        g {
          opacity: 1;
        }
      }
    }
  }

  &__UseCases {
    grid-area: use-cases;
    max-height: 100%;
    position: relative;
    overflow: hidden;

    &__Scroller {
      display: grid;
      grid-gap: 10px;
      position: absolute;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      // Scrolling
      overflow-y: scroll;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__UseCase {
    background: rgba($white, 0.02);
    border: 4px solid rgba($white, 0.1);
    cursor: pointer;
    padding: 20px;
    margin: 0 90px;

    display: grid;
    align-items: center;
    justify-items: left;

    opacity: 0;
    $delay: 100ms;
    $startDelay: 250ms;
    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        animation: shimmerIn 0.2s ease;
        animation-delay: $i * $delay + $startDelay;
        animation-fill-mode: forwards;
      }
    }

    &:active {
      border-color: inherit;
    }
    &:hover {
      border-color: $blue;
      .UseCasePicker__ArrowButton {
        border-color: rgba($white, 0.5);
      }
    }

    &--disabled {
      cursor: default;
      border: 4px solid rgba($white, 0.04);
      background: rgba($white, 0.01);
      pointer-events: none;

      & * {
        opacity: 0.4;
      }

      &:hover {
        border-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__UseCaseContent {
    display: grid;
    grid-template-columns: 40px 1fr auto 40px;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    gap: 20px;
    width: 100%;

    hr {
      width: 75px;
      height: 2px;
      background: rgba(255, 255, 255, 0.3);
      border: 0;
      margin-bottom: 20px;
    }
  }

  &__Icon {
    * {
      width: 45px;
    }
  }

  &__ArrowButton {
    border: 2px solid rgba($white, 0.2);
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  &__Title {
    font-family: $blenderThin;
    line-height: 40px;
    font-size: 42px;
    text-align: left;
    letter-spacing: -0.04em;
    padding: 0 20px;
    margin: 0;
    width: 100%;

    color: #ffffff;
  }

  &__Product {
    font-family: $futuraBook;
    line-height: 28px;
    font-size: 14px;
    text-align: center;

    color: $blue;

    &__Text {
      color: rgba($white, 0.5);
      margin-right: 10px;
    }

    &__Label {
      display: inline-block;
      margin: 0 20px 0 0;
      position: relative;
      &:after {
        content: '/';
        display: block;
        position: absolute;
        text-align: center;
        top: 0px;
        color: rgba($white, 0.2);
        left: 100%;
        width: 20px;
      }
      &:last-child {
        &:after {
          display: none !important;
        }
      }
    }
  }
}

@media (max-height: 750px) {
  .UseCasePicker {
    &__Title {
      font-size: 26px;
      margin: 10px 0;
    }
  }
}
