/*

RANSOMWARE

*/
@import "../base";

//
// Players that often have the same starting position
//

$dots: 6;
$duration: 3s;
$size: 6px;
$delay: $duration / $dots;

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin DonutCenter {
  top: 25%;
  left: 50%;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

$leftStart: 40px;
$rightStart: 590px;
$width: 480px;
$antsStart: -35px;
.waffle {
  // Slide 0 - Narrative
  .slide-01 {
    // Information
    .player {
      .StoryBlock__Title {
        margin: 0 0 20px 0;
      }
      &.block1 {
        width: $width;
        left: $leftStart;
        top: 140px;
      }
      &.block2 {
        width: $width;
        left: $leftStart;
        top: 346px;
      }
      &.block3 {
        width: $width;
        left: $leftStart;
        top: 532px;
      }
      &.block4 {
        width: $width;
        left: $rightStart;
        top: 140px;
      }
      &.block5 {
        width: $width;
        left: $rightStart;
        top: 306px;
      }
      &.block6 {
        width: $width;
        left: $rightStart;
        top: 512px;
      }
      &.lines {
        left: 520px;
        top: 170px;
      }
    }
  }

  // Slide 2 - Cards
  $leftOffset: 0px;
  $topOffset: -40px;
  .slide-02 {
    .player {
      &.card1 {
        top: 180px + $topOffset;
        left: 98px + $leftOffset;
      }
      &.card2 {
        top: 180px + $topOffset;
        left: 416px + $leftOffset;
      }
      &.card3 {
        top: 180px + $topOffset;
        left: 735px + $leftOffset;
      }
      &.card4 {
        top: 482px + $topOffset;
        left: 257px + $leftOffset;
      }
      &.card5 {
        top: 482px + $topOffset;
        left: 575px + $leftOffset;
      }
    }
  }

  .slide-03 {
    .player {
      &.network {
        left: 97px;
        top: 315px;
      }
      &.path1 {
        left: 277px;
        top: 365px;
        svg {
          &:nth-child(1) {
            top: 0px;
          }
          &:nth-child(2) {
            top: 10px;
          }
          &:nth-child(3) {
            top: 30px;
          }
          &:nth-child(4) {
            top: 40px;
          }
          &:nth-child(5) {
            top: 50px;
          }
          &:nth-child(6) {
            top: 60px;
          }
          &:nth-child(7) {
            top: 70px;
          }
        }
      }
      &.signals {
        left: 327px;
        top: 263px;
      }
      &.path2 {
        left: 630px;
        top: 412px;
      }
      &.shield {
        left: 677px;
        top: 348px;
      }
      &.path3 {
        left: 782px;
        top: 412px;
      }
      &.reveal {
        left: 830px;
        top: 330px;
      }
    }
  }

  .slide-04 {
    .player {
      &.card1 {
        left: 200px;
        top: 210px;
      }
      &.card2 {
        left: 200px;
        top: 380px;
      }
      &.path1 {
        left: 320px;
        top: 290px;
      }
      &.path2 {
        left: 320px;
        top: 400px;
      }
      &.path3 {
        left: 670px;
        top: 210px;
      }
      &.path4 {
        left: 670px;
        top: 410px;
      }
      &.scanner {
        left: 440px;
        top: 260px;
        border: 4px solid $blue;
        width: 220px;
        height: 220px;
        .Player__DataScan {
          left: 20px;
          top: 20px;
          overflow: hidden;
        }
        .scannerTitle {
          position: absolute;
          text-align: center;
          width: 100%;
          height: 30px;
          padding: 0px;
          line-height: 30px;
          &.top {
            bottom: 100%;
            .titleBorder {
              border-width: 4px 4px 0px 4px;
            }
          }
          &.bottom {
            top: 100%;
            .titleBorder {
              border-width: 0px 4px 4px 4px;
            }
          }
          .titleBorder {
            border-color: $blue;
            border-style: solid;
            padding: 5px 10px 5px 10px;
          }
          svg {
            position: relative;
            margin: 0 5px 0 0;
            top: 2px;
          }
        }
      }
      &.reveal1 {
        left: 790px;
        top: 240px;
      }
      &.reveal2 {
        left: 790px;
        top: 370px;
      }
    }
  }

  // Slide 5
  .slide-05 {
    .player {
      &.analyst {
        left: 505px;
        top: 335px;
      }
      &.circle {
        left: 425px;
        top: 275px;
      }
      .Player__Path {
        position: absolute;
      }
      &.terminal {
        position: absolute;
        width: 70px;
        height: 70px;
        svg.progress-pie, svg.computer {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: opacity 200ms ease;
          .background {
            stroke: $red;
            opacity: 1;
          }
        }
        &.hide-line {
          .Player__Path {
            opacity: 0;
          }
          svg.progress-bar, svg.computer {
            .background {
              stroke: $white;
              opacity: 0.2;
            }
          }
        }
      }
      &.terminal1 {
        left: 345px;
        top: 220px;
        .Player__Path {
          left: 60px;
          top: 60px;
        }
      }
      &.terminal2 {
        left: 285px;
        top: 380px;
        .Player__Path {
          left: 70px;
          top: 30px;
        }
      }
      &.terminal3 {
        left: 345px;
        top: 540px;
        .Player__Path {
          left: 60px;
          top: -40px;
        }
      }
      &.terminal4 {
        left: 710px;
        top: 220px;
        .Player__Path {
          right: 55px;
          top: 60px;
        }
      }
      &.terminal5 {
        left: 770px;
        top: 380px;
        .Player__Path {
          right: 65px;
          top: 30px;
        }
      }
      &.terminal6 {
        left: 710px;
        top: 540px;
        .Player__Path {
          right: 55px;
          top: -40px;
        }
      }
    }
  }

  $leftOffset: -20px;
  $topOffset: 0px;
  .slide-06 {
    .player {
      &.alert {
        @include AlertCenter();
      }
      // Not used in the actual slides, but saving for reference
      // To reuse this animation on a future slide because it's awesome
      &.block1 {
        left: 90px + $leftOffset;
        top: 200px + $topOffset;
      }
      &.block2 {
        left: 90px + $leftOffset;
        top: 280px + $topOffset;
      }
      &.block3 {
        left: 90px + $leftOffset;
        top: 414px + $topOffset;
      }
      // Column 2
      &.block4 {
        left: 420px + $leftOffset;
        top: 200px + $topOffset;
      }
      &.block5 {
        left: 420px + $leftOffset;
        top: 284px + $topOffset;
      }
      // XLS block
      &.block6 {
        left: 420px + $leftOffset;
        top: 346px + $topOffset;
      }
      &.block7 {
        left: 420px + $leftOffset;
        top: 395px + $topOffset;
      }
      &.block8 {
        left: 420px + $leftOffset;
        top: 457px + $topOffset;
      }
      &.block9 {
        left: 420px + $leftOffset;
        top: 519px + $topOffset;
      }
      // Column 3
      &.block10 {
        left: 750px + $leftOffset;
        top: 200px + $topOffset;
      }
      &.block11 {
        left: 750px + $leftOffset;
        top: 276px + $topOffset;
      }
      &.block12 {
        left: 750px + $leftOffset;
        top: 370px + $topOffset;
      }
      &.block13 {
        left: 750px + $leftOffset;
        top: 446px + $topOffset;
      }
      &.client {
        @include CardCenter();
      }
    }
    .copy {
      position: absolute;
      opacity: 0;
      border: 4px solid $blue;
      background: rgba($black, 0.8);
      box-sizing: border-box;
      transition: border 0.5s ease;
      &.show {
        opacity: 1;
      }
      &.flickerOff {
        border: 1px solid $white;
        animation: flickerOff 0.5s ease;
      }
    }
  }

  $topOffset: -40px;
  .slide-08 {
    .player {
      &.card1 {
        left: 170px;
        top: 260px + $topOffset;
      }
      &.card2 {
        left: 170px;
        top: 450px + $topOffset;
      }
      &.card3 {
        left: 830px;
        top: 350px + $topOffset;
      }
      &.path1 {
        left: 290px;
        top: 340px + $topOffset;
      }
      &.path2 {
        left: 290px;
        top: 480px + $topOffset;
      }
      &.path3 {
        left: 730px;
        top: 430px + $topOffset;
      }
      &.message {
        width: 340px;
        left: 390px;
        top: 280px + $topOffset;
        background: rgba($black, 0.5);
        padding: 20px;
        border: 4px solid rgba($white, 0.5);
        box-sizing: border-box;
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        font-family: $blenderThin;
        h3 {
          margin: 0px 0px 10px 0px;
          font-family: $blenderBold;
        }
        .redacted {
          font-family: $blenderBold;
          font-weight: 500;
          color: rgba($black, 0.5);
          background: $white;
          padding: 2px 4px;
        }
      }
      &.alert {
        left: 390px;
        top: 590px + $topOffset;
        width: 340px;
        box-sizing: border-box;
      }
    }
  }

  $topOffset: -20px;
  $leftOffset: 0px;
  .slide-09 {
    .player {
      &.card {
        left: 270px + $leftOffset;
        top: 360px + $topOffset;
      }
      // Path group 1
      &.path1 {
        left: 400px + $leftOffset;
        top: 300px + $topOffset;
      }
      &.path2 {
        left: 400px + $leftOffset;
        top: 306px + $topOffset;
      }
      // Path group 2
      &.path3 {
        left: 400px + $leftOffset;
        top: 360px + $topOffset;
      }
      &.path4 {
        left: 400px + $leftOffset;
        top: 366px + $topOffset;
      }
      // Path group 3
      &.path5 {
        left: 400px + $leftOffset;
        top: 433px + $topOffset;
      }
      &.path6 {
        left: 400px + $leftOffset;
        top: 439px + $topOffset;
      }
      // Path group 4
      &.path7 {
        left: 400px + $leftOffset;
        top: 457px + $topOffset;
      }
      &.path8 {
        left: 400px + $leftOffset;
        top: 463px + $topOffset;
      }
      // Path group 5
      &.path9 {
        left: 400px + $leftOffset;
        top: 477px + $topOffset;
      }
      &.path10 {
        left: 400px + $leftOffset;
        top: 483px + $topOffset;
      }
      &.computer1 {
        left: 630px;
        top: 250px;
      }
      &.computer2 {
        left: 750px;
        top: 310px;
      }
      &.computer3 {
        left: 690px;
        top: 390px;
      }
      &.computer4 {
        left: 750px;
        top: 466px;
      }
      &.computer5 {
        left: 630px;
        top: 526px;
      }
    }
  }

  .slide-10 {
    .player {
      &.card {
        left: 220px;
        top: 300px;
      }
      &.path1 {
        left: 340px;
        top: 370px;
      }
      &.path2 {
        left: 670px;
        top: 320px;
      }
      &.scanner {
        left: 440px;
        top: 260px;
        border: 4px solid $blue;
        width: 220px;
        height: 220px;
        .Player__DataScan {
          left: 20px;
          top: 20px;
          overflow: hidden;
        }
        .scannerTitle {
          position: absolute;
          text-align: center;
          width: 100%;
          height: 30px;
          padding: 0px;
          line-height: 30px;
          &.top {
            bottom: 100%;
            .titleBorder {
              border-width: 4px 4px 0px 4px;
            }
          }
          .titleBorder {
            border-color: $blue;
            border-style: solid;
            padding: 5px 10px 5px 10px;
          }
          svg {
            position: relative;
            margin: 0 5px 0 0;
            top: 2px;
          }
        }
      }
      &.title {
        left: 800px;
        top: 300px;
        width: 120px;
        border-top: 4px solid $blue;
        padding: 10px 0px 10px 20px;
        text-transform: uppercase;
        color: $blue;
        box-sizing: border-box;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          background: $blue;
          left: 0px;
          top: 12px;
        }
      }
      &.row {
        background: $red;
        padding: 5px 5px 5px 25px;
        width: 120px;
        box-sizing: border-box;
        svg {
          position: absolute;
          left: 5px;
          top: 5px;
        }
      }
      &.row1 {
        left: 800px;
        top: 340px;
      }
      &.row2 {
        left: 800px;
        top: 380PX;
      }
      &.row3 {
        left: 800px;
        top: 420PX;
      }
    }
  }

  $topOffset: -20px;
  $leftOffset: -110px;
  .slide-11, .slide-12, .slide-13 {
    .player {
      &.card {
        left: 270px + $leftOffset;
        top: 360px + $topOffset;
      }
      // Path group 1
      &.path1 {
        left: 400px + $leftOffset;
        top: 300px + $topOffset;
      }
      &.path2 {
        left: 400px + $leftOffset;
        top: 306px + $topOffset;
      }
      // Path group 2
      &.path3 {
        left: 400px + $leftOffset;
        top: 360px + $topOffset;
      }
      &.path4 {
        left: 400px + $leftOffset;
        top: 366px + $topOffset;
      }
      // Path group 3
      &.path5 {
        left: 400px + $leftOffset;
        top: 433px + $topOffset;
      }
      &.path6 {
        left: 400px + $leftOffset;
        top: 439px + $topOffset;
      }
      // Path group 4
      &.path7 {
        left: 400px + $leftOffset;
        top: 457px + $topOffset;
      }
      &.path8 {
        left: 400px + $leftOffset;
        top: 463px + $topOffset;
      }
      // Path group 5
      &.path9 {
        left: 400px + $leftOffset;
        top: 477px + $topOffset;
      }
      &.path10 {
        left: 400px + $leftOffset;
        top: 483px + $topOffset;
      }
      &.computer1 {
        left: 630px + $leftOffset;
        top: 270px + $topOffset;
      }
      &.computer2 {
        left: 750px + $leftOffset;
        top: 330px + $topOffset;
      }
      &.computer3 {
        left: 690px + $leftOffset;
        top: 410px + $topOffset;
      }
      &.computer4 {
        left: 750px + $leftOffset;
        top: 486px + $topOffset;
      }
      &.computer5 {
        left: 630px + $leftOffset;
        top: 546px + $topOffset;
      }
      &.loading {
        left: 850px + $leftOffset;
        top: 410px + $topOffset;
      }
      &.url {
        background: $red;
        padding: 5px 5px 5px 25px;
        width: 230px;
        box-sizing: border-box;
        svg {
          position: absolute;
          left: 5px;
          top: 5px;
        }
      }
      &.url1 {
        left: 850px + $leftOffset;
        top: 488px + $topOffset;
      }
      &.url2 {
        left: 850px + $leftOffset;
        top: 523px + $topOffset;
      }
      &.url3 {
        left: 850px + $leftOffset;
        top: 558px + $topOffset;
      }
    }
  }

  .slide-14 {
    .player {
      &.customer {
        left: 500px;
        top: 500px;
      }
    }
    .calendar {
      position: absolute;
      left: 260px;
      top: 260px;
      width: 600px;
      height: 170px;
      .months {
        display: flex;
        height: 100%;
        .month {
          flex: 1 1 auto;
          text-align: center;
          border-left: 1px solid rgba($white, 0.2);
          font-size: 14px;
          opacity: 1;
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            left: 0px;
            top: 30px;
            height: 1px;
            background: rgba($white, 0.2);
          }
          &:last-child {
            border-right: 1px solid rgba($white, 0.2);
          }
        }
      }
      .timelines {
        .timeline {
          position: absolute;
          width: 100%;
          height: 30px;
          line-height: 30px;
          label {
            position: absolute;
            width: 180px;
            left: -200px;
            text-align: right;
            text-transform: none;
          }
          .event {
            width: 0px;
            height: 30px;
            position: absolute;
            overflow: hidden;
            background: $blue;
            z-index: 2;
            svg {
              position: absolute;
              left: 9px;
              top: 9px;
            }
            &.bug {
              background: $red;
            }
          }
          .loading {
            position: absolute;
            width: 30px;
            height: 30px;
            left: 2%;
            top: 7px;
            z-index: 2;
          }
          .grower {
            position: absolute;
            left: 7%;
            background: rgba($white, 0.1);
            height: 30px;
            z-index: 1;
          }
          .label {
            position: absolute;
            height: 30px;
            line-height: 30px;
            white-space: nowrap;
            z-index: 2;
          }
          &.timeline1 {
            top: 50px;
            .event {
              left: 7%;
            }
          }
          &.timeline2 {
            top: 90px;
            .event1 {
              left: 8%;
            }
            .event2 {
              left: 89%;
            }
            .label1 {
              left: calc(8% + 40px);
            }
            .label2 {
              left: calc(89% + 40px);
            }
            .grower {
              // width: 85%;
              width: 0%;
            }
          }
          &.timeline3 {
            top: 130px;
            .event {
              left: 87%;
              z-index: 2;
            }
            .label {
              left: calc(87% + 40px);
              z-index: 2;
            }
            .grower {
              // width: 85%;
              width: 0%;
              background: rgba($red, 0.2);
            }
          }
        }
      }
    }
  }
  
}