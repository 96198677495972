@import '../base';

// Turn debug on to help debug the grid
$debug: false;
$colors: #f0f2, #0f02, #0ff2, #f002, #ff02, #08f2;

@function color($number: 1) {
  @return if(
    $debug,
    nth($colors, ($number % length($colors)) + 1),
    transparent
  );
}

@mixin loader03($size, $color, $border-size, $duration, $align: null) {
  width: $size;
  height: $size;
  border: $border-size solid transparent;
  border-top-color: $color;
  border-bottom-color: $color;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate $duration linear infinite;
  @if ($align == center) {
    margin: auto auto;
  }
  @if ($align == middle) {
    top: 50%;
    margin: -$size / 2 auto 0;
  }
  @include loader-rotate;
}

@mixin loader-rotate {
  @keyframes loader-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.MitreMapping {
  display: grid;
  grid-template-areas:
    'MainMenu  Title     Logo'
    'MainMenu  Subtitle  Controls'
    'Middle    Middle    Middle'
    'BottomNav BottomNav BottomNav';
  grid-template-columns: auto 1fr 114px;
  grid-template-rows: 45px 45px 1fr;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  background-color: #242c40;

  h2 {
    font-family: $blenderThin;
    line-height: 19px;
    font-size: 20px;
  }

  .capitalize {
    text-transform: capitalize;
  }

  &__ModeControlsContainer {
    position: relative;
    grid-area: Controls;
  }

  &__ModeControls {
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 0px 32px 0px 0px;
  }

  &__ModeButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    background: rgba(255, 255, 255, 0.1);
    height: 32px;
    border-radius: 32px;
    width: 171px;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  &--selected-mode {
    background: $blue;

    &:hover {
      background: #0085c9;
    }
  }

  &__MainMenu {
    background: color(1);

    grid-area: MainMenu;
    width: 100px;
    z-index: $z-chrome-menu-button;
    padding: 20px 0 0 30px;

    .HamburgerButton {
      width: 40px;
      height: 40px;
      background: transparent;
      display: block;
      border: 0 none;
      outline: none;
      cursor: pointer;

      &:hover {
        svg {
          rect {
            fill-opacity: 1;
          }
        }
      }
    }
  }

  &__Logo {
    box-sizing: border-box;
    background: color(2);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    grid-area: Logo;
    width: 100%;
    padding: 32px 32px 0 0;
  }

  &__Title {
    background: color(3);

    grid-area: Title;
    color: #0085c9;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 32px 0 0 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Subtitle {
    background: color(4);

    grid-area: Subtitle;

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 100%;
    height: 40px;
    font-size: 34px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $white;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Middle {
    background: color(11);
    grid-area: Middle;
    overflow: hidden;
    display: grid;
    grid-template: 'main';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: 30px 0px 0px 0px;

    &__Loader {
      grid-area: Middle;
      display: grid;

      // prettier-ignore
      grid-template:
        '.    .      .   ' auto
        '.    top    .   ' 55px
        '.    bottom .   ' 25px
        '.    .      .   ' auto /
         auto 100px  auto
      ;

      grid-gap: 8px;
      position: relative;
      overflow: hidden;
    }

    &__Spinner {
      @include loader03(
        $size: 55px,
        $color: #c53d43,
        $border-size: 5px,
        $duration: 1s,
        $align: center
      );

      grid-area: top;
    }

    &__LoaderLabel {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-area: bottom;
    }
  }
}

@media (min-width: 0px) and (max-width: 1023px) {
  .MitreMapping {
    &__ModeButton {
      width: 100px;
      height: 24px;
    }
  }
}

@media (max-width: 1000px) {
  .MitreMapping {
    &__MainMenu {
      width: 115px;
    }
  }
}

.FadeIn {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
