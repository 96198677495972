@import '../base';
@import '../animations';

$dots: 12;
$distance: 110px;
$duration: 3s;
$size: 6px;

@include keyframes(marchingDots) {
  0% {
    left: 0%;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

@include keyframes(noFade) {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

@include keyframes(InOut) {
  0% {
    left: 0%;
    opacity: 0;
  }
  25% {
    left: 25%;
    opacity: 1;
  }
  75% {
    left: 75%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

@include keyframes(InOutReverse) {
  0% {
    left: 100%;
    opacity: 0;
  }
  25% {
    left: 75%;
    opacity: 1;
  }
  75% {
    left: 25%;
    opacity: 1;
  }
  100% {
    left: 0%;
    opacity: 0;
  }
}

@include keyframes(marchingDotsReverse) {
  0% {
    left: 105%;
    opacity: 0;
  }
  5% {
    left: 100%;
    opacity: 1;
  }
  95% {
    left: 0%;
  }
  100% {
    left: 0%;
    opacity: 0;
  }
}

@include keyframes(leftToRight) {
  0% {
    left: 0%;
    opacity: 0;
  }
  95% {
    left: 100%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

@include keyframes(rightToLeft) {
  0% {
    left: 100%;
    opacity: 0;
  }
  95% {
    left: 0%;
    opacity: 1;
  }
  100% {
    left: 0%;
    opacity: 0;
  }
}

@include keyframes(marchingDotsCollection) {
  0% {
    left: 0%;
    opacity: 0;
  }
  95% {
    left: 100%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 1;
  }
}

@include keyframes(marchingDotsReverseCollection) {
  0% {
    left: 100%;
    opacity: 0;
  }
  95% {
    left: 0%;
    opacity: 1;
  }
  100% {
    left: 0%;
    opacity: 1;
  }
}

@include keyframes(marchingDotsReverseStopped) {
  0% {
    left: 105%;
    opacity: 1;
  }
  5% {
    left: 100%;
    opacity: 1;
  }
  95% {
    left: 0%;
    opacity: 0;
  }
  99% {
    opacity: 1;
  }
  100% {
    left: 0%;
    opacity: 0;
  }
}

$space: 100 / $dots;

// Animation
[data-active='1'] {
  .player--dots .player--dots-track span {
    opacity: 1;
    animation: marchingDots linear 1s infinite;
  }
  .player--dots.reverse-direction .player--dots-track span {
    opacity: 1;
    animation: marchingDotsReverse linear 1s infinite;
  }
  .player--dots.left-to-right .player--dots-track span {
    opacity: 1;
    animation: leftToRight linear 1s infinite;
  }
  .player--dots.right-to-left .player--dots-track span {
    opacity: 1;
    animation: rightToLeft linear 1s infinite;
  }
  .player--dots.collection .player--dots-track span {
    opacity: 1;
    animation: marchingDotsCollection linear 1s infinite;
  }
  .player--dots.reverse-collection .player--dots-track span {
    opacity: 1;
    animation: marchingDotsReverseCollection linear 1s infinite;
  }
  .player--dots.in-out .player--dots-track span {
    opacity: 1;
    animation: InOut linear 1s infinite;
  }
  .player--dots.in-out-reverse .player--dots-track span {
    opacity: 1;
    animation: InOutReverse linear 1s infinite;
  }
}

// Evil data packets getting blocked
[data-active='1']
  .blocked
  .player--dots.reverse-direction
  .player--dots-track
  span {
  animation: marchingDotsReverseStopped linear 1s infinite;
}

.blocked {
  .player--dots {
    .player--dots-track {
      width: 200px;
      left: 82px !important;
    }
  }
}

.player--dots {
  position: relative;
  // Red dots
  &.red-dots {
    .player--dots-track span {
      background: $red;
    }
  }
  &.white-dots {
    .player--dots-track span {
      background: $white;
    }
  }
  &.square-dots {
    .player--dots-track span {
      border-radius: 0;
    }
  }
  &.small-dots {
    .player--dots-track span {
      width: 4px;
      height: 4px;
    }
  }
  &.right {
    left: 100% !important;
    transform-origin: 0% 0 !important;
    .player--dots-track {
      left: auto;
      right: 0;
    }
  }
  &.left {
    right: 100% !important;
  }
  // Blue dots
  .player--dots-track {
    position: absolute;
    height: 4px;
    width: 200px;
    left: 0;
    transition: left 0.5s ease, width 0.2s ease;
    span {
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      top: 0;
      background: $blue;
      border-radius: 50%;
      // Animations
      &.inout {
        animation: InOut linear 1s infinite;
      }
      &.marchingdotsreversestopped {
        animation: marchingDotsReverseStopped linear 1s infinite;
      }
      &.marchingdotsreverse {
        animation: marchingDotsReverse linear 1s infinite;
      }
      &.marchingdots {
        animation: marchingDots linear 1s infinite;
      }
    }
  }
}
