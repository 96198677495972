@import '../base';

$min: 20;
$max: 60;
@include keyframes(blinkRow) {
  0% {
    opacity: randomNum($min, $max) / 100;
  }
  10% {
    opacity: randomNum($min, $max) / 100;
  }
  20% {
    opacity: randomNum($min, $max) / 100;
  }
  30% {
    opacity: randomNum($min, $max) / 100;
  }
  40% {
    opacity: randomNum($min, $max) / 100;
  }
  50% {
    opacity: randomNum($min, $max) / 100;
  }
  60% {
    opacity: randomNum($min, $max) / 100;
  }
  70% {
    opacity: randomNum($min, $max) / 100;
  }
  80% {
    opacity: randomNum($min, $max) / 100;
  }
  90% {
    opacity: randomNum($min, $max) / 100;
  }
  100% {
    opacity: randomNum($min, $max) / 100;
  }
}

.Player__DataScan {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  &__Rows {
    display: grid;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  &__Row {
    width: 100%;
    display: grid;
    margin: 0;
    opacity: 0.2;
    span {
      background: $white;
    }
    &.blink-row {
      @include animation(blinkRow 0.5s linear infinite);
    }
  }
}
