@import 'base';

.Timer {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;

  .Progress {
    transform-origin: center center;
    transform: rotate(-0.25turn);
    margin-right: 5px;
  }

  .Progress_Tick {
    display: block;
    background: red; // Help spot errors as this should never happen

    &--filled {
      background: hsla(193, 72%, 60%, 1);
      transition: all 0.75s;
    }

    &--unfilled {
      background: hsla(193, 10%, 60%, 0.25);
      transition: all 0.75s;
    }
  }

  .Time {
    display: grid;
    grid-template-areas: 'Days       Hours      Minutes      Seconds';
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 5px;

    &__Digit {
      text-align: center;
      padding: 0 0.25em;
      font-size: 20px;
      font-family: 'Blender Thin';

      &:not(:nth-of-type(3))::after {
        content: '';
        position: relative;
        right: -5px;
        top: -0.1em;
        color: hsla(0, 100%, 100%, 0.4);
      }
    }

    &__Column {
      span,
      label {
        display: block;
      }
    }

    &__Days {
      grid-area: Days;
    }
    &__Hours {
      grid-area: Hours;
    }
    &__Minutes {
      grid-area: Minutes;
    }
    &__Seconds {
      grid-area: Seconds;
    }

    label {
      text-align: center;
      color: #50c2e3;
      text-transform: uppercase;
      font-family: $futuraBold;
      font-size: 10px;
      letter-spacing: 1px;
    }
  }
}
