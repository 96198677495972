@import '../../base';

.RiskAnalysisProbability {
  svg:hover {
    cursor: pointer;

    g {
      opacity: 1;
    }
  }

  &__Row {
    margin-bottom: 30px;

    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    align-items: center;
    justify-items: left;
  }

  &__Gear {
    cursor: pointer;
    padding: 10px;
    border: 3px solid rgba($white, 0.4);

    &:hover {
      border-color: $white;

      svg {
        path {
          fill-opacity: 1;
        }
      }
    }
  }

  &__Legend {
    margin-left: 50px;
    margin-top: 20px;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    align-items: start;

    &__Filters {
      display: grid;
      grid-template-columns: repeat(auto-fit, 120px);
      grid-gap: 20px;

      &__Filter {
        margin-top: 12px;

        display: grid;
        grid-template:
          'dot title   ' auto
          '.   subtitle' 1fr /
          auto 1fr;
        grid-gap: 5px;
        grid-row-gap: 0;
        align-items: center;

        & > div {
          font-size: 10px;

          &:nth-child(1) {
            grid-area: dot;
          }
          &:nth-child(2) {
            text-transform: uppercase;
            font-family: $futuraBold;

            grid-area: title;
          }
          &:nth-child(3) {
            font-family: $blenderThin;

            grid-area: subtitle;
          }
        }
      }
    }
  }

  &__Legend .Modal__Body__Content > div {
    background: #282d30;
    display: grid;
    grid-template:
      'top' 100px
      'bottom' 1fr /
      1fr;
    height: 100%;
    font-family: 'futura-pt';
  }

  &__Popup {
    & > .Popup__Middle {
      padding: 30px 40px;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 10px;
    }

    &__Content {
      max-height: 550px;
      overflow: auto;
    }

    &__Column {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, auto);

      h3 {
        margin: 0 0 20px 0;
        font-family: $futuraBold;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
}

.Filters {
  &__Body {
    overflow: hidden;
    display: grid;
    grid-area: bottom;
    // prettier-ignore
    grid-template:
      'left right' auto /
      1fr  1fr
    ;
  }

  &__Title {
    grid-area: top;
    font-size: 34px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.02em;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__Left {
    grid-area: left;
    column-width: 11em;
    padding-left: 40px;
  }

  &__Right {
    grid-area: right;
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    margin-bottom: 40px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;

    &__Body {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      overflow: auto;
    }
  }

  &--row {
    display: flex;
    flex-direction: row;
    flex: 0 1 50px;
    min-height: 50px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &--row-header {
    flex: 3 3;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.7);
  }

  &--row-column {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
  }

  label {
    display: grid;
    align-items: center;
    break-inside: avoid;
    position: relative;
    font-family: $futuraBook;
    font-size: 14px;
    color: rgba($white, 0.7);
    padding: 0 0 0 30px;
    height: 35px;
    cursor: pointer;

    &:hover {
      color: $white;
      .checkmark {
        border: 1px solid rgba($white, 0.5);
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: #ffffff;
        border: 1px solid rgba($white, 1);
        &:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 8px;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid rgba($white, 0.2);
      background-color: transparent;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid #282d30;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
}
