@import '../base';

.player.red .Player__Revealer {
  border: 4px solid rgba($red, 1);
}

.Player__Revealer {
  &.red {
    border: 4px solid rgba($red, 1);
  }
  border: 4px solid rgba($white, 0.2);
  position: absolute;
  transition: border 0.2s ease;
  .pixelBox {
    position: absolute;
    display: block;
  }
  &__Pixels {
    position: absolute;
    overflow: hidden;
    z-index: 3;
  }
  &__Screen {
    position: absolute;
    background: linear-gradient(0deg, rgba($blue, 0.5) 0%, rgba($blue, 0) 100%);
    transition: opacity 1s ease;
    z-index: 4;
  }
  &__Icon {
    position: absolute;
    text-align: center;
    box-sizing: border-box;
    height: 0px;
    overflow: hidden;
    z-index: 2;
    .svg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      text-align: center;
    }
    &__Text {
      position: absolute;
      left: 0px;
      height: 40px;
      width: 100%;
      .line {
        position: absolute;
        top: 0px;
        height: 1px;
        background: rgba($white, 0.5);
        width: 60%;
        left: 20%;
      }
      .label {
        position: absolute;
        font-size: 14px;
        top: 10px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
