@import '../base';
@import '../animations';

$dots: 6;
$distance: 110px;
$duration: 3s;
$size: 6px;
$delay: $duration / $dots;

@include keyframes(moveDots) {
  0% {
    @include transform(translateX(0));
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    @include transform(translateX($distance));
    opacity: 0;
  }
}

.Home,
.SandboxFindings3,
.MaliciousIP2,
.EscalationFollowup {
  .player.alert {
    z-index: $z-home-slide-player-alert;
  }

  .player.dots {
    width: $distance;
    height: $size;
    &[data-active='1'] {
      span {
        opacity: 1;
        @for $i from 1 through $dots {
          &:nth-child(#{$i}) {
            @include animation(
              moveDots $duration linear (($i - 1) * $delay * -1) infinite
            );
          }
        }
      }
    }
    span {
      position: absolute;
      display: block;
      height: $size;
      width: $size;
      background: $red;
      border-radius: 50%;
      opacity: 0;
      animation: none;
    }
  }
}

.MaliciousIP2 {
  .player.dots span {
    background: $blue;
  }
}

.EscalationFollowup {
  .player.dots span {
    background: rgba($white, 0.5);
    border-radius: 0;
  }
}
