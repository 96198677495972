@import 'base';

.Navigation {
  display: grid;
  // prettier-ignore
  grid-template:
    'logo ' auto
    'items';
  grid-template-rows: 90px 1fr;

  &__Logo {
    align-self: center;
    justify-self: center;
    padding: 30px;
  }

  &__Items {
    grid-area: items;

    display: grid;
    // prettier-ignore
    grid-template:
      'use-cases use-cases ' 1fr
      'soc       compliance' 1fr
      'risk      killchain ' 1fr
      'mitre     none      ' 1fr /
       1fr       1fr;
    grid-gap: 2px;
    margin: 30px 90px 90px 90px;
  }

  &__Item {
    cursor: pointer;
    border: 2px solid #4c4e50;
    text-align: center;
    font-size: 32px;
    margin: -2px;
    padding: 0 60px;
    font-family: $blenderThin;
    z-index: 1;

    display: grid;
    align-items: center;
    justify-items: left;

    &--use-cases {
      grid-area: use-cases;
      justify-items: center;
    }

    &--soc {
      grid-area: soc;
    }

    &--compliance {
      grid-area: compliance;
    }

    &--mitre {
      grid-area: mitre;
    }

    &--risk {
      grid-area: risk;
    }

    &--killchain {
      grid-area: killchain;
    }

    &:hover {
      border-color: $blue;
      z-index: 2;
    }

    opacity: 0;
    $delay: 100ms;
    $startDelay: 250ms;
    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        animation: shimmerIn 0.2s ease;
        animation-delay: $i * $delay + $startDelay;
        animation-fill-mode: forwards;
      }
    }
  }

  &__ItemContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__ItemIcon {
    height: 40px;
    width: 40px;
    margin-right: 20px;
    border: 2px solid rgba($white, 0.2);

    display: grid;
    align-items: center;
    justify-items: center;
  }
}

@media (max-height: 750px) {
  .Navigation {
    &__Item {
      font-size: 26px;
    }
  }
}

@media (max-width: 900px) {
  .Navigation {
    &__Item {
      font-size: 26px;
    }
  }
}
