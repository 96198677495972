@import '../../base';

.ServicesMenu {
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  background: #151723;
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);

  &__Close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__Services {
    flex: none;

    display: grid;
    align-items: center;
    justify-items: center;
    background-color: white;
    font-size: 14px;
    position: relative;
    height: 80px;
    width: 150px;
    color: black;
    margin-top: -5px;
  }

  &__Buttons {
    flex: 1;

    padding: 30px;
    display: flex;
    flex-direction: column;

    .ServicesMenu__Button {
      margin-bottom: 15px;
      font-size: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__Button {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px 15px;
    height: 20px;
    border-radius: 22px;
    font-size: 24px;
    font-family: $blenderThin;
    text-align: left;
    margin-right: auto;
    cursor: pointer;
    border: 2px solid #f05938;
    color: #f05938;

    &:hover {
      background-color: rgba(#f05938, 0.15);
    }

    &--selected {
      background: #36a170;
      border-color: #36a170;
      color: white;

      &:hover {
        border-color: white;
        background-color: darken(#36a170, 10%);
      }
    }

    svg {
      margin-right: 10px;
    }

    transition: border-color 0.25s, background-color 0.25s;
  }

  &__ServicesMarker {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 5px;
    width: 5px;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }

  &__Bulk {
    flex: none;

    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;

    padding: 0 30px 30px 30px;
  }

  &__BulkButton {
    padding: 10px 15px;
    height: 15px;
    border-radius: 20px;
    font-size: 14px;
    line-height: 15px;
    font-family: $blenderThin;
    cursor: pointer;

    &--all {
      color: #36a170;
      border: 1px solid #36a170;

      &:hover {
        background-color: rgba(#36a170, 0.25);
      }

      transition: background-color 0.25s;
    }

    &--none {
      color: #f05938;
      border: 1px solid #f05938;

      &:hover {
        background-color: rgba(#f05938, 0.15);
      }

      transition: background-color 0.25s;
    }
  }
}
