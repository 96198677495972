@import '../components/base';

.Template__GlobalScanInfo {
  .player {
    &.world {
      left: 80px;
      top: 200px;
    }
    &.info {
      left: 720px;
      top: 310px;
    }
  }
}
