@import 'base';

.Button {
  cursor: pointer;
  height: 40px;
  padding: 0 15px;
  border: 2px solid $blue;
  color: $blue;
  font-size: 13px;
  line-height: 36px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  min-width: 100px;
  font-family: Blender Bold;

  &--with-icon {
    align-items: center;
    justify-items: center;
    display: flex;
    flex-direction: row;
  }

  &--inverted {
    background-color: $blue;
    color: rgb(0, 0, 0);
  }

  &:hover {
    background-color: rgba($blue, 0.1);
  }

  svg {
    margin: 0 10px 0 0;
  }
}
