@import '../base';
@import '../animations';

.ProcessLocation {
  .connector-tree {
    // Payload activity slide
    &.slide-payload-activity {
      &.highlight-path {
        .connectors.left span {
          // Vertical lines
          &:nth-child(2) {
            top: 230px;
            left: 400px;
            height: 182px;
            width: 2px;
          }
          &:nth-child(4) {
            opacity: 1;
            background: $blue;
          }
          &:nth-child(5) {
            opacity: 0;
          }
        }
        .connectors.right span {
        }
      }
      // Right
      .connectors.right span {
        &:nth-child(1) {
          width: 100px;
          left: 650px;
          top: 230px;
        }
      }
    }
    // Path highlighted
    &.highlight-path {
      .connectors.left span {
        background: none;
        &:nth-child(2) {
          top: 320px;
          height: 92px;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5) {
          background: $blue;
        }
      }
      .connectors.right span {
        background: none;
        &:nth-child(2) {
          top: 230px;
          height: 92px;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          background: $blue;
        }
      }
    }
    // Connectors
    .connectors {
      span {
        position: absolute;
        display: block;
        background: $blue;
        height: 2px;
        @include transition(background 0.2s ease);
      }
      &.left {
        span {
          // Middle line
          &:nth-child(1) {
            width: 70px;
            top: 410px;
            left: 330px;
          }

          // Vertical lines
          &:nth-child(2) {
            top: 230px;
            left: 400px;
            height: 182px;
            width: 2px;
          }
          &:nth-child(3) {
            top: 410px;
            left: 400px;
            height: 180px;
            width: 2px;
          }

          // Right lines
          &:nth-child(4) {
            width: 40px;
            left: 400px;
            top: 230px;
          }
          &:nth-child(5) {
            width: 40px;
            left: 400px;
            top: 320px;
          }
          &:nth-child(6) {
            width: 40px;
            left: 400px;
            top: 410px;
          }
          &:nth-child(7) {
            width: 40px;
            left: 400px;
            top: 500px;
          }
          &:nth-child(8) {
            width: 40px;
            left: 400px;
            top: 590px;
          }
        }
      }
      &.right {
        span {
          // Middle line
          &:nth-child(1) {
            width: 70px;
            left: 640px;
            top: 320px;
          }

          // Vertical line
          &:nth-child(2) {
            top: 230px;
            left: 710px;
            height: 180px;
            width: 2px;
          }

          // Right lines
          &:nth-child(3) {
            width: 40px;
            left: 710px;
            top: 230px;
          }
          &:nth-child(4) {
            width: 40px;
            left: 710px;
            top: 320px;
          }
          &:nth-child(5) {
            width: 40px;
            left: 710px;
            top: 410px;
          }
        }
      }
    }
  }
}
