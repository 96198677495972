@import '../components/base';

.Template__ScanTerminalAction {
  .player {
    &.product {
      left: 512px;
      top: 355px;
    }
    &.circle {
      left: 425px;
      top: 275px;
    }
    .Player__Path {
      position: absolute;
    }
    &.terminal {
      position: absolute;
      width: 70px;
      height: 70px;
      svg.progress-pie,
      svg.computer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 200ms ease;
        .background {
          stroke: $red;
          opacity: 1;
        }
      }
      &.hide-line {
        .Player__Path {
          opacity: 0;
        }
        svg.progress-bar,
        svg.computer {
          .background {
            stroke: $white;
            opacity: 0.2;
          }
        }
      }
    }
    &.terminal1 {
      left: 345px;
      top: 220px;
      .Player__Path {
        left: 60px;
        top: 60px;
      }
    }
    &.terminal2 {
      left: 285px;
      top: 380px;
      .Player__Path {
        left: 70px;
        top: 30px;
      }
    }
    &.terminal3 {
      left: 345px;
      top: 540px;
      .Player__Path {
        left: 60px;
        top: -40px;
      }
    }
    &.terminal4 {
      left: 710px;
      top: 220px;
      .Player__Path {
        right: 55px;
        top: 60px;
      }
    }
    &.terminal5 {
      left: 770px;
      top: 380px;
      .Player__Path {
        right: 65px;
        top: 30px;
      }
    }
    &.terminal6 {
      left: 710px;
      top: 540px;
      .Player__Path {
        right: 55px;
        top: -40px;
      }
    }
  }
}
