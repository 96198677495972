@import '../base';

.Icon__Team {
  .Icon__Team__Border {
    transition: stroke 0.5s ease;
  }
  &.blue-border {
    .Icon__Team__Border {
      stroke: $blue;
      stroke-opacity: 1;
    }
  }
  &.red-border {
    .Icon__Team__Border {
      stroke: $red;
      stroke-opacity: 1;
    }
  }
}
