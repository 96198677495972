@import '../../base';

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ComplianceAlignmentSummary {
  margin-top: 50px;
  font-size: 14px;
  line-height: 14px;
  overflow: hidden;
  overflow-y: scroll;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  grid-area: main;
  display: grid;
  grid-template:
    'left   functions product coverage right '
    'bottom bottom    bottom  bottom   bottom';
  grid-template-columns: 0 1fr 1fr 1fr 0;
  grid-template-rows: 1fr 60px;
  grid-gap: 60px;

  &__Functions {
    grid-area: functions;

    display: grid;
    // prettier-ignore
    grid-template:
      'header' auto
      '.     ' 1fr
      '.     ' 1fr
      '.     ' 1fr
      '.     ' 1fr
      '.     ' 1fr /
       1fr;
    grid-gap: 15px;
  }

  &__Product {
    grid-area: product;

    display: grid;
    // prettier-ignore
    grid-template:
      'header header' auto
      '.      .     ' 1fr
      '.      .     ' 1fr
      '.      .     ' 1fr
      '.      .     ' 1fr
      '.      .     ' 1fr /
       1fr    1fr;
    grid-gap: 15px;
  }

  &__Coverage {
    grid-area: coverage;

    display: grid;
    // prettier-ignore
    grid-template:
      'header header' auto
      '.      .     ' 1fr
      '.      .     ' 1fr
      '.      .     ' 1fr
      '.      .     ' 1fr
      '.      .     ' 1fr /
       1fr    1fr;
    grid-gap: 15px;
  }

  &__Header {
    grid-area: header;

    font-size: 20px;
    font-family: $blenderBold;
    margin-bottom: 15px;
  }

  &__FunctionsItem,
  &__ProductItem,
  &__CoverageItem {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0 25px;
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-items: left;

    transition: background-color 0.5s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &--active:hover,
    &--active {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &--disabled:hover,
    &--disabled {
      opacity: 0.2;
    }
  }

  &__FunctionsItem {
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-gap: 20px;
    text-transform: capitalize;
    font-size: 18px;

    &--selected:hover,
    &--selected {
      background-color: $orange;
    }
  }

  &__ProductItem {
    &--selected:hover,
    &--selected {
      background-color: $red;
    }
  }

  &__CoverageItem {
    &--selected:hover,
    &--selected {
      background-color: $blue;
    }
  }

  &__CoveragePercent {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &__CoveragePie,
  &__CoverageCheck {
    animation: fade-in 0.5s;
  }

  &__Bottom {
    grid-area: bottom;

    height: 60px;
    top: 60px;
    margin-bottom: -60px;
    overflow: hidden;
    background-color: #1f222f;
    position: relative;

    display: flex;
    flex-direction: row;

    transition: top 0.5s, margin 0.5s;

    &--visible {
      top: 0;
      margin-bottom: 0;
    }
  }

  &__BottomTitle {
    padding: 0 25px 0 0;
    margin: 15px 25px 15px 60px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    line-height: 30px;
    font-family: $blenderBold;
    font-size: 17px;
  }

  &__BottomItem {
    margin: 15px 30px 15px 0;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
  }

  &__BottomItemCount {
    height: 24px;
    min-width: 14px;
    border-radius: 13px;
    border: 2px solid $white;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    margin-left: 10px;
    padding: 0 5px;

    &--functions {
      border-color: $orange;
      color: $orange;
    }

    &--products {
      border-color: $red;
      color: $red;
    }

    &--coverages {
      border-color: $blue;
      color: $blue;
    }
  }

  &__BottomDetail {
    background-color: $green;
    margin-left: auto;
    padding: 25px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: darken($green, 30%);
    }
  }
}
