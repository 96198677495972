@import './base';

.Stage {
  position: relative;
  width: 200px;
  height: 320px;
  box-sizing: border-box;
  border: 4px solid rgba($white, 0.2);
  display: flex;
  flex-direction: column;

  &.short {
    height: 300px;
    .Stage__Label {
      padding: 20px 0 0 0;
    }
    .Stage__Stage {
    }
    .Stage__Title {
      padding: 10px 10px;
    }
    .Stage__Icon {
      padding: 20px 0 0px 0;
    }
  }

  &.wide {
    width: 280px;
    height: 270px;
    .Stage__Label {
      padding: 20px 0 0 0;
    }
    .Stage__Stage {
      padding: 10px 0 10px 0;
    }
    .Stage__Title {
      padding: 10px 10px;
    }
    .Stage__Icon {
      padding: 20px 0 0px 0;
    }
  }

  &__Label {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-family: $futuraBold;
    letter-spacing: 1px;
    color: $blue;
    margin: 0;
    padding: 40px 0 0 0;
  }

  &__Stage {
    display: block;
    width: 100%;
    font-size: 60px;
    color: $white;
    font-family: Blender Thin;
    margin: 0;
    padding: 10px 0 30px 0;
    text-align: center;
    letter-spacing: -3px;
  }

  &__Line {
    height: 2px;
    width: 100%;
    position: relative;
    &:after {
      display: block;
      position: absolute;
      content: '';
      left: 50%;
      top: 0;
      margin-left: -35px;
      height: 2px;
      width: 70px;
      background: rgba($white, 0.2);
    }
  }

  &__Icon {
    display: block;
    text-align: center;
    padding: 20px;
  }

  &__Title {
    padding: 20px 5px;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0px;
    font-family: Blender Book;
  }
}
