@import 'base';

@keyframes popup-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.PopupContainer {
  position: relative;
  outline: none;

  &__PopupPixel {
    top: 50%;
    left: 50%;
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: visible;
  }

  &.focus-within {
    pointer-events: none;

    & > .PopupContainer__PopupPixel {
      & > .Popup {
        display: grid;
      }
    }
  }

  &:focus-within {
    pointer-events: none;

    & > .PopupContainer__PopupPixel {
      & > .Popup {
        display: grid;
      }
    }
  }

  &:focus {
    pointer-events: none;

    & > .PopupContainer__PopupPixel {
      & > .Popup {
        display: grid;
      }
    }
  }

  .Popup {
    pointer-events: auto;
    position: absolute;
    z-index: $z-popup;

    display: none;
    grid-template:
      'topleft  topmid  topright' auto
      'middle   middle  middle' 1fr
      'btmleft  btmmid  btmright' auto /
      auto 1fr auto;

    animation: popup-fade-in 0.5s;
    outline: none;

    &--top-left {
      top: 40px;
      left: -20px;
    }
    &--top-middle {
      top: 40px;
      left: 0;
    }
    &--top-right {
      top: 40px;
      right: -20px;
    }

    &--bottom-left {
      bottom: 40px;
      left: -20px;
    }
    &--bottom-middle {
      bottom: 40px;
    }
    &--bottom-right {
      bottom: 40px;
      right: -20px;
    }

    &__QuoteMark {
      height: 10px;
      width: 10px;
      background-color: $popup;
      transform: rotate(45deg);
      margin-bottom: -5px;

      &--hidden {
        display: none;
      }

      &--top-left {
        grid-area: topleft;
      }
      &--top-middle {
        grid-area: topmid;
      }
      &--top-right {
        grid-area: topright;
      }
      &--bottom-left {
        grid-area: btmleft;
      }
      &--bottom-middle {
        grid-area: btmmid;
      }
      &--bottom-right {
        grid-area: btmright;
      }

      &--top-left,
      &--bottom-left {
        margin-left: 15px;
      }

      &--top-middle,
      &--bottom-middle {
        margin-left: calc(50% - 5px);
      }

      &--top-right,
      &--bottom-right {
        margin-right: 15px;
      }

      &--bottom-left,
      &--bottom-middle,
      &--bottom-right {
        margin-bottom: 0;
        margin-top: -5px;
      }
    }

    &__Middle {
      grid-area: middle;

      outline: none;
      padding: 20px 30px;
      background-color: $popup;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    }
  }
}
