@import '../base';

@include keyframes(pulse) {
  0% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.player--cloudShark {
  position: relative;
  .player--cloudShark-icon {
    position: absolute;
    left: 50%;
    top: -35px;
    margin-left: -25px;
  }
  .player--cloudShark-circle {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    border: 1px solid rgba($white, 0.5);
    &.pulse-on {
      .player--cloudShark-pulse {
        $pulses: 6;
        $delay: 2s / $pulses;
        @for $i from 1 through $pulses {
          &:nth-child(#{$i}) {
            @include animation(pulse 2s ease infinite);
            animation-delay: $delay * $i;
          }
        }
      }
    }
    .player--cloudShark-pulse {
      position: absolute;
      opacity: 0;
      width: 220px;
      height: 220px;
      border: 20px solid rgba($white, 0.1);
      box-sizing: border-box;
      border-radius: 50%;
      @include animation(none);
    }
  }
}
