@import '../base';

.ChartsTab {
  h2 {
    margin: 0 0 30px 0;
    padding: 0 0 20px 0;
    border-bottom: 3px solid $blue;
  }

  .ChartShell {
    margin-bottom: 30px;
  }
}
