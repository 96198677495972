/*

RANSOMWARE

*/
@import "../base";

//
// Players that often have the same starting position
//

$dots: 6;
$duration: 3s;
$size: 6px;
$delay: $duration / $dots;

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin DonutCenter {
  top: 25%;
  left: 50%;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

@include keyframes(dotsLeftToRight) {
  0% {
    left: 0%;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

// Use case wrapper
.powershell {

// Slide 1 - Overview
$leftOffset: -25px;
.slide-01 {
  .player {
    &.card1 {
      top: 240px;
      left: 120px + $leftOffset;
    }
    &.card2 {
      top: 240px;
      left: 360px + $leftOffset;
    }
    &.card3 {
      top: 240px;
      left: 600px + $leftOffset;
    }
    &.card4 {
      top: 240px;
      left: 840px + $leftOffset;
    }
  }
}

//
// Slide 2 - Endpoint Activity
//
.slide-02 {
  .player {
    &.code {
      left: centerLeft(50px);
      top: centerTop(50px);
      width: 50px;
      height: 50px;
    }
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.analyzing {
      left: 640px;
      top: 320px;
    }
    &.attacker {
      @include CardCenter();
    }
    &.powershell {
      transform: scale(1.5);
      left: centerLeft(70px);
      top: centerTop(70px);
      .player--circleIcon-circle {
        border-width: 2px;
      }
    }
    &.dots-left {
      top: 400px;
      left: 280px;
    }
    &.dots-right {
      top: 400px;
      left: 620px;
    }
  }
}

//
// Slide 3 - Bluesteel Trigger
//
.slide-03 {
  .player {
    &.code {
      left: centerLeft(50px);
      top: centerTop(50px);
      width: 50px;
      height: 50px;
    }
    &.donut {
      top: 25%;
      left: 50%;
    }
    &.analyzing {
      left: 640px;
      top: 320px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

// Slide 4 - Endpoint Analysis
$col1: 140px;
$col2: 450px;
$col3: 670px;
$topOffset: 16px;
$leftOffset: -100px;
$topLineOffset: 0px;
.slide-04 {
  .player {
    &.folder {
      left: $col1 + $leftOffset;
      top: 360px + $topOffset;
    }
    &.process1 {
      left: $col2 + $leftOffset;
      top: 180px + $topOffset;
    }
    &.process2 {
      left: $col2 + $leftOffset;
      top: 270px + $topOffset;
    }
    &.process3 {
      left: $col2 + $leftOffset;
      top: 360px + $topOffset;
    }
    &.process4 {
      left: $col2 + $leftOffset;
      top: 450px + $topOffset;
    }
    &.process5 {
      left: $col2 + $leftOffset;
      top: 540px + $topOffset;
    }
    &.subProcess1 {
      left: $col3 + $leftOffset;
      top: 270px + $topOffset;
    }
    &.incident {
      left: 920px + $leftOffset;
      top: 360px + $leftOffset;
    }
    &.cmd {
      left: 920px + $leftOffset;
      top: 560px + $leftOffset;
    }
  }
  .connector-tree {
    // Payload activity slide
    &.slide-payload-activity {
      &.highlight-path {
        .connectors.left span {
          // Vertical lines
          &:nth-child(2) {
            top: 230px + $topLineOffset;
            left: 400px + $leftOffset;
            height: 182px;
            width: 2px;
          }
          &:nth-child(4) {
            opacity: 1;
            background: $blue;
          }
          &:nth-child(5) {
            opacity: 0;
          }
        }
        .connectors.right span {

        }
      }
      // Right
      .connectors.right span {
        &:nth-child(1) {
          width: 100px;
          left: 650px + $leftOffset;
          top: 230px + $topLineOffset;
        }
      }
    }
    // Path highlighted
    &.highlight-path {
      .connectors.left span {
        background: none;
        &:nth-child(2) {
          top: 320px + $topLineOffset;
          height: 92px;
        }
        &:nth-child(1), &:nth-child(2), &:nth-child(5) {
          background: $blue;
        }
      }
      .connectors.right span {
        background: none;
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          background: $blue;
        }
      }
    }
    // Connectors
    .connectors {
      span {
        position: absolute;
        display: block;
        background: $blue;
        height: 2px;
        @include transition(background 0.2s ease);
      }
      &.left {
        span {

          // Middle line
          &:nth-child(1) {
            width: 70px;
            top: 410px + $topLineOffset;
            left: 330px + $leftOffset;
          }

          // Vertical lines
          &:nth-child(2) {
            top: 230px + $topLineOffset;
            left: 400px + $leftOffset;
            height: 182px;
            width: 2px;
          }
          &:nth-child(3) {
            top: 410px + $topLineOffset;
            left: 400px + $leftOffset;
            height: 180px;
            width: 2px;
          }

          // Right lines
          &:nth-child(4) {
            width: 40px;
            left: 400px + $leftOffset;
            top: 230px + $topLineOffset;
          }
          &:nth-child(5) {
            width: 40px;
            left: 400px + $leftOffset;
            top: 320px + $topLineOffset;
          }
          &:nth-child(6) {
            width: 40px;
            left: 400px + $leftOffset;
            top: 410px + $topLineOffset;
          }
          &:nth-child(7) {
            width: 40px;
            left: 400px + $leftOffset;
            top: 500px + $topLineOffset;
          }
          &:nth-child(8) {
            width: 40px;
            left: 400px + $leftOffset;
            top: 590px + $topLineOffset;
          }
        }
      }
      &.right {
        span {
          // Middle line
          &:nth-child(1) {
            width: 70px;
            left: 590px + $leftOffset;
            top: 320px + $topLineOffset;
          }
        }
      }
    }
  }
}

//
// Slide 5 - Isolate Host
//
$dots: 8;
.slide-05 {
  .center-player {
    display: grid;
    grid-template-areas: "main";
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .player {
    &.computer {
      top: 378px;
      left: 525px;
    }
    &.dots {
      position: static !important;
      grid-area: main;
      .mask {
        position: relative;
        transform-origin: 50% 50%;
        border-radius: 50%;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        overflow: hidden;
        width: 600px;
        height: 600px;
        background: rgba($white, 0);
        border: 4px solid rgba($white, 0);
        @include transition('background 0.5s ease, border 0.5s ease');
        .dotContainer {
          position: absolute;
          width: 400px;
          height: 400px;
          top: 50%;
          left: 50%;
          transform-origin: 50% 50%;
          .player--dots {
            position: absolute;
          }
        }
        &.highlighted {
          background: rgba($white, 0.05);
          border: 4px solid rgba($white, 0.15);
        }
      }
      .player--dots {
        .player--dots-track {
          transform-origin: 0 0;
          left: 50px;
        }
        @for $i from 1 through $dots {
          &:nth-child(#{$i}) {
            $deg: #{$i * (360 / $dots)}deg;
            transform: rotate($deg);
          }
        }
      }
    }
  }
}

//
// Slide 6 - Network Containment
//
.slide-06 {
  .player {
    &.folder {
      left: 180px;
      top: 360px;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.payload {
      top: 290px;
      left: 450px;
    }
    &.results {
      top: 290px;
      left: 680px;
    }
  }
}

// Slide 7
.slide-07 {
  .center-player {
    display: grid;
    grid-template-areas: "main";
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .player {
    &.computer {
      top: 378px;
      left: 525px;
    }
    &.dots {
      position: static !important;
      grid-area: main;

      .circle {
        position: relative;
        transform-origin: 50% 50%;
        border-radius: 50%;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        overflow: hidden;
        width: 10px;
        height: 10px;
        background: rgba($white, 0.2);
        border: 4px solid rgba($white, 0.5);
        @include transition('background 0.5s ease, border 0.5s ease');
        &.highlighted {
          background: rgba($white, 0.05);
          border: 4px solid rgba($white, 0.15);
        }
      }

      .dotContainer {
        position: absolute;
        width: 400px;
        height: 400px;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        .player--dots {
          position: absolute;
        }
      }

      .player--dots {
        .player--dots-track {
          transform-origin: 0 0;
          left: 50px;
        }
        @for $i from 1 through $dots {
          &:nth-child(#{$i}) {
            $deg: #{$i * (360 / $dots)}deg;
            transform: rotate($deg);
          }
        }
      }
    }
  }
}

//
// Slide 08 - Global Blacklist (AMP) Containment
//
$topOffset: -40px;
.slide-08 {
  .player--textBlock {
    .player--textBlock-dots {
      .player--textBlock-dots {
        width: 60px;
      }
    }
  }
  .player {
    &.amp {
      left:  150px;
      top: 360px + $topOffset;
    }
    &.threat {
      left: 400px;
      top: 270px + $topOffset;
    }
    &.info {
      left: 700px;
      top: 270px + $topOffset;
    }
    &.client {
      left: 700px;
      top: 480px + $topOffset;
    }
  }
}

//
// Slide 9 - Alert Client
//
.slide-09 {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

//
// Slide 10 - Escalation Initiated
//
$topOffset: -20px;
$leftOffset: 140px;
.slide-10 {
  .player--textBlock {
    .player--textBlock-dots {
      .player--textBlock-dots {
        width: 60px;
      }
    }
  }
  .player {
    &.warning {
      left:  150px + $leftOffset;
      top: 360px + $topOffset;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.threat {
      left: 400px + $leftOffset;
      top: 270px + $topOffset;
    }
    &.info {
      left: 400px + $leftOffset;
      top: 390px + $topOffset;
    }
  }
}

//
// Slide 11 - Escalation with Client
//
$topOffset: 0;
.slide-11 {
  .player {
    &.analyst {
      left: (centerLeft(95px) - 200px);
      top: 320px + $topOffset;
    }
    &.client {
      left: (centerLeft(95px) + 200px);
      top: 320px + $topOffset;
    }
    &.phone {
      left: 535px;
      top: 375px + $topOffset;
      z-index: 2;
    }
    &.dots {
      opacity: 0.5 !important;
      left: centerLeft(200px);
      top: centerTop(4px);
    }
  }
}

//
// Slide 12 - Deep Dive Initial Follow-up
//
$topOffset: -40px;
.slide-12 {
  .player--textBlock {
    .player--textBlock-dots {
      .player--textBlock-dots {
        width: 60px;
      }
    }
  }
  .player {
    &.client {
      left:  270px;
      top: 410px + $topOffset;
    }
    &.request {
      left: 580px;
      top: 320px + $topOffset;
    }
  }
}

//
// Slide 13 - Access Investigation - Process Analysis
//
$leftOffset: 110px;
$topOffset: 0px;
.slide-13 {
  .player {
    &.local {
      left: 600px + $leftOffset;
      top: 300px + $topOffset;
    }
    &.remote {
      left: 600px + $leftOffset;
      top: 460px + $topOffset;
    }
    &.process1 {
      left: 100px + $leftOffset;
      top: 400px + $topOffset;
    }
    &.process2 {
      left: 250px + $leftOffset;
      top: 400px + $topOffset;
    }
    &.process3 {
      left: 400px + $leftOffset;
      top: 400px + $topOffset;
    }
    &.process1, &.process2 {
      &:after {
        position: absolute;
        display: block;
        content: '';
        background: $blue;
        width: 60px;
        height: 2px;
        top: calc(50% - 1px);
        left: calc(100% + 10px);
      }
    }
  }
}

//
// Slide 14 - Access Investigation - Host Access Vector
//
$leftOffset: 110px;
$topOffset: 0px;
.slide-14 {
  .player {
    &.sensor {
      left: 400px;
      top: 270px;
    }
    &.host {
      left: 720px;
      top: 270px;
    }
    &.vpn {
      left:  100px;
      top: 350px;
    }
  }
}

//
// Slide 15 - Access Investigation - VPN Login Source
//
$topOffset: -20px;
.slide-15 {
  .player {
    &.lock {
      left: 320px;
      top: 360px + $topOffset;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.vpn {
      left: 580px;
      top: 280px + $topOffset;
    }
  }
}

//
// Slide 16 - Access Investigation - Credentials Used
//
$topOffset: -20px;
.slide-16 {
  .player {
    &.lock {
      left: 320px;
      top: 360px + $topOffset;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.vpn {
      left: 580px;
      top: 280px + $topOffset;
    }
    &.login {
      left: 580px;
      top: 440px + $topOffset;
    }
  }
}

//
// Slide 17 - Access Investigation Network Containment
//
$dots: 8;
.slide-17 {
  .center-player {
    display: grid;
    grid-template-areas: "main";
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .player {
    &.computer {
      top: 378px;
      left: 525px;
    }
    &.dots {
      position: static !important;
      grid-area: main;
      .mask {
        position: relative;
        transform-origin: 50% 50%;
        border-radius: 50%;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        overflow: hidden;
        width: 600px;
        height: 600px;
        background: rgba($white, 0);
        border: 4px solid rgba($white, 0);
        @include transition('background 0.5s ease, border 0.5s ease');
        .dotContainer {
          position: absolute;
          width: 400px;
          height: 400px;
          top: 50%;
          left: 50%;
          transform-origin: 50% 50%;
          .player--dots {
            position: absolute;
          }
        }
        &.highlighted {
          background: rgba($white, 0.05);
          border: 4px solid rgba($white, 0.15);
        }
      }
      .player--dots {
        .player--dots-track {
          transform-origin: 0 0;
          left: 50px;
        }
        @for $i from 1 through $dots {
          &:nth-child(#{$i}) {
            $deg: #{$i * (360 / $dots)}deg;
            transform: rotate($deg);
          }
        }
      }
    }
  }
}

//
// Slide 18 - Access Investigation Follow-up
//
.slide-18 {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

//
// Slide 19 - Post Infection Activities Investigation - Process Analysis
//
$leftOffset: 200px;
.slide-19 {
  .player {
    &.netconn {
      left: 460px + $leftOffset;
      top: 340px;
    }
    &.process1 {
      left: 90px + $leftOffset;
      top: 180px;
    }
    &.process2 {
      left: 90px + $leftOffset;
      top: 280px;
    }
    &.process3 {
      left: 90px + $leftOffset;
      top: 380px;
    }
    &.process4 {
      left: 90px + $leftOffset;
      top: 480px;
    }
    &.process5 {
      left: 90px + $leftOffset;
      top: 580px;
    }
    &.process {
      width: 70px;
      .player--dots {
        position: absolute;
        left: 100%;
        top: 50%;
        width: $trackWidth;
        transform-origin: 0% 0;
        transition: opacity 0.5s ease;
        .player--dots-track {
          width: $trackWidth;
        }
      }
    }
  }
}

//
// Slide 20 - Post Infection Activities Investigation - Packet Review
//
.slide-20 {
  .player {
    &.drive {
      top: centerTop(70px);
      left: centerLeft(70px);
    }
    &.dots-left {
      top: centerTop(4px);
      left: centerLeft(160px) - 180px;
    }
    &.dots-right {
      top: centerTop(4px);
      left: centerLeft(160px) + 140px;
    }
    &.computer-left {
      top: centerTop(160px);
      left: centerLeft(120px) - 350px;
    }
    &.computer-right {
      top: centerTop(160px);
      left: centerLeft(120px) + 350px;
    }
  }
}

//
// Slide 21 - Post Infection Activities Investigation - Post Requests
//
.slide-21 {
  .player {
    &.packet {
      left: 460px;
      top: 260px;
    }
    &.data {
      left: 460px;
      top: 410px;
    }
    &.data-left {
      position: absolute;
      width: calc(50% - 150px);
      top: 50%;
      left: 40px;
      height: 12px;
    }
  }
}

//
// Slide 22 - Post Infection Activities Investigation - File Extraction
//
@keyframes revealPage {
  0% {
    height: 0px;
  }
  100% {
    height: 60px;
  }
}
.slide-22 {
  .player {
    &.donut {
      @include DonutCenter();
    }
    &.binary {
      left:  500px;
      top: 360px;
    }
    &.extracting {
      left: 800px;
      top: 340px;
    }
    &.pages {
      width: 140px;
      height: 140px;
      left: centerLeft(140px);
      top: centerTop(140px);
      &[data-active="1"] {
        $delay: 400ms;
        @for $i from 1 through 6 {
          .page:nth-child(#{$i}) {
            animation: revealPage 1.0s ease;
            animation-delay: $i * $delay;
            animation-fill-mode: forwards;
          }
        }
      }
      .page {
        position: absolute;
        overflow: hidden;
        height: 0px;
        width: 60px;
        &:nth-child(1) {
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          left: 50px;
          top: 0;
        }
        &:nth-child(3) {
          left: 100px;
          top: 0;
        }
        &:nth-child(4) {
          left: 0;
          top: 60px;
        }
        &:nth-child(5) {
          left: 50px;
          top: 60px;
        }
        &:nth-child(6) {
          left: 100px;
          top: 60px;
        }
      }
    }
  }
}

//
// Slide 23 - Post Infection Activities Investigation - Extracted File Review
//
$topOffset: 0;
.slide-23 {
  .player {
    &.lock {
      left: 320px;
      top: 360px + $topOffset;
      .player--circleIcon-circle {
        width: 100px;
        height: 100px;
        border-width: 2px;
      }
    }
    &.filename {
      left: 580px;
      top: 280px + $topOffset;
    }
    &.data {
      left: 580px;
      top: 400px + $topOffset;
    }
    &.pages {
      width: 140px;
      height: 140px;
      left: centerLeft(140px);
      top: centerTop(140px);
      .page {
        position: absolute;
        height: 60px;
        width: 60px;
        &:nth-child(1) {
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          left: 50px;
          top: 0;
        }
        &:nth-child(3) {
          left: 100px;
          top: 0;
        }
        &:nth-child(4) {
          left: 0;
          top: 60px;
        }
        &:nth-child(5) {
          left: 50px;
          top: 60px;
        }
        &:nth-child(6) {
          left: 100px;
          top: 60px;
        }
      }
    }
  }
}

//
// Slide 24 - Post Infection Activities Investigation - Network Containment
//
$leftOffset: -40px;
$topOffset: 0;
$trackWidth: 330px;
.slide-24 {
  .player {
    &.computer {
      left: 160px + $leftOffset;
      top: 300px + $topOffset;
    }
    &.dots {
      left: 370px + $leftOffset;
      top: 395px + $topOffset;
      .player--dots-track {
        width: 150px !important;
      }
    }
    &.disk {
      opacity: 0.5 !important;
      left: 540px + $leftOffset;
      top: 360px + $topOffset;
    }
    &.badge {
      .player--dots {
        position: absolute;
        right: 100%;
        top: 50%;
        width: $trackWidth;
        transform-origin: 100% 0;
        transition: opacity 0.5s ease;
        .player--dots-track {
          width: $trackWidth;
        }
      }
      &.dimmed {
        .player--dots {
          opacity: 0;
        }
      }
    }
    &.badge1 {
      left: 890px;
      top: 180px;
    }
    &.badge2 {
      left: 890px;
      top: 280px;
    }
    &.badge3 {
      left: 890px;
      top: 380px;
    }
    &.badge4 {
      left: 890px;
      top: 480px;
    }
    &.badge5 {
      left: 890px;
      top: 580px;
    }
  }
}

//
// Slide 25 - Post Infection Activities Investigation Follow-up
//
.slide-25 {
  .player {
    &.client {
      @include CardCenter();
    }
    &.email {
      top: 380px;
      left: 450px;
    }
    &.alert {
      @include AlertCenter();
    }
  }
}

// End use case wrapper
}
