@import '../base';

.Player__Signals {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: rgba($white, 0.05);
  border: 4px solid rgba($blue, 0.1);
  position: relative;
  overflow: hidden;
  .Player__Path {
    position: absolute;
  }
  &.four {
    .Player__Signals__Signal {
      &:nth-child(1) {
        left: 140px;
        top: 20px;
        .Player__Path {
          left: 51px;
          top: 55px;
        }
      }
      &:nth-child(2) {
        left: 80px;
        top: 80px;
        .Player__Path {
          left: 60px;
          top: 35px;
        }
      }
      &:nth-child(3) {
        left: 80px;
        top: 160px;
        .Player__Path {
          left: 60px;
          top: -5px;
        }
      }
      &:nth-child(4) {
        left: 140px;
        top: 220px;
        .Player__Path {
          left: 51px;
          top: -55px;
        }
      }
    }
  }
  &.three {
    .Player__Signals__Signal {
      &:nth-child(1) {
        left: 120px;
        top: 30px;
        .Player__Path {
          left: 55px;
          top: 45px;
        }
      }
      &:nth-child(2) {
        left: 120px;
        top: 120px;
        .Player__Path {
          left: 60px;
          top: 23px;
        }
      }
      &:nth-child(3) {
        left: 120px;
        top: 210px;
        .Player__Path {
          left: 55px;
          top: -48px;
        }
      }
    }
  }
  &__Signal {
    position: absolute;
    label {
      position: absolute;
      color: $white;
      text-transform: uppercase;
      height: 20px;
      right: calc(100% + 10px);
      top: 25px;
      font-size: 11px;
      letter-spacing: 1px;
    }
  }
}
