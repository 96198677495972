@import '../components/base';

.Template__TeamIdentifiesExploit {
  .player {
    &.team {
      left: 191px;
      top: 353px;
    }
    &.scanner {
      left: 446px;
      top: 317px;
      width: 220px;
      height: 200px;
      border: 4px solid $blue;
      box-sizing: border-box;
      .Player__DataScan {
        position: absolute;
        left: 16px;
        top: 16px;
      }
    }
    &.reveal {
      left: 817px;
      top: 338px;
    }
    &.path1 {
      left: 296px;
      top: 361px;
    }
    &.path2 {
      left: 296px;
      top: 421px;
    }
    &.path3 {
      left: 666px;
      top: 361px;
    }
    &.path4 {
      left: 666px;
      top: 421px;
    }
  }
}
