@import '../../base';

.KillchainOutcomes {
  grid-area: main;

  margin-top: 50px;
  font-size: 14px;
  line-height: 14px;
  overflow: hidden;
  overflow-y: scroll;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  display: grid;
  grid-template:
    'header'
    'content';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;

  &__Header {
    grid-area: header;

    margin: 10px 60px 0 60px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
  }

  &__Back {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba($white, 0.5);
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      color: $white;

      svg g {
        opacity: 1;
      }
    }

    svg {
      margin-right: 15px;
    }
  }

  &__Section {
    &:last-child {
      .KillchainOutcomes__SectionDivider {
        border: none;
      }
    }

    h2 {
      margin: 0;
      font-family: $futuraBold;
      font-size: 24px;
      line-height: 112%;
    }

    p {
      font-size: 14px;
      line-height: 160%;
      color: #9ea1aa;
    }

    ul {
      margin: 20px 0 0 0;
      padding: 20px 0 0 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      li {
        font-family: $blenderThin;
        font-size: 16px;
        line-height: 144%;
      }

      ul {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
        list-style-type: '– ';
      }
    }
  }

  &__OutcomeSection {
    margin: 0 60px 0 60px;
    padding-top: 40px;
    padding-bottom: 40px;

    display: flex;
    flex-direction: row;
  }

  &__White {
    flex: 0 1 100%;
    border: 4px solid #ffffff;
    padding: 30px;
  }

  &__Arrow {
    flex: 0 0 85px;

    display: grid;
    justify-items: center;
    margin: 20px 10px 0 10px;
  }

  &__Orange {
    flex: 0 1 100%;
    border: 4px solid #f05938;
    padding: 30px;

    h2 {
      color: #f05938;
    }
  }

  &__SectionDivider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0 60px;
  }

  &__CoverageSection {
    padding: 0 60px 0 60px;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    background: darken(#262b40, 10%);
  }

  &__CoverageTitle {
    font-family: $futuraBold;
    font-size: 24px;
    line-height: 112%;
  }

  &__CoverageSubtitle {
    font-family: $blenderThin;
    font-size: 16px;
    line-height: 144%;
    margin-bottom: 30px;
  }

  &__CoverageGrid {
    margin: 0 auto;
    max-width: 550px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: repeat(12, 40px);
    grid-gap: 5px;
  }

  &__CoverageHeader {
    background: #9ea1ab;
    font-family: $futuraBold;
    font-size: 15px;
    line-height: 19px;
    text-transform: uppercase;
    color: #262b40;

    display: grid;
    align-items: center;
    justify-items: center;
  }

  &__CoverageLabel {
    background: rgba(158, 161, 171, 0.1);
    line-height: 40px;
    text-align: center;
  }

  &__CoverageValue {
    background: rgba(230, 232, 230, 0.15);

    display: grid;
    align-items: center;
    justify-items: center;
  }
}
