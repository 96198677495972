@import '../base';

@include keyframes(ComputerOut) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.Player__NetworkScan {
  $self: &; // Myself
  border: 4px solid rgba($white, 0.2);
  padding: 20px; // Set in component
  box-sizing: border-box;
  &__Computers {
    width: 100%;
    display: grid;
    gap: 10px; // Set in component
    #{ $self }__Computer {
      display: block;
      text-align: center;
      opacity: 0.2;
      svg path {
        transition: fill 0.5s ease;
      }
      &.on {
        animation: ComputerOut 0.5s linear;
      }
      &.remediated {
        opacity: 1;
        svg path {
          fill: $green;
        }
      }
      &.vulnerable {
        opacity: 1;
        svg path {
          fill: $red;
        }
      }
    }
  }
}
