@import '../components/base';

.Template__TeamScanningCustomers {
  .player {
    &.team {
      left: 211px;
      top: 331px;
    }
    &.mdr {
      left: 496px;
      top: 336px;
    }
    &.customers {
      left: 758px;
      top: 312px;
    }
    &.path {
      left: 316px;
      top: 394px;
    }
    &.paths {
      left: 580px;
      top: 272px;
      .Player__Path {
        position: absolute;
        &:nth-child(1) {
          left: 0px;
          top: 0px;
        }
        &:nth-child(2) {
          left: 16px;
          top: 10px;
        }
        &:nth-child(3) {
          left: 25px;
          top: 20px;
        }
        &:nth-child(4) {
          left: 31px;
          top: 30px;
        }
        &:nth-child(5) {
          left: 34px;
          top: 70px;
        }
        &:nth-child(6) {
          left: 36px;
          top: 105px;
        }
        &:nth-child(7) {
          left: 36px;
          top: 127px;
        }
        &:nth-child(8) {
          left: 34px;
          top: 137px;
        }
        &:nth-child(9) {
          left: 31px;
          top: 147px;
        }
        &:nth-child(10) {
          left: 25px;
          top: 157px;
        }
        &:nth-child(11) {
          left: 16px;
          top: 167px;
        }
        &:nth-child(12) {
          left: 0px;
          top: 177px;
        }
      }
    }
  }
}
