@import '../base';

.PopupsTab {
  h2 {
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: 3px solid $blue;
  }

  &__Popups {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    grid-gap: 80px;
    padding: 30px;
    align-items: center;
    justify-items: center;
  }

  &__Modals {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;

    & > button {
      margin-left: 30px;
    }
  }
}
