@import '../base';

$series: 5;

@keyframes animateColumn {
  0% {
    height: 0%;
  }
}

.player--chart {
  height: 400px;
  width: 560px;
  .chart-series {
    display: flex;
    flex-direction: row;
    height: 300px;
    width: 100%;
    .series-data {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      height: 300px;
      box-sizing: border-box;
      margin: 0 20px;
      div {
        flex: 1;
        margin: 3px;
        bottom: 0;
        left: 0;
        width: 25%;
        border-radius: 4px;
        animation: animateColumn 2s ease;
        &.blue {
          background: $blue;
        }
        &.red {
          background: $red;
        }
      }
      label {
        display: block;
        position: absolute;
        text-transform: uppercase;
        height: 60px;
        line-height: 60px;
        top: 100%;
        left: 0;
        width: 100%;
        color: $white;
        font-size: 14px;
        font-family: $futuraBold;
        text-align: center;
      }
    }
  }
  .series-legend {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    span {
      display: inline-block;
      position: relative;
      margin: 0 20px;
      font-size: 14px;
      text-transform: uppercase;
      font-family: $futuraBold;
      &:before {
        display: block;
        position: absolute;
        content: '';
        right: calc(100% + 10px);
        top: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      &.blue {
        &:before {
          background: $blue;
        }
      }
      &.red {
        &:before {
          background: $red;
        }
      }
    }
  }
}
