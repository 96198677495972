@import 'base';

.Select {
  outline: none;

  display: grid;
  width: auto;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  align-items: center;
  justify-items: left;

  &__Caption {
    position: relative;
    font-family: $futuraBold;
    font-size: 11px;
    text-transform: uppercase;
  }

  &__Label {
    position: relative;
    cursor: pointer;
    width: 100%;
    outline: none;
    border: 1px solid rgba($white, 0.2);
    border-radius: 4px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    align-items: center;
    justify-items: left;

    select {
      width: 100%;
      text-transform: capitalize;
      background: transparent;
      color: rgba($white, 0.5);
      border: none;
      font-size: 14px;
      z-index: 2;
      font-family: $blenderBold;
      height: 35px;
      line-height: 35px;
      padding: 0 25px 0 10px;
      outline: none;
      box-sizing: border-box;

      // Remove the arrows
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    &__Arrow {
      position: absolute;
      right: 7px;
      top: 11px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      z-index: 1;
    }
  }
}
