@import 'base';

.SlideShow {
  display: grid;
  grid-template-areas:
    'main'
    'bottom';
  grid-template-rows: 1fr auto;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: hidden;

  .TopMenu {
    grid-area: main;
  }
  .BottomNav {
    grid-area: bottom;
  }
  > * {
    grid-area: main;
  }
}
