@import '../../base';

.KillchainOverview {
  display: grid;
  grid-template-areas:
    'services response response response detection detection awareness'
    'cell11   cell12   cell13   cell14   cell15    cell16    cell17   '
    'cell21   cell22   cell23   cell24   cell25    cell26    cell27   '
    'cell31   cell32   cell33   cell34   cell35    cell36    cell37   '
    'cell41   cell42   cell43   cell44   cell45    varies    adds     '
    'cell51   cell52   cell53   cell54   cell55    varies    adds     '
    'cell61   cell62   cell63   cell64   cell65    varies    adds     ';
  grid-template-columns: 150px repeat(6, 1fr);
  grid-template-rows: 80px repeat(2, 2fr) repeat(15, 1fr);
  grid-gap: 5px;

  padding: 0 0 15px 5px;
  text-align: center;
  font-family: $blenderBold;

  .text-muted {
    opacity: 0.5;
  }

  .text-red {
    color: rgb(169, 0, 0);
  }

  &__Cell {
    display: grid;
    align-items: center;
    justify-items: center;

    font-size: 13px;

    transition: background-color 0.25s;

    &--blue-1 {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5)
        ),
        #173b72;
    }

    &--blue-2 {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0.4)
        ),
        #173b72;
    }

    &--blue-3 {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.3),
          rgba(255, 255, 255, 0.3)
        ),
        #173b72;
    }

    &--blue-4 {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2)
        ),
        #173b72;
    }

    &--blue-5 {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1)
        ),
        #173b72;
    }

    &--blue-6 {
      background: #173b72;
    }

    &--grey-1 {
      background-color: #575c6b;
    }

    &--grey-2 {
      background-color: rgba(230, 232, 230, 0.15);
    }

    &--grey-3 {
      background-color: rgba(158, 161, 171, 0.1);
    }

    &--opacity {
      opacity: 0.5;
    }
  }

  &__Services {
    grid-area: services;

    background-color: #36a170;
    font-size: 14px;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: darken(#36a170, 10%);
    }
  }

  &__ServicesMarker {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 5px;
    width: 5px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
  }

  &__ServicesIndicator {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 8px;
    width: 8px;
    background: #a90000;
    border: 1px solid #ffffff;
    border-radius: 50%;
  }

  &__Detection,
  &__Response,
  &__Awareness {
    display: grid;
    grid-template:
      'header  ' 1fr
      'products' 2fr /
      1fr;
  }

  &__Response {
    grid-area: response;

    .KillchainOverview__CellHeader {
      background-color: #0085c9;
    }
  }

  &__Detection {
    grid-area: detection;

    .KillchainOverview__CellHeader {
      background-color: rgba(0, 133, 201, 0.8);
    }
  }

  &__Awareness {
    grid-area: awareness;

    .KillchainOverview__CellHeader {
      background-color: rgba(8, 115, 174, 0.6);
    }
  }

  &__CellHeader {
    display: grid;
    align-items: center;
    justify-items: center;

    font-size: 16px;
    text-transform: uppercase;
  }

  &__CellProducts {
    grid-area: products;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 5px;

    & > * {
      display: grid;
      align-items: center;
      justify-items: center;

      background-color: #000000;
      font-size: 14px;

      transition: background-color 0.25s;
    }
  }

  &__Varies {
    grid-area: varies;

    background: linear-gradient(180deg, #7489aa 23.36%, #45628e 81.31%);
  }

  &__Adds {
    grid-area: adds;

    background: linear-gradient(180deg, #7489aa 23.36%, #45628e 81.31%);
  }

  &--no-esnetwork {
    background: rgb(169, 0, 0);
  }

  &--no-esendpoint {
    background: rgb(169, 0, 0);
  }

  &--no-mvs {
    background: rgb(169, 0, 0);
  }

  &--no-eslog {
    background: rgb(169, 0, 0);
  }

  &--no-prisma {
    background: rgb(169, 0, 0);
  }
}

@media (max-height: 750px) {
  .KillchainOverview {
    grid-template-rows: 60px repeat(17, 1fr);

    &__Cell {
      font-size: 10px;
    }

    &__Services {
      font-size: 12px;
    }

    &__CellHeader {
      font-size: 12px;
    }

    &__CellProducts {
      & > * {
        font-size: 10px;
      }
    }
  }
}
