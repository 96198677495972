@import './_base.scss';

.EmailDemo {
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: $z-slide-show-popup-image;

  font-family: IBM Plex Sans;
  color: rgba(255, 255, 255, 0.8);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.03em;

  &__Email {
    flex: 0 1 auto;
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    background: #313538;
    border-radius: 8px;
    overflow: hidden;
  }

  &__Content {
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    padding: 0px 32px 24px 32px;
  }

  &__Title {
    flex: 0 1 40px;
    width: 100%;
    height: 40px;
    max-height: 40px;
    display: flex;
    flex-direction: row;
    padding: 14px 16px;
    background: #3e4246;
    margin-bottom: 24px;
    box-sizing: border-box;
    align-items: center;
  }

  &__Dot {
    flex: 0 1 auto;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 6px;
    background: rgba(0, 0, 0, 0.2);
  }

  &__TitleLabel {
    flex: 1 1 auto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 100%;
    margin-left: 6px;
  }

  p {
    flex: 0 1 auto;
    max-width: 1180px;
    display: flex;
    margin: 0px 0px 24px 0px;
    width: 100%;
    white-space: break-spaces;

    &:last-child {
      margin: 0px;
    }
  }

  &__Table {
    flex: 0 1 auto;
    max-width: 1180px;
    display: flex;
    flex-direction: column;
    padding: 2px;
    width: 100%;
    margin: 0px 0px 24px 0px;
  }

  &__Row {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }

  &__RowLeft {
    flex: 1 0 232px;
    display: flex;
    width: 232px;
    max-width: 232px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    padding-left: 6px;
    min-height: 30px;
    line-height: 18px;
  }

  &__RowRight {
    white-space: break-spaces;
    box-sizing: border-box;
    margin-left: 0px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding-left: 6px;
    height: 30px;
    min-height: 30px;
    font-weight: 200;
    line-height: 18px;
    height: 100%;
  }

  &--dark {
    min-height: 18px;
    background: rgba(255, 255, 255, 0.1);
  }
}
