@import '../base';

.AssetChart {
  .ChartShell__BottomSection {
    color: rgba(255, 255, 255, 0.6);
  }

  &__Tooltip {
    padding: 15px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    &__Industry {
      font-family: $futuraBold;
      font-size: 12px;
      text-transform: uppercase;
    }

    &__AttackType {
      font-family: $blenderThin;
      font-size: 12px;
    }

    &__Percent {
      font-family: $blenderThin;
      font-size: 40px;
      text-transform: uppercase;
    }
  }
}
