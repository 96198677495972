@import '../base';
@import '../animations';

@include keyframes(sectionIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

$lineDelay: 50ms;
$maxLines: 50;

// grid layout
.player--textBlock {
  display: grid;
  grid-template-areas:
    'top'
    'box'
    'sections';
  &.icon-layout {
    grid-template-areas:
      'action action'
      'icon box'
      'icon top'
      'icon sections';
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto;
    &.action-only {
      grid-template-areas:
        'icon top'
        'icon box'
        'icon action'
        'icon sections';
    }
  }
  &.action-only {
    grid-template-areas:
      'top'
      'action'
      'sections';
  }
  .player--textBlock-action {
    grid-area: action;
  }
  .player--textBlock-top-bar {
    grid-area: top;
  }
  .player--textBlock-icon {
    grid-area: icon;
  }
  .player--textBlock-sections {
    grid-area: sections;
  }
}

@include keyframes(greenBox) {
  0% {
    background: rgba($green, 1);
    color: $white;
  }
  100% {
    background: rgba($green, 0);
    color: $green;
  }
}

@include keyframes(orangeBox) {
  0% {
    background: rgba($orange, 1);
    color: $white;
  }
  100% {
    background: rgba($orange, 0);
    color: $orange;
  }
}

// Animate section when active
.player[data-active='1'] {
  .player--textBlock-section {
    &.animate-in {
      opacity: 0;
      @include animation(sectionIn 0.5s ease);
      animation-fill-mode: forwards;
      .player--textBlock-line {
        @for $i from 1 through $maxLines {
          &:nth-child(#{$i}) {
            @include animation(shimmerIn 0.2s ease);
            animation-delay: $i * $lineDelay;
          }
        }
      }
    }
  }
  .player--textBlock {
    &.box-green {
      .player--textBlock-box {
        span {
          @include animation(greenBox 1s ease);
          border-color: $green;
          color: $green;
        }
      }
    }
  }
  .player--textBlock {
    &.box-orange {
      .player--textBlock-box {
        span {
          @include animation(orangeBox 1s ease);
          border-color: $orange;
          color: $orange;
        }
      }
    }
  }
}

.player--textBlock {
  width: 200px;
  position: relative;
  box-sizing: border-box;
  &.action-only {
    .player--textBlock-action {
      font-size: 30px;
      color: $white;
      text-transform: uppercase;
      white-space: nowrap;
    }
    &.wrap {
      .player--textBlock-action {
        white-space: normal;
        word-wrap: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
      }
    }
  }
  &.red-top {
    .player--textBlock-top-bar {
      border-color: $red;
      color: $red;
      &:before {
        background: $red;
      }
    }
  }
  &.green-top {
    .player--textBlock-top-bar {
      border-color: $green;
      color: $green;
      &:before {
        background: $green;
      }
    }
  }
  &.orange-top {
    .player--textBlock-top-bar {
      border-color: $orange;
      color: $orange;
      &:before {
        background: $orange;
      }
    }
  }
  &.section-grid {
    .player--textBlock-sections {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-gap: 10px;
      margin-top: 10px;
      .player--textBlock-section,
      .player--textBlock-section-lines {
        margin-top: 0 !important;
      }
    }
  }
  .player--textBlock-timeline {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1.5fr 4fr 6fr;
    width: calc(100% + 20px);
    height: 14px;
    margin: 10px 0 0 0;
    span {
      display: block;
      height: 14px;
      background: rgba($white, 0.2);
      margin-left: 1px;
      &:first-child {
        border-radius: 14px 0 0 0;
      }
      &:last-child {
        border-radius: 0 0 14px 0;
      }
    }
  }
  .player--textBlock-action {
    font-size: 20px;
    color: $blue;
    font-family: 'Blender Thin';
    padding: 0 0 20px 0;
    letter-spacing: 2px;
  }
  .player--textBlock-icon {
    width: 60px;
    height: 80px;
    box-sizing: border-box;
    border: 4px solid rgba($white, 0.1);
    display: grid;
    justify-content: center;
    align-content: center;
  }
  .player--textBlock-top-bar {
    position: relative;
    width: 100%;
    max-width: 200px;
    border-top: 3px solid $blue;
    padding: 10px 0 10px 20px;
    font-family: 'Blender Heavy';
    text-transform: uppercase;
    letter-spacing: 0.2px;
    color: $blue;
    font-size: 14px;
    box-sizing: border-box;
    transition: all 0.2s ease;
    &:before {
      display: block;
      content: '';
      background: $blue;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 13px;
      transition: all 0.2s ease;
    }
    .player--textBlock-dots {
      position: absolute;
      transform-origin: 100% 0%;
      top: 18px;
      right: calc(100% + 20px);
      transform: rotate(0deg);
      display: block;
      height: 4px;
      width: 120px;
      background: linear-gradient(
        90deg,
        rgba($white, 0.5) 50%,
        transparent 50%
      );
      background-repeat: repeat-x;
      background-size: 10px 4px;
      background-position: 0 0;
      @include animation(dottedLinesReverse 5s infinite linear);
      .player--textBlock-dots {
        position: absolute;
        transform: rotate(-55deg);
        right: 100%;
        top: 0;
        width: 60px;
      }
    }
  }
  &.box-green {
    .player--textBlock-box {
      span {
        border-color: $green;
        color: $green;
      }
    }
  }
  &.box-orange {
    .player--textBlock-box {
      span {
        border-color: $orange;
        color: $orange;
      }
    }
  }
  .player--textBlock-box {
    padding: 0 0 20px 0;
    span {
      display: inline-block;
      border: 2px solid $white;
      font-size: 16px;
      color: $white;
      font-family: 'Blender Heavy';
      text-transform: uppercase;
      line-height: 16px;
      padding: 8px;
    }
  }
  .player--textBlock-section + .player--textBlock-section {
    margin-top: 0px;
    .player--textBlock-section-title {
      margin-top: 10px;
    }
  }
  .player--textBlock-section {
    margin-top: 15px;
    &.compact {
      margin-top: 0px;
      .player--textBlock-section-title {
        margin-top: 0px;
      }
    }
    &.column-mode {
      display: flex;
      // padding-top: 10px;
      .player--textBlock-section-title {
        flex: 1;
        padding: 0;
      }
      .player--textBlock-section-lines {
        flex: 10;
        padding: 0;
        margin-left: 10px;
      }
    }
    .player--textBlock-section-title {
      padding: 0;
      &.multiple {
        display: grid !important;
      }
      &.action {
        div {
          white-space: normal;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 34px;
          font-size: 38px;
          color: $white;
          font-family: 'Blender Thin';
          padding: 0 0 20px 0;
          letter-spacing: 0px;
          text-transform: none;
          background: transparent;
        }
      }
      div {
        display: block;
        margin: 7px 7px 0 0;
        &.blue {
          background: $blue;
          color: $white;
        }
        &.red {
          background: $red;
          color: $white;
        }
        &.orange {
          background: $orange;
          color: $white;
        }
        &.green {
          background: $green;
          color: $white;
        }
      }
      &.red-box {
        div {
          border: 2px solid $red;
          background: transparent;
          color: $red;
        }
      }
      &.multiline {
        span,
        div {
          white-space: normal;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
        }
      }
      &.normal-text {
        span,
        div {
          text-transform: none;
          font-family: $blenderThin;
        }
      }
      &.link {
        span,
        div {
          color: $blue;
          font-weight: 400;
        }
      }
      span,
      div {
        display: inline-block;
        background: rgba($white, 0.2);
        color: $white;
        font-family: 'Blender Bold';
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        padding: 8px;
        white-space: nowrap;
        margin-right: 7px;
        .Icon__SkullScore {
          margin-bottom: -6px;
        }
      }
    }
    .player--textBlock-section-lines {
      width: 100%;
      margin-top: 10px;
      .player--textBlock-line {
        display: flex;
        flex-direction: row;
        margin-bottom: 7px;
        span {
          flex: 1;
          display: block;
          height: 7px;
          background: rgba($white, 0.2);
          &:nth-child(n + 2) {
            margin-left: 7px;
          }
        }
        &:last-child {
          width: 70%;
        }
      }
    }
  }
}
