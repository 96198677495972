/*

RANSOMWARE

*/
@import "../base";

//
// Players that often have the same starting position
//

$dots: 6;
$duration: 3s;
$size: 6px;
$delay: $duration / $dots;

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

$leftStart: 40px;
$rightStart: 590px;
$width: 480px;
$antsStart: -35px;
.log4j {
  // Slide 0 - Narrative
  .slide-01 {
    // Information
    .player {
      .StoryBlock__Title {
        margin: 0 0 20px 0;
      }
      &.block1 {
        width: $width;
        left: 46px;
        top: 180px;
      }
      &.block2 {
        width: $width;
        left: 46px;
        top: 388px;
      }
      &.block3 {
        width: $width;
        left: 588px;
        top: 240px;
      }
      &.block4 {
        width: $width;
        left: 588px;
        top: 490px;
      }
      &.line1 {
        left: 286px;
        top: 348px;
      }
      &.line2 {
        left: 287px;
        top: 200px;
      }
      &.line3 {
        left: 828px;
        top: 450px;
      }
    }
  }

  // Slide 1 - Cards
  $leftOffset: -25px;
  .slide-02 {
    .player {
      &.card1 {
        top: 240px;
        left: 120px + $leftOffset;
      }
      &.card2 {
        top: 240px;
        left: 360px + $leftOffset;
      }
      &.card3 {
        top: 240px;
        left: 600px + $leftOffset;
      }
      &.card4 {
        top: 240px;
        left: 840px + $leftOffset;
      }
    }
  }

  .slide-03 {
    .player {
      &.card {
        left: 176px;
        top: 328px;
      }
      &.info {
        left: 396px;
        top: 240px;
      }
      &.reveal {
        left: 806px;
        top: 328px;
      }
      &.path1 {
        left: 296px;
        top: 260px;
      }
      &.path2 {
        left: 635px;
        top: 331px;
      }
      &.path3 {
        left: 595px;
        top: 369px;
      }
      &.path4 {
        left: 586px;
        top: 407px;
      }
    }
  }

}