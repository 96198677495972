@import '../components/base';

.Template__SignalsThreatHunt {
  .player {
    &.customers {
      left: 136px;
      top: 335px;
    }
    &.signals {
      left: 366px;
      top: 270px;
    }
    &.greynoise {
      left: 728px;
      top: 358px;
    }
    &.amp {
      left: 887px;
      top: 358px;
    }
    &.path1 {
      left: 315px;
      top: 370px;
    }
    &.path2 {
      left: 673px;
      top: 418px;
    }
    &.path3 {
      left: 833px;
      top: 418px;
    }
  }
}
