@import 'base';

.KeyValueSet {
  display: flex;
  flex-direction: row;
  float: right;
  outline: none;

  &__Key {
    font-size: 14px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
    text-align: right;
    text-transform: uppercase;
  }

  &__Value {
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    color: $blue;
  }

  &__Plus {
    font-size: 24px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    margin: 10px 10px 0 10px;
  }

  input {
    display: block;
    font-size: 24px;
    line-height: 24px;
    background: rgba(0, 0, 0, 0);
    border: none;
    height: 24px;
    color: $blue;
    text-align: right;
    font-family: $blenderBold;
    padding: 0;
    margin: 0;
    width: 100%;
    outline: 0;

    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}
