$white: #ffffff;
$blue: #50C2E3;
$red: #C53D43;
$green: #54D5AE;
$grey: #AAA;
$grey-dark: #3A3A3A;
$grey-xdark: #1F2224;
$orange: #F36C21;
$black: #151719;
$popup: #282D30;
$yellow: #ffe600;

@function centerLeft($w) {
  $slideWidth: 1112px;
  @return ($slideWidth / 2) - ($w / 2);
}

@function centerTop($h) {
  $slideHeight: 814px;
  @return ($slideHeight / 2) - ($h / 2);
}

// Fonts
$futuraBold: futura-pt-bold, sans-serif;
$futuraBook: futura-pt, sans-serif;
$blenderBold: "Blender Bold";
$blenderThin: "Blender Thin";

// Border box mixin
@mixin border-box() {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

// No select
@mixin no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

// Animation mixin
@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

@mixin animation-delay($str) {
	-webkit-animation-delay: #{$str};
	-moz-animation-delay: #{$str};
	-o-animation-delay: #{$str};
	animation-delay: #{$str};
}

@mixin screen($size) {
	@media screen and (max-width: $size) {
		@content;
	}
}

// keyframes mixin
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

// Transform
@mixin transform($str) {
	-webkit-transform: #{$str};
	-moz-transform: #{$str};
	-ms-transform: #{$str};
	-o-transform: #{$str};
	transform: #{$str};
}

// Transition
@mixin transition($args) {
	-webkit-transition: #{$args};
	-moz-transition: #{$args};
	-ms-transition: #{$args};
	-o-transition: #{$args};
	transition: #{$args};
}

$z-level: 0;

@function z-level() {
  $z-level: $z-level + 1 !global;
  @return $z-level;
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));
  @return $randomNum;
}

// Make a meaningful name for your element, then sort it in this list and
// z-indices will be output automagically
$z-background-grid-cell: z-level();
$z-router-content: z-level();
$z-home-slide-player-alert: z-level();
$z-chrome-bottom-nav: z-level();
$z-chrome-menu-button: z-level();
$z-chrome-left-arrow: z-level();
$z-chrome-right-arrow: z-level();
$z-slide-show-popup-image: z-level();
$z-application-alert: z-level();
$z-background-grid-over-cell: z-level();
$z-popup: z-level();
$z-modal: z-level();
