@import './base';
@import './animations';

// Turn debug on to help debug the grid
$debug: false;
$colors: #f0f2, #0f02, #0ff2, #f002, #ff02, #08f2;

@function color($number: 1) {
  @return if(
    $debug,
    nth($colors, ($number % length($colors)) + 1),
    transparent
  );
}

.Chrome {
  display: grid;
  grid-template-areas:
    'MainMenu     MainMenu    Title        Logo         Logo      '
    'MainMenu     MainMenu    Subtitle     Logo         Logo      '
    'Left         Left        Product      Right        Right     '
    'Left         Left        Timer        Right        Right     '
    'Left         Left        Middle       Right        Right     '
    'Left         Left        Description  Right        Right'
    'BottomNav    BottomNav   BottomNav    BottomNav    BottomNav'
    'SlideProgress SlideProgress SlideProgress SlideProgress SlideProgress';
  grid-template-columns: auto auto 1fr auto auto;
  grid-template-rows: auto auto auto auto 1fr auto auto;
  padding: 30px 30px 0px 30px;
  // z-index: 2; // Interfering with timeline page

  &.has-screenshot {
    > * {
      opacity: 0.1;
    }
    .Chrome__Middle {
      opacity: 1 !important;
    }
  }

  &__MainMenu {
    background: color(1);

    grid-area: MainMenu;
    width: 100px;
    z-index: $z-chrome-menu-button;

    .HamburgerButton {
      width: 40px;
      height: 40px;
      background: transparent;
      border: 0 none;
      outline: none;
      cursor: pointer;

      &:hover {
        svg {
          rect {
            fill-opacity: 1;
          }
        }
      }
    }
  }

  &__Logo {
    background: color(2);

    grid-area: Logo;
    width: 100px;
  }

  &__Title {
    background: color(3);

    grid-area: Title;

    font-family: $futuraBold;
    font-weight: 700;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $blue;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Subtitle {
    background: color(4);

    grid-area: Subtitle;

    font-family: Blender Book;
    font-weight: 300;
    line-height: 30px;
    font-size: 34px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $white;
    margin: 0;
    padding: 5px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    &.smaller {
      font-size: 28px;
    }
  }

  &__Product {
    background: color(5);

    grid-area: Product;

    height: 20px;
    line-height: 18px;
    font-size: 11px;
    font-family: $futuraBold;
    text-align: center;
    text-transform: uppercase;

    color: rgba($white, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: $white;
      display: inline-block;
      padding: 5px 7px;
      font-size: 11px;
      letter-spacing: 1px;
    }
  }

  &__Timer {
    background: color(6);

    grid-area: Timer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Description {
    background: color(7);

    grid-area: Description;

    font-family: 'Blender Thin';
    line-height: 22px;
    font-size: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;

    align-items: center;
    justify-content: center;

    &--hidden {
      visibility: hidden;
    }

    &__Block {
      display: block;
      margin-bottom: 10px;
      &:last-child {
        margin: 0px;
      }
    }

    span {
      max-width: 100%;
    }
  }

  $height: 1px;
  &__SlideProgress {
    grid-area: SlideProgress;
    position: relative;

    div {
      position: absolute;
      top: -10px;
      left: 0;
      height: $height;
      width: 100%;
      background: rgba($white, 0.1);
      transition: opacity 1s ease;
      &.fade-out {
        opacity: 0;
      }
      span {
        display: block;
        position: absolute;
        height: $height;
        left: 0;
        width: 0%;
        background: rgba($white, 0.3);
      }
    }
  }

  &__BottomNav {
    background: color(8);

    grid-area: BottomNav;

    padding: 20px;
    z-index: $z-chrome-bottom-nav;
  }

  &__OptionMenu {
    background: color(9);

    background: #133;
    grid-area: OptionMenu;
  }

  &__Left {
    background: color(10);

    grid-area: Left;
    position: relative;

    &__Arrow {
      cursor: pointer;
      z-index: $z-chrome-left-arrow;
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      border-width: 4px 4px 0 0;
      border-style: solid;
      border-color: $white;
      opacity: 0.05;
      margin: 10px;
      transform: rotate(-135deg);
      &:before {
        right: 0;
        top: -3px;
        position: absolute;
        height: 4px;
        box-shadow: inset 0 0 0 32px;
        transform: rotate(-45deg);
        width: 23px;
        transform-origin: right top;
      }
    }
  }

  &__Middle {
    background: color(11);

    grid-area: Middle;

    position: relative;
    overflow: hidden;
    margin: -100px;

    // The actual slide for each screenshot
    .Screenshot__Slides {
      display: grid;
      width: 100%;
      height: 100%;
      position: relative;
      top: 20px;
      grid-template-columns: 100% 100% 100% 100% 100%;
      transition: all 0.5s ease;
      .Screenshot__Slide {
        width: 100%;
        height: 100%;
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .Screenshot__Slide__Dots {
      position: absolute;
      top: 0px;
      left: calc(50% - 100px);
      text-align: center;
      width: 200px;
      height: 20px;
      display: none;
      border-radius: 10px;
      &__Dot {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba($white, 0.2);
        margin: 0 5px;
        cursor: pointer;
        &.active {
          background: rgba($white, 1);
        }
      }
    }

    &__AppScreenshot {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      // pointer-events: none;
      user-select: none;
      position: absolute;
      top: 150%;
      transition: top 0.25s, margin-top 0.25s;
      z-index: $z-slide-show-popup-image;
      left: 50%;
      margin-top: 1px;

      transform: translate(-50%, -50%);

      &--visible {
        top: 50%;
        margin-top: 0;
        .Screenshot__Slide__Dots {
          display: block;
        }
      }
    }

    &__EmailDemo {
      transition: transform 0.25s ease;
      transform: translate(0%, 100%);

      &--visible {
        transform: translate(0%, 0%);
      }
    }
  }

  &__Right {
    position: relative;
    background: color(12);

    grid-area: Right;

    &__Arrow {
      cursor: pointer;
      z-index: $z-chrome-right-arrow;
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      height: 20px;
      border-width: 4px 4px 0 0;
      border-style: solid;
      border-color: $white;
      opacity: 0.05;
      margin: 10px;
      transform: rotate(45deg);
      &:before {
        right: 0;
        top: -3px;
        position: absolute;
        height: 4px;
        box-shadow: inset 0 0 0 32px;
        transform: rotate(-45deg);
        width: 23px;
        transform-origin: right top;
      }
      &.pulse {
        animation: arrowRightPulse 1s ease infinite;
      }
    }
  }
}
