/*

RANSOMWARE

*/
@import "../base";

//
// Players that often have the same starting position
//

$dots: 6;
$duration: 3s;
$size: 6px;
$delay: $duration / $dots;

@mixin AlertCenter {
  left: 384px;
  top: 260px;
}

@mixin DonutCenter {
  top: 230px;
  left: 50%;
}

@mixin CardCenter {
  left: 496px;
  top: 320px;
}

@mixin DotsCenter {
  left: 500px;
  top: 395px;
}

// Event Slides
.SlideList.sunwalker {
  .SlideList__Column {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      flex: 2;
      .SlideList__Slides {
        display: block;
        column-count: 2;
      }
    }
  }
}

.sunwalker {

  // Slide 0 - Narrative
  .slide-01 {
    // Information
    .player {
      &.block1 {
        width: 420px;
        left: 86px;
        top: 177px;
      }
      &.block2 {
        width: 420px;
        left: 86px;
        top: 367px;
      }
      &.block3 {
        width: 420px;
        left: 86px;
        top: 537px;
      }
      &.block4 {
        width: 420px;
        left: 607px;
        top: 177px;
      }
      &.block5 {
        width: 420px;
        left: 607px;
        top: 435px;
      }
      .animated-dotted-line {
        &.ants-1 {
          position: absolute;
          width: 50px;
          left: -50px;
          top: 40px;
          transform: rotate(-180deg);
        }
        &.ants-2 {
          position: absolute;
          width: 360px;
          left: -50px;
          top: 40px;
          transform: rotate(90deg);
          transform-origin: bottom left;
        }
        &.ants-3 {
          position: absolute;
          width: 50px;
          left: -100px;
          top: 400px;
          transform: rotate(-180deg);
        }
      }
    }
  }

  // Slide 1 - Cards
  $leftOffset: -25px;
  .slide-02 {
    .player {
      &.card1 {
        top: 240px;
        left: 120px + $leftOffset;
      }
      &.card2 {
        top: 240px;
        left: 360px + $leftOffset;
      }
      &.card3 {
        top: 240px;
        left: 600px + $leftOffset;
      }
      &.card4 {
        top: 240px;
        left: 840px + $leftOffset;
      }
    }
  }

  // Slide 3 - Step 1
  .slide-03 {
    .player {
      &.attacker {
        @include CardCenter();
      }
      &.vpn {
        @include CardCenter();
      }
      &.dots1 {
        left: 321px;
        top: 380px;
      }
      &.dots2 {
        left: 600px;
        top: 380px;
      }
      &.dots3 {
        left: 321px;
        top: 400px;
      }
      &.dots4 {
        left: 600px;
        top: 400px;
      }
      &.network {
        left: 752px;
        top: 290px;
      }
    }
  }

  $topOffset: 240px;
  $terminalSize: 90px;
  .slide-04 {
    .player {
      &.donut {
        @include DonutCenter();
      }
      &.scanner {
        left: 415px;
        top: 275px;
        width: 280px;
        height: 280px;
        border-radius: 50%;
        overflow: hidden;
      }
      &.terminal {
        position: absolute;
        width: 70px;
        height: 70px;
        opacity: 0;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        svg.computer {
          opacity: 0;
          transition: opacity 200ms ease;
        }
        &.show-computer {
          svg.computer {
            opacity: 1;
          }
          .animated-dotted-line {
            background: linear-gradient(
              90deg,
              rgba($blue, 1) 50%,
              transparent 50%
            );
            background-repeat: repeat-x;
            background-size: 10px 4px;
            background-position: 0 0;
          }
        }
      }
      .animated-dotted-line {
        position: absolute;
        left: 100%;
        top: 50%;
        transform-origin: bottom left;
      }
      &.terminal1 {
        left: 150px;
        top: $topOffset + ($terminalSize * 0);
        .animated-dotted-line {
          width: 130px;
          transform: rotate(15deg);
        }
      }
      &.terminal2 {
        left: 150px;
        top: $topOffset + ($terminalSize * 1);
        .animated-dotted-line {
          width: 110px;
          transform: rotate(5deg);
        }
      }
      &.terminal3 {
        left: 150px;
        top: $topOffset + ($terminalSize * 2);
        .animated-dotted-line {
          width: 110px;
          transform: rotate(-5deg);
        }
      }
      &.terminal4 {
        left: 150px;
        top: $topOffset + ($terminalSize * 3);
        .animated-dotted-line {
          width: 130px;
          transform: rotate(-15deg);
        }
      }
      &.terminal5 {
        left: 890px;
        top: $topOffset + ($terminalSize * 0);
        .animated-dotted-line {
          width: 130px;
          left: auto;
          transform: rotate(-195deg);
        }
      }
      &.terminal6 {
        left: 890px;
        top: $topOffset + ($terminalSize * 1);
        .animated-dotted-line {
          width: 110px;
          left: auto;
          transform: rotate(-185deg);
        }
      }
      &.terminal7 {
        left: 890px;
        top: $topOffset + ($terminalSize * 2);
        .animated-dotted-line {
          width: 110px;
          left: auto;
          transform: rotate(-175deg);
        }
      }
      &.terminal8 {
        left: 890px;
        top: $topOffset + ($terminalSize * 3);
        .animated-dotted-line {
          width: 130px;
          left: auto;
          transform: rotate(-165deg);
        }
      }
    }
  }
 
  .slide-05 {
    .player {
      &.attacker {
        @include CardCenter();
      }
      &.rdp {
        @include CardCenter();
      }
      &.dots1 {
        left: 320px;
        top: 400px;
      }
      &.dots2 {
        left: 620px;
        top: 400px;
      }
      &.connection {
        left: 780px;
        top: 420px;
      }
    }
  }

  .slide-06 {
    .player {
      &.windows {
        position: absolute;
        left: 250px;
        top: 450px;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left:  135px;
            top: 50%;
            width: 170px;
          }
          &.ants-2 {
            left: 305px;
            top: 10px;
            width: 70px;
            transform: rotate(90deg);
          }
        }
      }
      &.attacker {
        left: 250px;
        top: 180px;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left:  135px;
            top: 50%;
            width: 170px;
          }
          &.ants-2 {
            left: 305px;
            top: 50%;
            width: 70px;
            transform: rotate(90deg);
          }
        }
      }
      &.powershell {
        left: 506px;
        top: 350px;
        .animated-dotted-line {
          position: absolute;
          &.ants-1 {
            left:  110px;
            top: 50%;
            width: 120px;
          }
        }
      }
      &.execute1 {
        left: 744px;
        top: 380px;
      }
      &.execute2 {
        left: 744px;
        top: 380px;
      }
    }
  }

  .slide-07 {
    .player {
      &.donut {
        @include DonutCenter();
      }
      &.scanner {
        top: 330px;
        left: 465px;
        width: 180px;
        height: 180px;
        overflow: hidden;
      }
      &.info {
        lefT: 810px;
        top: 380px;
      }
      &.alert {
        @include AlertCenter();
      }
    }
  }

  .slide-08 {
    .player {
      &.donut {
        @include DonutCenter();
      }
      &.info {
        lefT: 810px;
        top: 380px;
      }
      &.attacker {
        @include CardCenter();
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left:  130px;
            top: 50%;
            width: 170px;
          }
        }
      }
      &.code {
        left: 800px;
        top: 355px;
      }
      &.powershell {
        left: 506px;
        top: 350px;
        .animated-dotted-line {
          position: absolute;
          &.ants-1 {
            left:  110px;
            top: 50%;
            width: 170px;
          }
        }
      }
    }
  }

  //
  // Slide 9 - Isolate Host
  //
  $dots: 8;
  .slide-09 {
    .center-player {
      display: grid;
      grid-template-areas: "main";
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .player {
      &.computer {
        top: 378px;
        left: 525px;
      }
      &.alert {
        @include AlertCenter();
      }
      &.dots {
        position: static !important;
        grid-area: main;
        .mask {
          position: relative;
          transform-origin: 50% 50%;
          border-radius: 50%;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          overflow: hidden;
          width: 600px;
          height: 600px;
          background: rgba($white, 0);
          border: 4px solid rgba($white, 0);
          @include transition('background 0.5s ease, border 0.5s ease');
          .dotContainer {
            position: absolute;
            width: 400px;
            height: 400px;
            top: 50%;
            left: 50%;
            transform-origin: 50% 50%;
            .player--dots {
              position: absolute;
            }
          }
          &.highlighted {
            background: rgba($white, 0.05);
            border: 4px solid rgba($white, 0.15);
          }
        }
        .player--dots {
          .player--dots-track {
            transform-origin: 0 0;
            left: 50px;
          }
          @for $i from 1 through $dots {
            &:nth-child(#{$i}) {
              $deg: #{$i * (360 / $dots)}deg;
              transform: rotate($deg);
            }
          }
        }
      }
    }
  }

  // Slide 10 - Step 8: Access Removed
  .slide-10 {
    .player {
      &.attacker {
        @include CardCenter();
      }
      &.vpn {
        @include CardCenter();
      }
      &.dots1 {
        left: 321px;
        top: 380px;
      }
      &.dots2 {
        left: 600px;
        top: 380px;
      }
      &.dots3 {
        left: 321px;
        top: 400px;
      }
      &.dots4 {
        left: 600px;
        top: 400px;
      }
      &.network {
        left: 752px;
        top: 290px;
      }
    }
  }

  // Slide 11
  .slide-11, .slide-12 {
    .player {
      &.attacker {
        left: 196px;
        top: 320px;
      }
      &.vpn {
        @include CardCenter();
      }
      &.dots1 {
        left: 321px;
        top: 380px;
      }
      &.dots2 {
        left: 600px;
        top: 380px;
      }
      &.dots3 {
        left: 321px;
        top: 400px;
      }
      &.dots4 {
        left: 600px;
        top: 400px;
      }
      &.network {
        left: 752px;
        top: 290px;
      }
    }
  }

  .slide-13 {
    .player {
      &.attacker {
        left: 120px;
        top: 320px;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          left:  130px;
          top: 50%;
          width: 70px;
        }
      }
      &.process1 {
        left: 330px;
        top: 370px;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left:  140px;
            top: 30px;
            width: 40px;
          }
          &.ants-2 {
            left:  180px;
            top: 30px;
            width: 70px;
            transform: rotate(90deg);
          }
          &.ants-3 {
            left:  180px;
            top: -40px;
            width: 70px;
            transform: rotate(90deg);
          }
          &.ants-4 {
            left:  180px;
            top: -40px;
            width: 40px;
          }
          &.ants-5 {
            left:  180px;
            top: 105px;
            width: 40px;
          }
        }
      }
      &.process2 {
        left: 560px;
        top: 300px;
      }
      &.process3 {
        left: 560px;
        top: 445px;
      }
      &.info1 {
        left: 760px;
        top: 200px;
      }
      &.info2 {
        left: 760px;
        top: 430px;
      }
    }
  }

  .slide-14 {
    .player {
      &.donut {
        @include DonutCenter();
      }
      &.page {
        left: 510px;
        top: 360px;
      }
      &.alert {
        @include AlertCenter();
      }
      &.info1 {
        left: 100px;
        top: 300px;
      }
      &.info2 {
        left: 800px;
        top: 300px;
      }
    }
  }

  .slide-15 {
    .player {
      &.analyst {
        top: 300px;
        left: 80px;
        .animated-dotted-line {
          width: 60px;
          left: 130px;
          top: 20px;
        }
      }
      &.info1 {
        top: 300px;
        left: 280px;
      }
      &.info2 {
        top: 300px;
        left: 500px;
        .animated-dotted-line {
          width: 60px;
          left: 210px;
          top: 20px;
        }
      }
      &.scanner {
        top: 355px;
        left: 500px;
        width: 200px;
        height: 200px;
        overflow: hidden;
      }
      &.info3 {
        top: 300px;
        left: 780px;
      }
    }
  }

  //
  // Slide 16
  //
  $leftOffset: -40px;
  $topOffset: 0;
  $trackWidth: 330px;
  .slide-16 {
    .player {
      &.network {
        left: 160px + $leftOffset;
        top: 300px + $topOffset;
      }
      &.dots {
        left: 370px + $leftOffset;
        top: 395px + $topOffset;
        .player--dots-track {
          width: 150px !important;
        }
      }
      &.shield {
        left: 540px + $leftOffset;
        top: 330px + $topOffset;
      }
      &.badge {
        .player--dots {
          position: absolute;
          right: 100%;
          top: 50%;
          width: $trackWidth;
          transform-origin: 100% 0;
          transition: opacity 0.5s ease;
          .player--dots-track {
            width: $trackWidth;
          }
        }
        &.dimmed {
          .player--dots {
            opacity: 0;
          }
        }
      }
      &.badge1 {
        left: 890px;
        top: 180px;
      }
      &.badge2 {
        left: 890px;
        top: 280px;
      }
      &.badge3 {
        left: 890px;
        top: 380px;
      }
      &.badge4 {
        left: 890px;
        top: 480px;
      }
      &.badge5 {
        left: 890px;
        top: 580px;
      }
    }
  }

  $leftOffset: 160px;
  $topOffset: -10px;
  .slide-17 {
    .player {
      &.attacker {
        left: 450px + $leftOffset;
        top: 380px + $topOffset;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left: 130px;
            top: -10px;
            transform: rotate(-45deg);
          }
          &.ants-2 {
            left: 200px;
            top: -80px;
          }
        }
      }
      &.info1 {
        left: 480px + $leftOffset;
        top: 280px + $topOffset;
      }
      &.info2 {
        left: 480px + $leftOffset;
        top: 490px + $topOffset;
        .red {
          width: 200px;
          white-space: normal;
        }
      }
    }
  }

  $leftOffset: 200px;
  $topOffset: 340px;
  .slide-18 {
    .player {
      &.attackers {
        left: 0px + $leftOffset;
        top: 0px + $topOffset;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          left: 130px;
          top: 50%;
          width: 160px;
        }
      }
      &.computer {
        left: 300px + $leftOffset;
        top: 0px + $topOffset;
      }
      &.barrier {
        left: 420px + $leftOffset;
        top: -70px + $topOffset;
      }
      &.vmware {
        left: 620px + $leftOffset;
        top: 20px + $topOffset;
      }
    }
  }

  $leftOffset: 120px;
  $topOffset: -10px;
  .slide-19, .slide-20 {
    .player {
      &.analyst {
        left: 450px + $leftOffset;
        top: 380px + $topOffset;
        .animated-dotted-line {
          position: absolute;
          transform-origin: bottom left;
          &.ants-1 {
            left: 130px;
            top: -10px;
            transform: rotate(-45deg);
          }
          &.ants-2 {
            left: 200px;
            top: -80px;
          }
        }
      }
      &.info {
        left: 480px + $leftOffset;
        top: 280px + $topOffset;
      }
      &.mdr {
        left: 570px + $leftOffset;
        top: 380px + $topOffset;
        .animated-dotted-line {
          position: absolute;
          left: auto;
          right: calc(100% + 10px);
          top: 50%;
          width: 100px;
        }
      }
    }
  }

  $topOffset: -20px;
  .slide-21 {
    .player {
      &.message {
        @include AlertCenter();
      }
      &.title {
        width: 100%;
        top: 160px;
        text-align: center;
        h1 {
          font-size: 48px;
          font-family: Blender Thin;
        }
      }
      &.alert {
        left: 384px;
        top: 320px + $topOffset;
      }
      &.info1 {
        left: 240px;
        top: 320px + $topOffset;
      }
      &.info2 {
        left: 640px;
        top: 320px + $topOffset;
      }
      &.info3 {
        left: 640px;
        top: 520px + $topOffset;
      }
    }
  }

}